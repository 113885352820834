<template>
  <div class="h-screen flex flex-col md:flex-row">
    <div
      class="h-full w-full flex flex-col md:flex-row-reverse items-center md:items-start"
    >
      <div class="flex flex-1 h-full justify-center items-center w-full title">
        <TitlePresentation class="md:text-left 2k:text-8xl 4k:text-9xl">
          BOOK, <br />
          PLAY, <br />
          SHARE !
        </TitlePresentation>
      </div>
      <div class="flex flex-1 h-full justify-center items-center w-full">
        <div
          class="bg-left bg-header-sub-background header-background-image md:mt-0 -ml-16 md:ml-0"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import TitlePresentation from "@/components/atomic/TitlePresentation";

export default {
  name: "Header",
  components: { TitlePresentation },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.header-background-image {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

@media (min-width: 320px) and (max-width: 375px) {
  .header-background-image {
    margin-top: 6.5rem;
  }
}

@media (min-width: 375px) and (max-width: 450px) {
  .header-background-image {
    margin-top: 3rem;
  }
}

@media (max-width: 375px) {
  .title {
    margin-bottom: -4.5rem;
  }
}

@media (max-width: $doi-breakpoint-tablet) {
  .title {
    margin-bottom: -6.5rem;
  }
}
</style>
