import Network from "@/utils/Network";

const url2 = process.env.VUE_APP_API_URL_V2;

const cartUpdate2 = (cart) => {
  return Network.execute({
    method: "POST",
    url: url2 + "panier/modifie",
    payload: cart,
  });
};

const cartDelete2 = (idPanier) => {
  return Network.execute({
    method: "DELETE",
    url: url2 + `panier/supprime/${idPanier}`,
  });
};

const getCart2 = () => {
  return Network.execute({
    method: "GET",
    url: url2 + "panier",
  });
};

const cartValidate2 = (shareBill) => {
  return Network.execute({
    method: "POST",
    url: url2 + "panier/valide",
    payload: {
      partagePaiement: shareBill,
    },
  });
};

const cartUpdateLine = (data) => {
  return Network.execute({
    method: "PUT",
    url: url2 + "panier/modifie_ligne",
    payload: data,
  });
};

const addDiscountToCart = (cartId, data) => {
  return Network.execute({
    method: "POST",
    url: url2 + `add-discount/${cartId}/cart`,
    payload: data,
  });
};

const deleteDiscountFromCart = (cartId, discountId) => {
  return Network.execute({
    method: "DELETE",
    url: url2 + `remove-discount/cart/${cartId}/discount/${discountId}`,
  });
};

export {
  cartUpdate2,
  cartDelete2,
  getCart2,
  cartValidate2,
  cartUpdateLine,
  addDiscountToCart,
  deleteDiscountFromCart,
};
