<template>
  <div class="container mx-auto font">
    <div
      v-if="this.$route.params.indexArticle === 'infos-padel'"
      class="mx-8 text-justify"
    >
      <H1Article>Des terrains de padel connectés</H1Article>
      <TextArticle
        >Le padel est le nouveau sport tendance. Connu pour sa ressemblance avec
        le squash et pour être un dérivé du tennis, nous vous disons tout sur ce
        sport de raquette en plein développement.</TextArticle
      ><br />
      <H2Article>Le padel, un sport de raquette qui se joue à 2</H2Article
      ><br />
      <TextArticle
        >Le padel se pratique sur un court de 20m x 10m avec un filet qui
        délimite les deux côtés du terrain, entouré de grillages ou de vitres.
        Ce sport se pratique par équipes de 2.</TextArticle
      ><br />
      <TextArticle
        >La raquette de padel est plus petite que celle de tennis et ne dispose
        pas de cordage. La surface est plus souple et percée. Les balles de
        padel sont à première vue similaires à celles du tennis mais en réalité
        plus souples et plus petites.</TextArticle
      ><br />
      <TextArticle
        >Les règles font écho à celles du tennis : si la balle ne franchit pas
        le filet ou si elle rebondit deux fois dans votre camp, le point va à
        l’adversaire. La principale différence se trouve dans le rebond de la
        balle : celle-ci peut être projetée contre les grillages ou les murs,
        donnant lieu ainsi à des points parfois spectaculaires.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/paddle-tennis-g79618f8e4_1920.jpg"
          alt="Padel Park Dijon Fit Arena"
        />
      </div>
      <br />
      <H2Article>L’origine et la popularité du padel</H2Article><br />
      <TextArticle
        >Ce sport de raquette provient d’Amérique centrale, plus précisément du
        Mexique. Il a été inventé par Enrique Corcuera qui, par manque de place
        dans son jardin, a décidé de se créer un court plus petit qu’un terrain
        de tennis, entouré de murs de plusieurs mètres de haut. Le padel s’est,
        par la suite, répandu en Espagne grâce à Alfonso de Hohenlohe dès 1974
        et est un sport très populaire aujourd’hui dans ce pays.</TextArticle
      ><br />
      <TextArticle
        >En France, ce sport se démocratise au fil des années. En 2014, il est
        reconnu par la Fédération française de tennis. Le nombre de joueurs
        classés à la FFT a pratiquement doublé en 2 ans, passant de 7 500
        joueurs en 2019 à 13 500 en 2021. La fédération recense aujourd’hui
        environ 150 000 pratiquants, contre 80 000 en 2019.</TextArticle
      ><br />
      <H2Article>Les infrastructures de padel</H2Article><br />
      <TextArticle
        >Le nombre de salles de padel a connu une belle augmentation en une
        décennie. En 2014, seuls 48 terrains étaient présents entre France.
        Depuis 2016, ce sont 150 nouveaux terrains qui sont construits chaque
        année, permettant d’atteindre les 955 terrains en 2019.</TextArticle
      ><br />
      <TextArticle
        >SMC2 a participé au développement de ces infrastructures sportives
        dédiées au padel aux quatre coins de la France. Nous veillons à
        construire des salles lumineuses et tempérées avec une hauteur idéale,
        en choisissant nos matériaux avec soin pour une exemplarité
        écologique.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/SMC2-Freyssinet-Chalons_5-bis.jpg"
          alt="Padel Park Dijon Fit Arena"
        />
      </div>
      <br />
      <TextArticle
        >Proposer des solutions répondant aux besoins des sportifs et anticipant
        leurs envies, tel est le moteur de SMC2. Nos
        <a
          href="https://www.smc2-construction.com/sports-jeux/tennis-padels/padels/"
          target="_blank"
          >réalisations de padel</a
        >
        en sont de bons exemples : nos infrastructures de
        <a
          href="https://www.smc2-construction.com/realisations/halles-de-padel/"
          target="_blank"
          >padel indoor</a
        >
        et
        <a
          href="https://www.smc2-construction.com/realisations/padels-plein-air-couvert/"
          target="_blank"
          >plein air couvert</a
        >
        répondent aux attentes des sportifs de pouvoir jouer toute l’année à
        l’abri des intempéries tout en garantissant un confort de jeu
        optimal.</TextArticle
      ><br />
      <TextArticle
        >Fit Arena propose une version 2.0 de la pratique du padel avec une
        salle de sports autonome et connectée. Le parcours digitalisé permet au
        sportif de vivre une expérience sportive interactive complète : grâce à
        une application, il peut accéder simplement à un terrain pour pratiquer
        son sport favori. Il peut également enregistrer ses exploits sportifs,
        avoir un suivi de ses performances et se comparer à d’autres joueurs.
        Une vrai communauté se fédère ainsi autour du padel !</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'zoom-foot-5'"
      class="mx-8 text-justify"
    >
      <H1Article>Nos terrains de five connectés</H1Article>
      <TextArticle
        >Ce sport collectif est une variante du football où 2 équipes de 5
        joueurs dont 1 gardien s'affrontent.</TextArticle
      ><br />
      <H2Article>Le foot5, un sport originaire du Brésil</H2Article><br />
      <TextArticle
        >Le foot5 est originaire du Brésil où il a vu le jour en 1940. Il est né
        d’une improvisation des sportifs à jouer au football sur une surface
        réduite, notamment dans des gymnases ou des terrains improvisés dans la
        rue. Certaines stars brésiliennes comme Pelé ou encore Ronaldinho se
        seraient initiées au foot5 avant de lancer leur carrière.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/Mont_SMC2_Lalaude_Andia_35_5-1.jpg"
          alt="Salle de foot5 Fit Arena"
        />
      </div>
      <br />
      <H2Article>La popularité du foot5</H2Article><br />
      <TextArticle
        >Le foot5 a traversé l’océan Atlantique pour d’abord se faire connaître
        au Royaume-Uni, boosté notamment grâce à des grandes marques.
        Aujourd’hui, l’Angleterre compte plus de 6 millions
        d’adhérents.</TextArticle
      ><br />
      <H2Article>Nos terrains de five en France</H2Article><br />
      <TextArticle
        >En France, ce sport d’équipe s’est également développé avec un nombre
        de structures en hausse ces dernières années. SMC2 a d’ailleurs su
        répondre à cette demande croissante et compte à son actif la réalisation
        de plusieurs salles de foot, dont 2 à la demande du Five, l’une des
        grandes marques de complexe de foot5. Mais pas seulement ! Avec Fit
        Arena, nous vous proposons de
        <TextArticle type="span" :textColor="1" :fontSize="3"
          >bénéficier une salle multisports connectée qui permet la pratique de
          différents sports – dont le foot5 – en un seul et même lieu grâce à
          des équipements motorisés.</TextArticle
        >
        Vous profitez ainsi d’un gain de place et d’une offre plus large
        !</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/Five_Courneuve_1.jpg"
          alt="Salle de foot5 Fit Arena"
        />
      </div>
      <br />
      <H2Article>Différence entre le futsal et le foot5</H2Article><br />
      <TextArticle
        >De même que l’on ne compare pas le tennis au padel, il ne faut pas
        confondre futsal et foot5. Le foot5 se joue sur un terrain synthétique
        fermé de 25 x 15m. Les murs qui entourent le terrain deviennent des
        éléments de jeu : le ballon peut rebondir dessus du moment qu’il ne
        touche pas le filet au-dessus de ce mur. Le gardien doit rester dans sa
        surface et les autres joueurs ne peuvent y entrer.</TextArticle
      ><br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/Manosque-interior-left.jpg"
            alt="Salle de foot5 Fit Arena"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/Manosque-interior.jpg"
            alt="Salle de foot5 Fit Arena"
          />
        </div>
      </div>
      <br />
      <TextArticle
        >Concernant les fautes, dans les 2 sports le tacle est interdit. La
        différence se trouve dans la sanction : au futsal, un tacle donne lieu à
        un coup-franc tandis qu’au foot5, le ballon est rendu au goal adverse.
        Les fautes graves sont sanctionnées par des penalties, appelés « shoot
        out ».</TextArticle
      ><br />
      <TextArticle
        >Les différences principales entre ces 2 sports se trouvent dans la
        taille et le revêtement du terrain. Des lignes délimitent le terrain au
        futsal tandis que ce sont des murs au foot5. La démocratisation du sport
        est à 2 vitesses : le futsal bénéficie déjà de son championnat national
        structuré, à la différence du foot5.</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'basket-3x3'"
      class="mx-8 text-justify"
    >
      <H1Article>Votre salle de basket 3x3 ouverte au public</H1Article>
      <TextArticle
        >Le basket 3x3, également appelé 3x3, est une variante du basketball.
        Discipline olympique depuis 2020, ce sport consiste à opposer deux
        équipes de trois joueurs sur un demi-terrain avec un seul
        panier.</TextArticle
      ><br />
      <H2Article>L’émergence du basket 3x3</H2Article><br />
      <div class="sm:hidden">
        <TextArticle
          >Ce sport est né sous l’impulsion des joueurs de rue avant d’être
          encadré par la Fédération Française de Basketball (FFBB). S’il est
          difficile de définir une année de naissance précise à ce sport, nous
          pouvons au moins déterminer qu’une attention accrue s’est portée sur
          ce sport dès 2007. Depuis 2010, des compétitions nationales et
          internationales telles que la FIBA 3x3 World Tour sont organisées
          autour de ce sport d’équipe.</TextArticle
        >
        <img
          class="mx-auto mt-6"
          src="../../assets/img/mock/cmsActu/Logo FFBB.png"
          alt="Fédération française de Basket"
        />
      </div>
      <br />
      <div class="hidden sm:flex sm:flex-row">
        <div class="w-1/2 justify-center items-center">
          <TextArticle
            >Ce sport est né sous l’impulsion des joueurs de rue avant d’être
            encadré par la Fédération Française de Basketball (FFBB). S’il est
            difficile de définir une année de naissance précise à ce sport, nous
            pouvons au moins déterminer qu’une attention accrue s’est portée sur
            ce sport dès 2007. Depuis 2010, des compétitions nationales et
            internationales telles que la FIBA 3x3 World Tour sont organisées
            autour de ce sport d’équipe.</TextArticle
          >
        </div>
        <div class="w-1/2 flex justify-center items-center">
          <img
            src="../../assets/img/mock/cmsActu/Logo FFBB.png"
            alt="Fédération française de Basket"
          />
        </div>
      </div>
      <br />
      <TextArticle
        >La popularité du basket 3x3 est notamment due à la conception moderne,
        jeune et fun de ce sport. Il répond aux aspirations des sportifs à la
        recherche d’un sport physique, peu contraignant dans la pratique comme
        dans l’enseignement et adapté aux débutants comme aux
        confirmés.</TextArticle
      ><br />
      <H2Article>Les règles du basket 3x3</H2Article><br />
      <TextArticle
        >Issu du streetball, le basket 3x3 s’est d’abord développé sans
        codification ni règle commune. C’est la Fédération Internationale de
        Basket (FIBA), en organisant les premières compétitions internationales
        en 2011, qui a mis en place un règlement officiel.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/P1030275_recadree.jpg"
          alt="Match de Basket 3x3"
        />
      </div>
      <br />
      <TextArticle
        >Le terrain officiel doit mesurer 15 mètres de long et 11 mètres de
        largeur avec un seul panier, situé à 3.05 mètres de
        hauteur.</TextArticle
      ><br />
      <TextArticle
        >Le basket 3x3 ne suit pas la même organisation que le 5x5. Cette
        variante, plus intensive, propose des matchs sous un format plus court.
        Il se termine dès lors qu’une équipe a atteint 21 points ou que l’on
        atteint les 10 minutes de jeu. Le chronomètre est arrêté sur les arrêts
        de jeu. La procession de balle se fait par tirage au sort. Après chaque
        panier, la balle est remise à l’adversaire. Au niveau des scores, un
        panier marqué depuis l’intérieur de l’arc de cercle apporte 1 point, 2
        points sont attribués en cas de panier tiré depuis l’extérieur de la
        raquette.</TextArticle
      ><br />
      <H2Article>Les lieux de pratique du basket 3x3</H2Article><br />
      <TextArticle
        >De plus en plus de terrains voient le jour, permettant une pratique
        encadrée du basket 3x3. SMC2 a d’ailleurs assuré la conception et la
        construction de structures sportives adaptées au basket 5x5 comme au
        basket 3x3.</TextArticle
      ><br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/Preosport-Berlin-02.jpg"
            alt="Fit Arena Basket 3x3"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/Voiron_PREO2_Web.jpg"
            alt="Fit Arena Basket 3x3"
          />
        </div>
      </div>
      <br />
      <TextArticle
        >Fit Arena, la salle autonome et connectée, devient un nouveau lieu de
        pratique de cette discipline. Avec ses équipements motorisés, elle
        permet aux sportifs de bénéficier d’un terrain lumineux, moderne et
        équipé pour une pratique sportive optimale. Grâce au parcours autonome
        et digitalisé qu’elle propose, Fit Arena permet d’inviter facilement ses
        amis depuis l’application ou le site internet pour jouer une partie de
        basket 3x3. Il est ensuite possible de partager ses performances
        sportives sur les réseaux sociaux et ainsi de pouvoir se connecter à la
        communauté Fit Arena. Une belle occasion de trouver de nouveaux
        coéquipiers ou adversaires !</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle === 'salle-badminton-connectee'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Fit Arena, la salle de badminton connectée</H1Article>
      <TextArticle
        >Parmi les équipements sportifs disponibles dans votre Fit Arena, vous
        trouverez les tracés et le filet de badminton ! Vous bénéficiez ainsi
        d’un gymnase pour la pratique du badminton mais pas seulement… Vous êtes
        également amateur de foot, de basket ou de volley ? Fit Arena est faite
        pour vous ! En un seul lieu, pratiquez plusieurs sports : la seule chose
        qui change, c’est le sport à réserver !</TextArticle
      ><br />
      <H2Article>Le badminton, le sport à pratiquer en intérieur</H2Article
      ><br />
      <TextArticle
        >Le badminton est un sport de raquettes qui oppose deux joueurs (simple)
        ou deux paires (double) au sein d’un terrain séparé par un filet. Il
        s’agit du sport de raquettes le plus rapide au monde. Le malaisien Tan
        Boon Heong a réalisé le smash du record et frappé le volant à 137 m/s
        (soit 493km/h !), c’est quasiment deux fois plus que le record du monde
        de tennis (73 m/s). Cela est dû, entres autres, au fait que le volant de
        badminton est léger et que la raquette est souple (plus souple qu’une
        raquette de tennis par exemple).</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/badminton-g549cbba04_1920.jpg"
          alt="Badminton Fit Arena"
        />
      </div>
      <br />
      <H2Article>Le badminton, petit frère du poona indien</H2Article><br />
      <TextArticle
        >Les origines du badminton sont pour le moins raffinées. C’est en effet
        en 1873 que le mot « badminton » apparaît, au cœur du château de la
        ville de Badminton au sud-ouest de l’Angleterre. Les officiers du Duc de
        Beaufort ont souhaité reproduire le poona, un sport de raquette
        découvert durant leur expédition dans les Indes. Ne disposant pas de la
        même balle légère, ils improvisèrent en créant un volant à partir d’un
        bouchon de champagne qu’ils agrémentèrent de quelques plumes. Convaincus
        et fascinés par leur idée, les officiers firent connaître ce passe-temps
        sous le nom du château où il est né : Badminton. Quatre ans plus tard,
        ils publièrent les premières règles du jeu et s’en attribuèrent la
        paternité.</TextArticle
      ><br />
      <H2Article>Les règles du badminton</H2Article><br />
      <TextArticle
        >Du fait de la légèreté du volant (5 grammes environ), le badminton doit
        se jouer en intérieur pour éviter que le vent ne gêne les échanges. En
        simple ou en double, pour débuter le jeu au service, le volant doit être
        frappé sous la taille. Le serveur doit se positionner dans la zone de
        service de droite si le score est pair et tirer en diagonale. Une seule
        tentative est autorisée : en cas d’échec, le point va à l’adversaire. Le
        set se joue en 21 points, avec 2 points de différence. Le match se
        remporte en 2 sets gagnants. En cas de volant tombé en dehors de limites
        du terrain ou de volant frappé deux fois de suite par la même équipe, le
        point va à l’adversaire.</TextArticle
      ><br />
      <H2Article> La popularité de ce sport de raquette</H2Article><br />
      <TextArticle
        >C’est en 1934 que la fédération internationale de badminton (BWF) se
        crée, regroupant plus de 150 pays issus des 5 continents. La Fédération
        Française de Badminton (FFBA, appelée aujourd’hui FFBaD) a été fondée la
        même année avant d’être rattachée à la Fédération Française de Tennis en
        1944 par le régime de Vichy. Ce n’est qu’en décembre 1978, lors d’une
        déclaration officielle aux Jeux Olympiques, que la Fédération Française
        de Badminton reprend du service. Elle compte aujourd’hui près de 177 000
        licenciés. En 1992, le badminton fait son entrée aux jeux olympiques.
        Depuis, cette discipline a été représentée à chaque
        olympiade.</TextArticle
      ><br />
      <TextArticle
        >Sur le plan scolaire, le badminton est très populaire. Il est le
        deuxième sport individuel le plus pratiqué à l’école mais également
        durant l’épreuve du baccalauréat. La Fédération Française de Badminton
        encourage la pratique de ce sport dès l’école primaire en mettant à la
        disposition des professeurs d’EPS de nombreux outils pour améliorer leur
        proposition pédagogique.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/badminton-gebad03bc8_1920.jpg"
          alt="Badminton Fit Arena"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle ===
        'les-terrains-de-tennis-couvert-Fit-Arena'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Les terrains de tennis couvert Fit Arena</H1Article>
      <TextArticle
        >Vous aimez le tennis ? Fit Arena le sait et vous offre la possibilité
        de jouer au tennis au sein de notre salle multisports connectée.
        Réservez votre sport et votre créneau sur notre application Fit Arena ou
        sur notre site internet. Puis rendez-vous le jour J au sein de la Fit
        Arena proche de chez vous. Avec votre QR code d’accès, vous pourrez
        rentrer en toute autonomie. Lorsque l’heure de votre séance arrive, la
        salle de sport Fit Arena se transforme en court de tennis grâce aux
        équipements motorisés qui s’installent automatiquement quelques minutes
        avant le début du match. Ne loupez rien de vos performances sportives en
        tennis grâce à nos caméras présentes sur le terrain.</TextArticle
      ><br />
      <H2Article>Le tennis, un sport de raquettes mondialement connu</H2Article
      ><br />
      <TextArticle
        >Raphaël Nadal, Jo Wilfried Tsonga, Gaël Monfils, Amélie Mauresmo, Novak
        Djokovic... qui n’a jamais entendu parler de ces sportifs ? Le tennis
        est un sport populaire dans de nombreux pays et les performances de ces
        champions en grand chelem ne font que renforcer la popularité de ce
        sport de raquettes. Alors que Roland Garros a débuté il y a quelques
        jours, prenons le temps de mieux connaître le tennis dont la passion
        dépasse les frontières.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/tennis-gd678a5f47_1920.jpg"
          alt="Tennis Fit Arena"
        />
      </div>
      <br />
      <H2Article>Le tennis, issu d’une évolution du jeu de paume</H2Article
      ><br />
      <TextArticle
        >Le tennis descend d’une évolution du Jeu de Paume, datant du Moyen-Age.
        Populaire en France, le jeu de paume comptait une dizaine de salles
        parisiennes au XIVème siècle. Mais la plupart des parties se jouaient en
        extérieur ou dans des lieux privés. Le but du jeu était simple : deux
        joueurs séparés d’un filet se renvoyaient une balle en cuir rembourrée à
        l’aide de la paume de leur main.</TextArticle
      ><br />
      <TextArticle
        >Deux siècles plus tard, la première raquette fut inventée. En cordage
        de mouton, elle a permis de rendre le jeu de paume moins douloureux.
        Cette version avec raquette conquit la noblesse française avant de
        s’étendre en Europe et sur les autres continents.</TextArticle
      ><br />
      <TextArticle
        >Concernant l’appellation de ce sport, elle serait liée au mot « teneys
        » ou « ténèts » que les joueurs disaient au moment du service,
        signifiant « tenez » en ancien français. Le mot s’est transformé peu à
        peu dans les bouches anglaises pour devenir « tennis ».</TextArticle
      ><br />
      <TextArticle
        >Le tennis moderne comme on le connaît aujourd’hui a pris forme au
        XIXème siècle. C’est en 1877, lorsque le tournoi de Wimbledon voit le
        jour, que les règles de ce sport sont définies.</TextArticle
      ><br />
      <H2Article>Tennis : les règles à suivre</H2Article><br />
      <TextArticle
        >Un court de tennis fait 23.77m de longueur et 8.23m de largeur. Il est
        divisé en son milieu par un filet suspendu. Il existe différents types
        de revêtements pour les terrains de tennis : surface dure généralement
        utilisée en club ou en extérieur, gazon naturel utilisé notamment lors
        du championnat de Wimbledon ou terre battue dont l’évènement le plus
        connu est le tournoi de Roland-Garros. Le tennis se joue en simple et en
        double.</TextArticle
      ><br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/tennis-gdebcb6889_1920.jpg"
            alt="Tennis Fit Arena"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/tennis-g2aaa38411_1920.jpg"
            alt="Tennis Fit Arena"
          />
        </div>
      </div>
      <br />
      <TextArticle
        >Concernant le comptage des points, celui-ci sort un peu de l’ordinaire.
        Un match se découpe en deux ou trois set et chaque set est composé de
        six jeux minimum.</TextArticle
      >
      <TextArticle
        >Pour gagner un jeu, il faut marquer 4 points se notant ainsi : 0 – 15 –
        30 – 40 – jeu.</TextArticle
      >
      <TextArticle
        >Pour gagner un set, il faut 6 jeux gagnants avec au moins 2 jeux
        d’écart.</TextArticle
      >
      <TextArticle
        >Pour gagner un match, il faut avoir 3 sets gagnants.</TextArticle
      ><br />
      <H2Article>La popularité du tennis</H2Article><br />
      <TextArticle
        >C’est en 1878, soit un an après la création du tournoi de Wimbledon,
        que le premier club français voit le jour à Dinard. L’empire britannique
        permet une expansion rapide de ce sport de raquette à travers le monde.
        La Fédération Internationale de Tennis se crée en 1913, la Fédération
        Française de Tennis apparait en 1920 et le premier tournoi de
        Roland-Garros (appelé autrefois Internationaux de France) est organisé 5
        ans plus tard.</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle ===
        'le-tennis-de-table-d-activite-de-loisirs-a-sport-olympique'
      "
      class="mx-8 text-justify"
    >
      <H1Article
        >Le tennis de table, d’activité de loisirs à sport olympique</H1Article
      >
      <TextArticle
        >Le tennis de table, familièrement appelé ping pong, est un sport de
        raquette très populaire. Longtemps considéré comme étant uniquement une
        activité de loisirs, cette discipline s’est peu à peu professionnalisée
        jusqu’à devenir une discipline olympique en 1988.</TextArticle
      ><br />
      <H2Article>Fit Arena, votre nouvelle salle de tennis de table</H2Article
      ><br />
      <TextArticle
        >Fit Arena, c’est votre salle de foot5, votre salle de basket 3x3, votre
        salle de volley mais également votre salle de tennis de table ! Parmi
        les équipements à disposition, vous retrouvez les tables de ping-pong.
        Le principe est le même que pour les autres sports : réservez votre
        créneau sur notre application ou le site internet, invitez vos amis et à
        vous la séance de sport fun et conviviale ! Avec son bâtiment sportif
        modern et lumineux, Fit Arena vous offre un cadre de jeu
        optimal.</TextArticle
      ><br />
      <H2Article>La naissance du ping-pong</H2Article><br />
      <TextArticle
        >A la fin du XIXème siècle, le tennis de table fait son apparition en
        Angleterre. Pratiqué notamment par la société bourgeoise victorienne, ce
        sport de raquette est inspiré du tennis sur gazon. C’est en 1890, grâce
        à l’anglais David Foster, que le premier jeu de tennis de table fut
        intégré, permettant ainsi de pouvoir jouer en intérieur lors des soirées
        mondaines. L’histoire du ping pong est officiellement lancée au début
        des années 1900 avec l’apparition de la première balle en celluloïd et
        des premières raquettes recouvertes de caoutchouc et de picots
        caoutchoutées.</TextArticle
      ><br />
      <H2Article>La démocratisation de ce sport de raquettes</H2Article><br />
      <TextArticle
        >En 1902 se tient le premier championnat du monde officiel de tennis de
        table, entrainant la création de la Fédération Britannique de Tennis de
        Table puis l’organisation des premiers championnats d’Europe. De là,
        tout s’enchaîne rapidement. L’Association de Tennis de Table est créée
        en 1921, suivie 6 ans plus tard par la Fédération Internationale de
        Tennis de Table (comptant aujourd’hui plus de 200 nations inscrites). La
        même année, la Fédération Française de tennis de table voit le
        jour.</TextArticle
      ><br />
      <TextArticle
        >Ce sport de raquettes conquit les pays et plusieurs championnats du
        monde sont organisés à Londres jusqu’à devenir sport olympique en 1988
        lors des JO de Séoul. Les asiatiques dominent ce sport, avec par exemple
        Zhuand Zedong, triple champion du monde chinois en 1961, 1963 et
        1965.</TextArticle
      ><br />
      <TextArticle
        >Aujourd’hui, le tennis de table fait partie du top 5 des sports les
        plus pratiqués au monde et dispose de règles officielles
        universelles.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/table-tennis-ga15cf5c86_1920.jpg"
          alt="Tennis de table Fit Arena"
        />
      </div>
      <br />
      <H2Article>Les règles du tennis de table</H2Article><br />
      <TextArticle
        >Reconnu pour être une activité de loisirs très populaire, le ping-pong
        dispose tout de même de règles officielles qui s’appliquent autant aux
        professionnels qu’aux pongistes débutants. Elles sont gérées par l’ITTF
        (International Table Tennis Federation).</TextArticle
      ><br />
      <TextArticle
        >Pour remporter la manche, il faut gagner 3 sets. Pour gagner un set, il
        faut être le premier à marquer 11 points, avec deux points d’écart pour
        remporter la partie. L’arbitre détermine le joueur qui doit servir au
        tirage au sort. Au terme de chaque set, le service est attribué à celui
        qui n’a pas servi en premier au cours de la manche précédente. Lors du
        set, les joueurs alternent une série de deux services chacun. Le tennis
        de table se pratique en simple et en double.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/table-tennis-g41d0832e5_1920.jpg"
          alt="Tennis de table Fit Arena"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle === 'futsal-sport-derive-du-football'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Le futsal, un sport dérivé du football</H1Article>
      <TextArticle
        >Ce sport collectif, spectaculaire et technique, se pratique à 5 contre
        5 sur un terrain équivalent à celui du handball et compte près de 30 000
        licenciés.</TextArticle
      ><br />
      <H2Article>Origine du terme « futsal »</H2Article><br />
      <div class="sm:hidden">
        <img
          class="mx-auto mt-6"
          src="../../assets/img/mock/cmsActu/football-g790608808_1920.png"
          alt="Futsal Fit Arena"
          width="50%"
        /><br />
        <TextArticle
          >La première expression adoptée pour qualifier ce sport de ballon est
          « fútbol de salón » pour les usagers hispanophones et « futebol de
          salão » pour les lusophones. Mais en 1983, face à la menace d’une
          interdiction d’emploi du terme « football » par le président de la
          FIFA João Havelange, les membres de la fédération internationale de
          futsal déposèrent le nom « futsal », une contraction de « futbol » et
          « sala ».</TextArticle
        >
      </div>
      <br />
      <div class="hidden sm:flex sm:flex-row items-center">
        <div class="w-1/2 flex justify-center items-center">
          <img
            src="../../assets/img/mock/cmsActu/football-g790608808_1920.png"
            alt="Futsal Fit Arena"
            id="futsal"
          />
        </div>
        <div class="w-1/2 justify-center items-center">
          <TextArticle
            >La première expression adoptée pour qualifier ce sport de ballon
            est « fútbol de salón » pour les usagers hispanophones et « futebol
            de salão » pour les lusophones. Mais en 1983, face à la menace d’une
            interdiction d’emploi du terme « football » par le président de la
            FIFA João Havelange, les membres de la fédération internationale de
            futsal déposèrent le nom « futsal », une contraction de « futbol »
            et « sala ».</TextArticle
          >
        </div>
      </div>
      <br />
      <H2Article>L'histoire du futsal</H2Article><br />
      <H3Article>La naissance de ce sport</H3Article>
      <TextArticle
        >Ce sport collectif est né en Amérique du Sud dans les années 1930. Juan
        Carlos Ceriani organisa une compétition sur un espace réduit de la
        taille d’un terrain de handball en confrontant 2 équipes de 5 jeunes.
        Cet évènement connut un large succès en Uruguay puis fut ensuite repris
        et développé au Brésil la même année. Le Brésil est d’ailleurs
        aujourd’hui considéré comme la patrie du futsal.</TextArticle
      ><br />
      <TextArticle
        >Le futsal est né d’une volonté de créer un sport collectif avec des
        règles de jeu adaptées à la mission des YMCA (Unions chrétiennes de
        jeunes gens). L’idée originelle était de créer un nouveau sport avec des
        règles beaucoup plus strictes que le football afin d’éduquer les jeunes
        adultes, de les responsabiliser et de les rendre
        solidaires.</TextArticle
      ><br />
      <H3Article>Les fédérations de futsal</H3Article>
      <TextArticle
        >La fédération internationale de futsal (FIFUSA) est fondée en 1971, par
        l’association de 7 pays dont six sud-américains : l'Uruguay, le Brésil,
        l'Argentine, le Paraguay, la Bolivie, le Pérou — et le Portugal. Elle
        fut dissoute en 1990 pour des raisons économiques. C’est l’Association
        Mondiale de Futsal (AMF) qui prend le relai en 2002. La FIFA
        souhaiterait aujourd’hui que la pratique du futsal au niveau mondial
        soit intégrée à son périmètre tandis que l’AMF souhaite une gouvernance
        indépendante et autonome, estimant qu’il s’agit de deux sports
        différents.</TextArticle
      ><br />
      <H3Article>Le développement du futsal en France</H3Article>
      <TextArticle
        >Dans les années 70, cette discipline se développe dans les grandes
        villes françaises à l’initiative d’associations de quartiers. La
        première Coupe de France, officiellement nommé « Coupe Nationale de
        Futsal », voit le jour en 1994. Avec un principe d’élimination directe,
        cette compétition est ouverte aux clubs affiliés à la FFF. La France
        s’affirme ensuite à l’échelle internationale avec la naissance de la
        première équipe de France masculine en 1997.</TextArticle
      ><br />
      <TextArticle
        >L’équipe française fait aujourd’hui partie des 20 meilleures nations
        mondiales. Au niveau national, le Sporting Paris est le club le plus
        titré avec 5 trophées remportés.</TextArticle
      ><br />
      <H2Article>Les règles du futsal</H2Article><br />
      <TextArticle
        >Les matchs de futsal se jouent entre deux équipes de quatre joueurs et
        deux gardiens de buts. Le match se déroule en deux mi-temps de 20 ou
        25min selon si le temps de jeu est effectif ou non. La longueur du
        terrain est de 38 mètres et la largeur de 18 mètres pour les matchs
        internationaux.</TextArticle
      ><br />
      <TextArticle
        >A l’inverse du football, il n’y a pas de hors-jeu ni de tacle. Un
        joueur peut remplacer le gardien. Ce sport est très pratiqué à la fois
        au niveau scolaire et au niveau universitaire. Il est particulièrement
        apprécié car il allie intensité et concentration.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/futsal-g3cc7058a1_1920.jpg"
          alt="Futsal Fit Arena"
        />
      </div>
      <br />
      <H2Article>Où trouver un terrain de futsal ?</H2Article><br />
      <TextArticle
        >De nombreux clubs de sport proposent aujourd’hui des salles de futsal.
        Tout comme Fit Arena ! Notre salle multisports connectée s’adapte à vos
        envies en vous permettant de pratiquer facilement du futsal avec vos
        amis.</TextArticle
      ><br />
      <TextArticle
        >Vous avez juste à réserver en quelques clics votre créneau et inviter
        vos amis. Ensuite, Fit Arena s’occupe de tout ! Nos équipements
        motorisés s’adaptent automatiquement quelques minutes avant votre
        arrivée pour profiter d’une séance de sport digitale et conviviale
        !</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle === 'terrain-de-volley-a-louer'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Votre nouveau terrain de volley avec Fit Arena</H1Article>
      <TextArticle
        >Proposant une large offre de sports, Fit Arena devient votre nouveau
        terrain d’entraînement pour le volley. Avec ses marquages au sol et ses
        équipements motorisés, venez vous entraîner dans un bâtiment moderne,
        lumineux et digital parfaitement adapté pour la pratique du volley-ball
        tous niveaux. Réservez votre créneau en quelques minutes sur
        l’application ou le site Fit Arena, profitez d’une belle séance de sport
        et regardez ensuite vos meilleures actions en replay afin de progresser
        pour la prochaine session. Une expérience optimale en amont et en aval
        de votre match de volley qui saura vous satisfaire et vous convaincre
        que s’entraîner au sein d’une salle connectée, c’est idéal
        !</TextArticle
      ><br />
      <H2Article>Origine du volley-ball</H2Article><br />
      <TextArticle
        >Ce sport nous vient tout droit du continent américain, et plus
        précisément des Etats-Unis grâce au pasteur et professeur d’éducation
        physique à la YMCA (Young Men's Christian Association, ou Union
        chrétienne de jeunes gens, UCJG) William G. Morgan en 1895.</TextArticle
      ><br />
      <TextArticle
        >En s’inspirant du basket-ball, du tennis, du baseball et du handball,
        William Morgan a souhaité créer un sport de salle moins intensif que le
        basket afin de pouvoir plaire à un plus large public. Ce sport se fait
        initialement connaître sous le nom de « mintonette ». Un an plus tard,
        lors du premier match d’exhibition, le nom évolue pour devenir « volley
        ball » (initialement écrit en deux mots), association de volley pour
        volée et de ball pour ballon.</TextArticle
      ><br />
      <TextArticle
        >La Fédération Française de Volley (anciennement FFVB, aujourd’hui
        FFVolley) voit le jour en 1936. Elle est à l’origine de la fondation de
        La Fédération Internationale de Volley-ball (FIVB) en 1947 à Paris. Le
        premier championnat du monde masculin est organisé en 1949, le
        championnat féminin en 1952.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/volleyball-g3ca13c416_1920.jpg"
          alt="Volley Fit Arena"
        />
      </div>
      <br />
      <H2Article>Le volley-ball aux Jeux Olympiques</H2Article><br />
      <TextArticle
        >L’histoire entre les JO et le volley-ball débute en 1924 à Paris. A
        cette époque, ce sport n’est pratiqué que dans le cadre d’un évènement
        de démonstration sportive. Son inclusion officielle n’est envisagée
        qu’une fois la FIVB créée. Lors de la 53ème session du Comité
        international olympique à Sofia en Bulgarie, un tournoi spécial est
        organisé pour encourager l’ajout du volley-ball dans la liste des
        disciplines olympiques. La compétition a connu un tel succès que ce
        sport est officiellement inclus dans le programme des Jeux Olympiques
        d’été en 1964.</TextArticle
      ><br />
      <TextArticle
        >Le beach-volley, quant à lui, est né sur les plages californiennes en
        1930 et doit attendre 1996 pour être intégré au programme olympique,
        contribuant à hisser sa portée et sa popularité à un niveau
        mondial.</TextArticle
      ><br />
      <H2Article>Popularité du volley-ball</H2Article><br />
      <TextArticle
        >Depuis deux décennies, le volley-ball a connu une croissance notable,
        sous l’impulsion du développement de compétitions internationales
        organisées par la fédération internationale : championnats du monde,
        ligue mondiale, grand prix mondial, coupe du monde, coupe des grands
        champions et évidemment jeux olympiques. Les deux versions de ce sport –
        en salle et sur sable – jouissent d’une très forte
        popularité.</TextArticle
      ><br />
      <TextArticle
        >Aujourd’hui, ce sport compte plus de 800 millions de pratiquants dans
        le monde. Le souhait de son créateur William Morgan de créer un sport
        ouvert à tous semble donc être accompli.</TextArticle
      ><br />
      <H2Article>Règles sur un terrain de volley-ball</H2Article><br />
      <TextArticle
        >Cette discipline est un sport de ballon dans lequel deux équipes de six
        joueurs s’affrontent. Séparées par un filet au milieu du terrain,
        l’objectif est de faire atterrir le ballon chez l’adversaire sans
        commettre de faute.</TextArticle
      ><br />
      <TextArticle
        >Les deux équipes s’affrontent sur un terrain de 18 mètres sur 9 mètres.
        La balle est mise en jeu au service, dans un délai de 8 secondes après
        le coup de sifflet. Chaque équipe peut toucher le ballon 3 fois avant de
        l’envoyer du côté du camp adverse. Il n’y a pas de limite de temps : le
        jeu s’effectue en sets, 25 points étant nécessaires pour remporter un
        set, et 3 sets gagnants octroyant une victoire.</TextArticle
      ><br />
      <TextArticle
        >Concernant le beach-volley, les règles sont similaires hormis le fait
        que les dimensions du terrain sont plus petites (16 mètres sur 8) et le
        match se joue pieds nus sur le sable. L’irrégularité du terrain rend ce
        sport très exigeant physiquement : la durée du set est donc plus faible
        que pour le volley-ball (entre 15 et 21 points contre 25 points en
        salle). Chaque équipe est composée de seulement 2 joueurs.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/sport-g0e39111d7_1920.jpg"
          alt="Volley Fit Arena"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'salle-basket-5x5'"
      class="mx-8 text-justify"
    >
      <H1Article>Votre salle de basket 5x5 avec Fit Arena</H1Article>
      <TextArticle
        >Avec Fit Arena, ne craignez plus les intempéries. Qu’il pleuve, qu’il
        vente ou qu’il neige, notre salle de basket saura vous accueillir dans
        les meilleures conditions pour une séance de sport optimale. En trois
        clics, réservez votre créneau sur le site internet ou l’application,
        invitez 9 joueurs et à vous le match de basket ! Notre gymnase de basket
        ouvert au public, avec ses façades et sa couverture en membrane textile,
        vous offre des conditions de jeu optimales avec une luminosité
        naturelle, une atmosphère régulée et une acoustique maîtrisée ! Avec Fit
        Arena, la météo ne sera plus une excuse pour ne pas jouer au basket
        !</TextArticle
      ><br />
      <H2Article>Origine du basket</H2Article><br />
      <TextArticle
        >Le basketball – aussi appelé basket – provient du Massachussetts aux
        Etats-Unis. Comme pour le volley-ball, nous devons la création de ce
        sport à un instructeur du YMCA Training School, James W. Naismith. Ce
        professeur cherchait, en 1891, un jeu d’intérieur qui permettrait à ses
        élèves de rester en forme et au chaud pour les rudes périodes hivernales
        de la Nouvelle-Angleterre. Parmi les 13 règles formulées à cette époque,
        la quasi-totalité est encore appliquée sur les matchs
        d’aujourd’hui.</TextArticle
      ><br />
      <TextArticle
        >A l’origine, le panier de basket était fait en filets de pêche. Trop
        fragiles, ils ont été remplacés par des arceaux métalliques avec des
        panneaux accrochés à l’arrière. Concernant le ballon, il y a aussi eu
        une belle évolution pour cet équipement. Le ballon de basket n’a pas
        toujours été orange ! Dans ses prémisses, le basket se jouait avec un
        ballon de football. Des ballons de couleur brune ont ensuite été créés
        spécifiquement pour ce sport. Il faudra attendre la fin des années 1950
        et Tony Hinkle pour que le ballon orange soit couramment utilisé. Sa
        couleur a été choisie afin de permettre au ballon d’être plus visible
        pour les joueurs et les spectateurs.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/basketball-gf795e7596.jpg"
          alt="Basket 5x5 Fit Arena"
        />
      </div>
      <br />
      <H2Article>Les règles du basket</H2Article><br />
      <TextArticle
        >Le basket est un sport nécessitant peu d’équipements avec des règles de
        base accessibles à tous.</TextArticle
      ><br />
      <TextArticle
        >Un match se compose de 4 périodes de 10 minutes séparées de 2 minutes
        chacune sauf entre la deuxième et troisième période où l’intervalle est
        de 15 minutes. Pour le marquage de points, un tir réussi compte 2 points
        à l’intérieur de la zone des 6.25m (ou 6.75m selon le niveau de
        pratique) et 3 points à l’extérieur de cette zone. Un tir en
        lancer-franc vaut, lui, 1 point.</TextArticle
      ><br />
      <TextArticle
        >Quand un joueur est en possession du ballon, il ne peut s’immobiliser
        que 3 secondes dans la raquette, zone restrictive entre la ligne des
        lancer-francs et le panier. Quand une équipe prend possession du ballon
        sur le terrain, elle a alors 24 secondes pour procéder à un tir au
        panier. Si le ballon touche l’arc du panier, alors l’équipe bénéficie
        d’une nouvelle période de 24 secondes pour tirer.</TextArticle
      ><br />
      <TextArticle
        >Les règles officielles sont établies par la FIBA, Fédération
        Internationale du Basketball. Elles sont les seules reconnues
        internationalement. Les règles du 5 contre 5 de la FIBA diffèrent
        légèrement des règles de la NBA (National Basketball Association), la
        WNBA (Women’s National Basketball Association) et de la NCAA (National
        Collegiate Athletic Association).</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/basketball-g3fb8668a3.jpg"
          alt="Basket 5x5 Fit Arena"
        />
      </div>
      <br />
      <H2Article>La popularité du basket en France</H2Article><br />
      <TextArticle
        >La Fédération Française de Basketball (FFBB) est fondée en 1932. Elle
        est en charge d’organiser, de diriger et de développer le basketball en
        France. Et au vu des chiffres, la fédération remplit son rôle ! Le
        basket est un sport particulièrement attractif en France. Les derniers
        chiffres de 2015 le présente comme le deuxième sport collectif pratiqué
        et réunit 2.5 millions de pratiquants dont 660 000 licenciés. En NBA, la
        France est le deuxième pays représenté avec 11 joueurs.</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'nouvelle-salle-handball'"
      class="mx-8 text-justify"
    >
      <H1Article>Fit Arena, votre nouvelle salle de handball</H1Article>
      <TextArticle
        >Le handball fait partie du top 10 des sports les plus pratiqués en
        France. Né en Europe, sa popularité s’est étendue à l’échelle
        mondiale.</TextArticle
      ><br />
      <TextArticle
        >Vous souhaitez faire une partie de handball avec vos amis ? Vous n’avez
        pas d’abonnement à un club proposant ce sport ? Aucun souci, Fit Arena
        est là pour vous ! Il vous suffit de vous connecter à l’application Fit
        Arena ou à notre site internet, de réserver votre terrain de de
        handball, d’inviter vos coéquipiers et à vous de jouer !</TextArticle
      ><br />
      <TextArticle
        >La salle autonome et connectée Fit Arena s’adapte automatiquement selon
        le sport et le créneau réservés grâce à ses équipements motorisés. Une
        opportunité pour pratiquer différents sports, vous essayer à de
        nouvelles disciplines comme le handball sans avoir besoin de vous
        inscrire à un club dédié. Une belle opportunité donc pour faire varier
        vos activités sportives ! N’attendez plus pour pratiquer le handball
        avec Fit Arena.</TextArticle
      ><br />
      <H2Article>Les origines scandinaves du handball</H2Article><br />
      <TextArticle
        >Le handball date du XXème siècle et est né de la fusion de trois sports
        : le hand-bold d’origine danoise, l’hazena en provenance de la
        Tchécoslovaquie et le tor-boll, sport féminin allemand.</TextArticle
      ><br />
      <TextArticle
        >Dans les années 1900, l’irlandais Casey introduit un jeu semblable au
        hand-ball aux Etats-Unis. L’engouement est tel qu’en 1919 une
        compétition voit le jour. En Allemagne la même année, Carl Schellenz,
        professeur d’éducation physique, adapte le torball pour des athlètes
        masculins : on le joue alors en plein air sur un grand terrain avec un
        ballon de la taille d’un ballon de football. Le handball à 11 vient de
        se créer.</TextArticle
      ><br />
      <TextArticle
        >En 1920, ce nouveau sport est baptisé handball, contraction de « hand »
        pour « main » et de « ball » pour « ballon ». La FIHA (Fédération
        Internationale de Handball Amateur) établit un règlement international 8
        ans plus tard. Ce n’est qu’en 1946, à l’initiative de huit pays dont la
        France, que la Fédération internationale de handball est
        créée.</TextArticle
      ><br />
      <TextArticle
        >En 1959, le handball à 11 disparait pour ne laisser place qu’au
        handball à 7.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/woman-gcb1c69786.jpg"
          alt="Handball Fit Arena"
        />
      </div>
      <br />
      <H2Article>La notoriété de ce sport collectif</H2Article><br />
      <TextArticle
        >Le handball rentre dans la famille des disciplines olympiques en 1936
        aux Jeux Olympiques de Berlin pour une unique olympiade. La popularité
        de ce sport s’étend aux pays du nord qui développent la pratique de ce
        sport en intérieur, du fait des conditions climatiques rudes en hiver.
        Les premiers championnats du monde de handball à 7 et à 11 sont
        organisés en 1938. Il faut attendre ensuite 1952 pour que le premier
        championnat du monde de hand-ball d’après-guerre ait lieu. 20 ans plus
        tard, le handball est de nouveau admis aux JO.</TextArticle
      ><br />
      <TextArticle
        >En France, le handball est d’abord pratiqué uniquement dans les régions
        de l’est de 1925 à 1940. Il s’étend ensuite dans tout le pays jusqu’à
        donner lieu à la création de la Fédération française de handball en 1941
        et à l’organisation du premier championnat de France la même
        année.</TextArticle
      ><br />
      <TextArticle
        >Aujourd’hui, le handball à 7 est le sport scolaire numéro un en France.
        Il est également très répandu parmi les étudiants. La Fédération
        française compte près de 240 000 licenciés.</TextArticle
      ><br />
      <H2Article>Les règles du handball</H2Article><br />
      <TextArticle
        >Le handball se pratique sur un terrain rectangulaire de 40 mètres par
        20 mètres, comprenant une surface de jeu et deux surfaces de but. Une
        équipe se compose de 12 joueurs dont 7 sont sur le terrain, les autres
        étant remplaçants. Un match dure une heure, soit deux fois trente
        minutes avec 15 minutes de pause. A la mi-temps, les équipes changent de
        camp.</TextArticle
      ><br />
      <TextArticle
        >Le but est de faire entrer la balle dans le but du camp adversaire.
        Pour se déplacer, le joueur peut dribbler. Une fois le ballon en main,
        il ne peut le garder que trois secondes dans sa main en étant immobile
        avant de l’envoyer à son coéquipier. Le joueur n’a le droit de faire que
        trois pas sans dribbler.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/ball-g979384bbd.jpg"
          alt="Handball Fit Arena"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle === 'yoga-agit-sur-le-corps-et-esprit'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Le yoga, ce sport qui agit sur le corps et l’esprit</H1Article>
      <TextArticle
        >Le yoga est une discipline sportive faisant travailler le corps et
        l’esprit. Elle comprend une variété d’exercices et de techniques à la
        fois physiques, respiratoires, de méditation et de
        relaxation.</TextArticle
      ><br />
      <H2Article>Fit Arena, votre nouvelle salle de yoga</H2Article><br />
      <TextArticle
        >Avec Fit Arena, venez pratiquer votre yoga dans les meilleures
        conditions. Construite avec une charpente bois ainsi que des façades et
        une couverture en membrane textile, notre salle multisports connectée
        offre des conditions optimales pour la pratique sportive. Le bois permet
        une reconnexion de l’Homme avec la nature tandis que la membrane textile
        offre une lumière naturelle agréable et propice à la relaxation en
        comparaison à un éclairage artificiel. Elle apporte également un confort
        acoustique, essentiel pour une bonne pratique du yoga.</TextArticle
      ><br />
      <TextArticle
        >Les caméras disponibles au sein de la salle permettent de capter
        certains moments de votre séance. Les replays vidéos, disponibles depuis
        votre application, vous permettront de refaire ensuite certains
        exercices chez vous plus facilement voire de corriger vos
        postures.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/yoga-gfb44bb3cd_1920.jpg"
          alt="Yoga Fit Arena"
        />
      </div>
      <br />
      <H2Article>Les origines du yoga</H2Article><br />
      <TextArticle
        >Le yoga est un sport ancestral puisqu’il aurait été créé en Inde il y a
        près de 5000 ans ! C’est à l’époque de Patañjali en 200 avant
        Jésus-Christ que les premiers écrits fondateurs du yoga ont vu le jour.
        195 courts textes rédigés en sanskrit, appelés yoga-sûtras, ont permis
        de guider la pratique de ce sport. De grands maîtres ont procédé à leur
        traduction en français et en anglais afin de favoriser leur
        accessibilité. Selon la légende, Patañjali serait descendu des cieux en
        se réincarnant sous la forme d’un serpent, pour enseigner le yoga sur
        Terre et aider l’humanité.</TextArticle
      ><br />
      <H2Article>Les différentes formes de yoga</H2Article><br />
      <TextArticle
        >Les 195 textes du yoga-sûtra peuvent être répartis en 4 chapitres : la
        concentration, la pratique spirituelle, les différentes techniques du
        yoga pour atteindre un état de conscience supérieure puis la libération,
        but ultime de cette discipline.</TextArticle
      ><br />
      <TextArticle
        >Le yoga s’est depuis occidentalisé. Il compte aujourd’hui 250 millions
        de pratiquants dans le monde, dont un million en France. Il existe
        différentes formes de yoga :</TextArticle
      ><br />
      <List class="sm:ml-10 ml-5">
        <HyphenListItem
          >Le hatha yoga, dit le « yoga classique » : c’est la forme la plus
          répandue. Sous forme d’exercices de respiration et d’étirements, le
          corps se prépare à la méditation. Il permet aux sportifs de se muscler
          en profondeur tout en gagnant en souplesse.</HyphenListItem
        >
        <HyphenListItem
          >L’ashtanga yoga : en combinant exercices respiratoires et posturaux,
          cette forme de yoga est très dynamique. Elle permet le renforcement
          musculaire et le développement de la souplesse.</HyphenListItem
        >
        <HyphenListItem
          >Le kundalini yoga : nécessitant déjà une bonne maîtrise de ce sport,
          cette forme de yoga consiste à enchaîner plusieurs postures tout en
          étant parfaitement stable. Les exercices sont centrés autour de la
          colonne vertébrale et permettent de bien se connecter avec son
          corps.</HyphenListItem
        >
        <HyphenListItem
          >Le vinyasa yoga : idéal pour travailler sa souplesse et son
          équilibre, cette forme de yoga est axée sur l’enchainement d’exercices
          physiques et de respiration.</HyphenListItem
        ><br /> </List
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/woman-gd0996e33a_1920.jpg"
          alt="Yoga Fit Arena"
        />
      </div>
      <br />
      <H2Article>Les bienfaits du yoga</H2Article><br />
      <TextArticle
        >Le yoga apporte de nombreux bienfaits physiques tels que
        l’assouplissement du corps, le renforcement musculaire et l’amélioration
        de l’équilibre. Il apporte des vertus au quotidien en permettant de
        corriger ses erreurs de postures et donc de prévenir des douleurs de dos
        chroniques.</TextArticle
      ><br />
      <TextArticle
        >Le travail de respiration et de méditation participe à la réduction du
        stress. Les temps pris pour écouter son corps et se focaliser sur son
        souffle développent également la capacité de concentration.</TextArticle
      ><br />
      <TextArticle
        >C’est une discipline qui se pratique en douceur, sans aucune agression
        pour le corps. Elle a des vertus relaxantes. La méditation aide à mieux
        dormir.</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'histoire-du-fitness'"
      class="mx-8 text-justify"
    >
      <H1Article>Histoire du fitness</H1Article>
      <TextArticle
        >Le fitness est un sport très populaire aujourd’hui. Pour autant, il est
        assez récent en comparaison avec certains sports collectifs ou de
        raquettes. Alors d’où vient l’entraînement fitness ? Quels sont ses
        bienfaits pour le corps ? C’est ce que nous allons découvrir
        ensemble.</TextArticle
      ><br />
      <H2Article>Fit Arena, votre salle de fitness</H2Article><br />
      <TextArticle
        >Vous cherchez une salle multisports lumineuse, calme et tempérée pour
        pratiquer vos cours de fitness ? Vous l’avez trouvée avec Fit Arena !
        Construite avec des façades et une couverture en membrane textile, la
        salle connectée Fit Arena est fermée tout en restant ouverte sur
        l’extérieur. La translucidité de la membrane textile permet de laisser
        passer la lumière naturelle tout en renvoyant les rayons du soleil. La
        salle est donc lumineuse sans être surchauffée. Grâce à un système de
        régulation hygrothermique et une ventilation efficace, vous bénéficiez
        d’une température idéale toute l’année pour pratiquer votre
        sport.</TextArticle
      ><br />
      <TextArticle
        >L’autonomie de cette salle vous permet de réserver facilement votre
        créneau. En quelques clics, vous pouvez réserver votre séance et même
        inviter vos amis. Les caméras disponibles au sein de la salle permettent
        d’enregistrer les temps forts de votre cours de fitness. Toutes les
        vidéos sont ensuite disponibles depuis votre compte Fit Arena afin de
        pouvoir progresser et refaire en toute simplicité la séance de sport
        chez vous !</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/fitness-room-g4f5f495aa_1920.jpg"
          alt="Fitness Fit Arena"
        />
      </div>
      <br />
      <H2Article>L'adoption du fitness dans les pratiques sportives</H2Article
      ><br />
      <H3Article>Définition du fitness</H3Article>
      <TextArticle
        >Né d’une prise de conscience de la culture physique, le fitness est un
        ensemble de disciplines permettant de travailler le tonus du corps, le
        dynamisme et la souplesse. Il permet d’améliorer la condition physique
        en regroupant des activités de cardio et d’aérobic avec ou sans
        accessoires. Son appellation vient de l’abréviation de « Physical
        Fitness » qui signifie forme physique.</TextArticle
      ><br />
      <H3Article>Les origines militaires du fitness</H3Article>
      <TextArticle
        >C’est après la seconde guerre mondiale que l’entretien de la silhouette
        devient une préoccupation au sein de la population, notamment aux
        Etats-Unis. Pour autant, ce concept serait véritablement né quelques
        années plus tard, dans les années 60, avec le Dr Kenneth Cooper, médecin
        et lieutenant-colonel de l’US Army. Il a mis au point une méthode
        d’entraînement pour les sédentaires en s’inspirant de l’entraînement des
        Marines afin de proposer une alternative à la musculation permettant
        d’activer le système cardio vasculaire et de gainer le corps. En 1968,
        il publie un ouvrage intitulé « Aerobics » qui donnera le nom à cette
        méthode. Il peaufinera cette discipline pendant une vingtaine d’années
        avant de devenir le sport adapté et destiné aux « ménagères de plus de
        35 ans ».</TextArticle
      ><br />
      <H3Article>Le fitness moderne</H3Article>
      <TextArticle
        >En 1970, Jane Fonda, actrice de cinéma, développe de son côté un
        ensemble d’exercices d’aérobic Work Out à la télé, participant ainsi à
        la popularité de ce sport. Une décennie plus tard, le fitness moderne se
        développe en France par le biais d’émissions télévisées très populaires
        telles que Gym Tonic, diffusée tous les dimanches matins et animée par
        Véronique et Davina. Le muscle féminin devient alors
        esthétique.</TextArticle
      ><br />
      <TextArticle
        >Les cours de fitness se diversifient au fil des années et de nombreuses
        déclinaisons voient le jour : step, aquagym, bodysculpt, stretching sans
        oublier les fameux abdos-fessiers. Puis, pour attirer également les
        hommes en salle de fitness, les cours innovent avec de nouvelles
        variantes comme le body pump, le body combat ou encore le body
        balance.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/woman-g0d8d7226e_1920.jpg"
          alt="Fitness Fit Arena"
        />
      </div>
      <br />
      <H2Article>Les bienfaits du fitness</H2Article><br />
      <TextArticle
        >Regroupant plusieurs exercices, le fitness apporte de nombreuses vertus
        pour le corps. Ce sport permet d’améliorer son endurance
        cardiovasculaire et son souffle notamment par le biais d’exercices de
        cardio. Il travaille également l’équilibre et la coordination grâce à
        l’enchaînement de mouvements variés. Il permet enfin de réduire les
        tensions et le stress. Tous les exercices cumulés octroient une
        tonification du corps et aident à la perte de poids.</TextArticle
      ><br />
      <TextArticle
        >Pour affiner la taille et muscler son corps, le fitness est idéal. Il
        permet de travailler différentes parties de votre corps : le ventre, les
        cuisses, la poitrine ou encore les fessiers.</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>
  </div>
</template>

<script>
import articlesSport from "@/data/mock/articles-sport.json";
import HyphenListItem from "@/components/atomic/HyphenListItem";
import List from "@/components/atomic/List";
import H1Article from "@/components/atomic/H1Article";
import H2Article from "@/components/atomic/H2Article";
import H3Article from "@/components/atomic/H3Article";
import TextArticle from "@/components/atomic/TextArticle";

export default {
  name: "FitArenaArticleSport",
  data() {
    return {
      articlesSport: articlesSport,
    };
  },
  components: {
    HyphenListItem,
    List,
    H1Article,
    H2Article,
    H3Article,
    TextArticle,
  },
  mounted() {
    if (this.$route.params.indexArticle === "infos-padel") {
      document.title =
        "Location terrain de padel – Salle multisports Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vous cherchez un terrain de padel ? Découvrez le concept Fit Arena, des salles multisports connectées pour jouer en toute liberté. Réservez votre séance !"
        );
    }
    if (this.$route.params.indexArticle === "zoom-foot-5") {
      document.title =
        "Terrain de five, location terrain foot5 – Salle multisports Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vous cherchez un terrain de five ? Découvrez le concept Fit Arena, des salles multisports connectées pour jouer en toute liberté. Réservez votre séance !"
        );
    }
    if (this.$route.params.indexArticle === "basket-3x3") {
      document.title =
        "Salle de basket 3x3 ouverte au public – Salle multisports Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vous cherchez une salle de basket 3x3 ouverte au public ? Découvrez le concept Fit Arena, des salles multisports connectées pour jouer en toute liberté. Réservez votre séance !"
        );
    }
    if (this.$route.params.indexArticle === "salle-badminton-connectee") {
      document.title =
        "Salle de badminton couvert, la salle multisports Fit Arena est votre solution";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vous recherchez un gymnase pour pratiquer le badminton ? Réservez votre terrain Fit Arena. Nos équipements motorisés s’adaptent selon le sport que vous souhaitez pratiquer."
        );
    }
    if (
      this.$route.params.indexArticle ===
      "les-terrains-de-tennis-couvert-Fit-Arena"
    ) {
      document.title =
        "Terrain de tennis couvert ouvert au public – Salle multisports Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vous cherchez un terrain de tennis intérieur ouvert au public ? Découvrez le concept Fit Arena et ses équipements sportifs qui s’adaptent selon le sport réservé."
        );
    }
    if (
      this.$route.params.indexArticle ===
      "le-tennis-de-table-d-activite-de-loisirs-a-sport-olympique"
    ) {
      document.title = "Salle de tennis de table – Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Fit Arena, votre nouvelle salle de ping pong. Réservez votre séance dès à présent !"
        );
    }
    if (this.$route.params.indexArticle === "futsal-sport-derive-du-football") {
      document.title =
        "Terrain de futsal – Salle multisports connectée Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Votre terrain de futsal réservable en quelques clics grâce à Fit Arena ! N’attendez plus pour réserver votre créneau via le site internet fitarena.fr ou l’application."
        );
    }
    if (this.$route.params.indexArticle === "terrain-de-volley-a-louer") {
      document.title =
        "Terrain de volley à louer – Salle multisports Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vous cherchez un terrain de volley-ball ? Découvrez le concept Fit Arena, des salles multisports connectées pour jouer en toute liberté. Réservez votre séance !"
        );
    }
    if (this.$route.params.indexArticle === "salle-basket-5x5") {
      document.title =
        "Salle de basket et gymnase ouvert au public – Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Avec Fit Arena, jouez vos matchs de basket dans un bâtiment sportif lumineux offrant un confort de jeu optimal ! N’attendez plus pour réserver votre créneau."
        );
    }
    if (this.$route.params.indexArticle === "nouvelle-salle-handball") {
      document.title =
        "Terrain de handball – Salle de handball connectée Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Avec Fit Arena, il vous suffit de quelques clics pour réserver votre séance de handball. Notre salle connectée s’adapte selon le sport réservé grâce à ses équipements motorisés."
        );
    }
    if (
      this.$route.params.indexArticle === "yoga-agit-sur-le-corps-et-esprit"
    ) {
      document.title = "Salle de yoga – Réserver salle multisports Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "La salle multisports connectée Fit Arena nous offre des conditions optimales pour la pratique du yoga."
        );
    }
    if (this.$route.params.indexArticle === "histoire-du-fitness") {
      document.title = "Fit Arena - Réservez votre salle de fitness";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Avec Fit Arena, pratiquez le fitness en toute simplicité. Réservez votre séance de fitness depuis le site ou l’application mobile en quelques clics seulement."
        );
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
      setTimeout(() => {
        this.$router.push("#sport");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.font {
  font-family: $doi--text-font-family5;
}

@media (min-width: $doi-breakpoint-desktop) {
  #futsal {
    width: 30%;
  }
}

@media (max-width: $doi-breakpoint-desktop) {
  #futsal {
    width: 50%;
  }
}

@media (max-width: $doi-breakpoint-tablet) {
  #futsal {
    width: 70%;
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .article-img {
    margin-bottom: 0.75rem;
  }
}
</style>
