
import '@/utils/loadAutoBaseComponent'
import Vue from 'vue'
import App from '@/App.vue'// import du Router global
import router from '@/router' // import du Router global
import store from '@/store' // import du gestionnaire d'état global
import 'dayjs/locale/fr'
import VueGtag from 'vue-gtag'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import VueToast from 'vue-toast-notification'
import dayjs from 'dayjs'
import VueSocialSharing from 'vue-social-sharing'

import '@/directives/clickOutsideDirective'
/*
 * LAYOUTS
 */
import Blank from '@/layouts/Blank'
import FitArena from '@/layouts/FitArena'
import ContainerApp from '@/layouts/ContainerApp'

import '@/assets/scss/main.scss'
import '@/assets/css/core.css'
import '@/assets/css/main.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import './registerServiceWorker'
Vue.prototype.window = window

Vue.component('fitarena', FitArena)
Vue.component('blank', Blank)
Vue.component('containerApp', ContainerApp)

// https://matteo-gabriele.gitbook.io/vue-gtag/
// use like this in component :this.$gtag.event('login', { method: 'Google' })
Vue.prototype.window = window
Vue.use(VueGtag, {
  config: { id: 'GTM-W68WBNM' },
  enabled: false
})

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueToast)
Vue.use(VueSocialSharing);
dayjs.locale('fr')

Vue.config.productionTip = process.env.VUE_APP_PROD
if (window.cordova) {
  // add deviceready event to start app
  document.addEventListener('deviceready', () => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }, false)
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
