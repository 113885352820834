<template>
  <div class="mx-4">
    <div v-if="contenu.length > 0 && !cartList.v2">
      <div v-for="data in contenu" :key="data.id">
        <CartItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :v2="cartList.v2"
        />
      </div>
      <div class="flex">
        <div class="w-14 mr-2 sm:w-20"></div>
        <div
          v-if="totalPrice"
          class="flex flex-col items-end justify-end mt-8 w-4/6 mb-6"
        >
          <DoiText
            :textColor="0"
            :fontSize="2"
            :fontFamily="1"
            class="tracking-wide md:text-base lg:text-xl"
            >TOTAL À PARTAGER : {{ (totalPrice / 100).toFixed(2) }} €</DoiText
          >
          <DoiText
            :fontFamily="4"
            :fontSize="0"
            :textColor="1"
            class="flex md:block text-right leading-3 md:text-sm"
            >La somme de chaque réservation sera répartie entre les
            participants.</DoiText
          >
        </div>
        <div class="w-1/6"></div>
      </div>
    </div>
    <div
      v-if="contenuTerrain.length > 0"
      :class="
        contenuPlace.length > 0 || contenuCours.length > 0
          ? 'border-b border-grey border-dotted mb-4 pb-4'
          : ''
      "
    >
      <div v-for="data in contenuTerrain" :key="data.id">
        <CartItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :v2="cartList.v2"
        />
      </div>
      <div class="flex">
        <div class="w-14 mr-2 sm:w-20"></div>
        <div
          v-if="totalPriceTerrain"
          class="flex flex-col items-end justify-end lg:mr-24 mt-8 w-4/6"
        >
          <DoiText
            :textColor="0"
            :fontSize="2"
            :fontFamily="1"
            class="tracking-wide md:text-base lg:text-xl"
            >TOTAL À PARTAGER : {{ totalPriceTerrain.toFixed(2) }} €</DoiText
          >
          <DoiText
            :fontFamily="4"
            :fontSize="0"
            :textColor="1"
            class="flex md:block text-right leading-3 md:text-sm"
            >La somme de chaque réservation sera répartie entre les
            participants.</DoiText
          >
        </div>
        <div class="w-1/6"></div>
      </div>
    </div>
    <div v-if="contenuPlace.length > 0">
      <div v-for="data in contenuPlace" :key="data.id">
        <CartItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :v2="cartList.v2"
        />
      </div>
    </div>
    <div v-if="contenuCours.length > 0">
      <div v-for="(data, index) in contenuCours" :key="data.id">
        <CartItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :v2="cartList.v2"
          @toggleOpen="toggleOpen(index)"
          :index="index"
          :openIndex="openIndex"
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-14 mr-2 sm:w-20" />
      <div
        v-if="
          contenuTerrain.length !== 0 ||
          contenuCours.length !== 0 ||
          contenuPlace.length !== 0
        "
        class="flex flex-col items-end justify-end mt-8 w-4/6"
      >
        <DoiText
          v-if="fullTotal"
          :textColor="1"
          :fontSize="3"
          :fontFamily="1"
          class="pb-2 tracking-wide mt-6 md:text-2xl"
          >TOTAL :
          {{ (totalPrice / 100).toFixed(2) }}
          €</DoiText
        >
        <div class="w-5/6 md:w-3/6 lg:w-2/6 border-t border-grey"></div>
      </div>
      <div class="w-1/6"></div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/views/cart/CartItem";

export default {
  name: "CartList",
  data() {
    return {
      openIndex: -1,
      contenu: [],
      contenuTerrain: [],
      contenuPlace: [],
      contenuCours: [],
    };
  },
  props: {
    cartList: {
      type: Object,
      default: {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    shareBill: Boolean,
  },
  components: { CartItem },
  async mounted() {
    await this.typeCreneau();
  },
  computed: {
    fullTotal() {
      // Filtrer les trois sous-totaux pour savoir combien sont supérieurs à 0
      // Afficher le total si au moins deux sous-totaux sont supérieurs à 0
      return (
        [
          this.totalPriceTerrain,
          this.totalPriceWithType("place"),
          this.totalPriceWithType("cours"),
        ].filter((item) => item > 0).length >= 2
      );
    },
    totalPrice() {
      return this.cartList.tarif;
    },
    totalPriceTerrain() {
      let total = 0;
      this.contenuTerrain.forEach((item) => {
        if (item.tarif !== 0 && item.nb_place !== 0) total += item.tarif / 100;
      });
      return total;
    },
  },
  methods: {
    totalPriceWithType(type) {
      let total = 0;
      const items = type === "place" ? this.contenuPlace : this.contenuCours;

      items.forEach((item) => {
        total += item.tarif / 100;
      });
      return total;
    },
    toggleOpen(index) {
      this.openIndex = this.openIndex === index ? -1 : index;
    },
    async deleteCartElement(idLigne) {
      if (this.cartList.v2) {
        if (this.contenuTerrain.length > 0) {
          this.contenuTerrain.forEach((item, i) => {
            if (item.id === idLigne) {
              this.contenuTerrain.splice(i, 1);
            }
          });
        }
        if (this.contenuPlace.length > 0) {
          this.contenuPlace.forEach((item, i) => {
            if (item.id === idLigne) {
              this.contenuPlace.splice(i, 1);
            }
          });
        }
        if (this.contenuCours.length > 0) {
          this.contenuCours.forEach((item, i) => {
            if (item.id === idLigne) {
              this.contenuCours.splice(i, 1);
            }
          });
        }
      } else {
        this.contenu.forEach((item, i) => {
          if (item.id === idLigne) {
            this.contenu.splice(i, 1);
          }
        });
      }
      this.$emit("deleteCartElement", idLigne);
    },
    async typeCreneau() {
      this.contenu = this.cartList.contenu ?? [];
      this.cartList.contenu.forEach((item) => {
        if (item.typeCreneau !== undefined) {
          if (item.typeCreneau === "terrain") {
            this.contenuTerrain.push(item);
          } else if (item.typeCreneau === "place") {
            this.contenuPlace.push(item);
          } else if (item.typeCreneau === "cours") {
            this.contenuCours.push(item);
          }
        }
      });
      return this.contenu;
    },
  },
};
</script>
