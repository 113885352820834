<template>
  <span :class="colorClass" class="line flex invisible lg:visible" />
</template>

<script>
export default {
  name: "DoiLine",
  props: {
    color: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    colorClass() {
      if (typeof this.color === "number") return "color" + this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.line {
  height: 2px;
  width: 100%;
  background-color: $doi--color-color0;
}
</style>
