<template>
  <div class="min-h-screen flex flex-col">
    <div class="flex flex-1 flex-col">
      <div class="title-long-border"></div>
      <div
        class="mx-auto lg:max-w-2k flex justify-center p-8 lg:p-20 flex-col items-center w-full lg:w-5/12"
      >
        <div class="py-8 flex flex-col items-center">
          <div class="flex flex-col items-center justify-center text-center">
            <DoiHeader :level="4" :fontFamily="1" :headerColor="0" class="mb-4"
              >RÉINITIALISATION DU MOT DE PASSE</DoiHeader
            >
          </div>
        </div>
        <div class="w-full">
          <form @submit.prevent="submit">
            <div class="mb-6">
              <DoiInput
                type="password"
                v-model.trim="$v.password.$model"
                :error="$v.password.$error"
                placeholder="Votre nouveau mot de passe *"
              />
              <DoiText
                italic
                :textColor="0"
                :fontSize="1"
                v-if="!$v.password.required && $v.password.$error"
                >Ce champ est requis</DoiText
              >
            </div>
            <div class="mb-4">
              <DoiInput
                type="password"
                v-model.trim="$v.passwordConfirm.$model"
                :error="$v.passwordConfirm.$error"
                placeholder="Confirmation de votre nouveau mot de passe *"
              />
              <DoiText
                italic
                :textColor="0"
                :fontSize="1"
                v-if="!$v.passwordConfirm.required && $v.passwordConfirm.$error"
                >Ce champ est requis</DoiText
              >
              <DoiText
                italic
                :textColor="0"
                :fontSize="1"
                v-if="
                  !$v.passwordConfirm.sameAsPassword &&
                  $v.passwordConfirm.$error
                "
                >Les deux mots de passe ne concordent pas</DoiText
              >
            </div>
            <div class="flex flex-col justify-center mt-10">
              <DoiButton
                :bgHover="1"
                type="submit"
                :disabled="submitStatus === 'PENDING'"
              >
                RÉINITIALISER MON MOT DE PASSE
                <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
              </DoiButton>
              <DoiButton :bgColor="2" :bgHover="3" link="/signin" class="mt-4"
                >CONNEXION</DoiButton
              >
            </div>
          </form>
        </div>
      </div>
    </div>

    <Modal
      title="MOT DE PASSE"
      :open="open"
      size="xs"
      :keepratio="true"
      @close-modal="vCloseAndRedirect()"
    >
      <template slot="modal_content">
        <DoiText
          :textColor="1"
          :fontSize="3"
          :fontFamily="5"
          class="text-center"
          >{{ message }}</DoiText
        >
        <DoiButton :bgHover="1" @click="vCloseAndRedirect()" class="mt-4"
          >OK</DoiButton
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import { required, sameAs } from "vuelidate/lib/validators";
import { reinitPassword, verifyToken } from "@/api/AuthService";

export default {
  name: "ReinitPassword",
  data() {
    return {
      submitStatus: null,
      open: false,
      message: "",
      showSpinner: false,
      password: "",
      passwordConfirm: "",
      nIdUtilisateur: null,
      sMd5: null,
      token: null,
    };
  },
  components: {
    Modal,
  },
  validations: {
    password: { required },
    passwordConfirm: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  async created() {
    this.token = this.$route.params.token;
    const response = await verifyToken(this.token);
    if (response == undefined) {
      this.message =
        "Le token n'est pas valide. Veuillez renouveler votre demande de changement de mot de passe";
      this.open = true;
    }
  },
  computed: {
    getToken() {
      return this.$store.getters["AuthModule/get_token"];
    },
  },
  methods: {
    submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        this.message =
          "Merci de vérifier vos informations et veuillez réessayer";
        this.showSpinner = false;
        this.open = true;
      } else {
        this.reinitPassword();
      }
    },
    vCloseAndRedirect() {
      this.open = false;
      this.$router.push("/signin");
    },
    async reinitPassword() {
      this.submitStatus = "ERROR";
      this.message =
        "Merci de vérifier vos informations et veuillez réessayer.";
      try {
        await reinitPassword(this.password, this.token);
        this.submitStatus = "SUCCESS";
        this.message =
          "Votre mot de passe a bien été changé. Vous pouvez désormais vous connecter.";
      } catch (e) {}

      this.open = true;
      this.showSpinner = false;
    },
  },
  watch: {
    getToken(token) {
      if (token !== null && token !== "") this.$router.push("/profile");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.title-long-border {
  content: "";
  height: 2px;
  width: calc(40% - 250px);
  background-color: $doi--color-color0;
  position: relative;
  top: 130px;
}
</style>
