<template>
  <div>
    <div
      @click="open = !open"
      class="flex px-3 pt-3 justify-between items-center w-full"
    >
      <span class="text-black planning-title">{{ day.sLibelle }}</span>
      <span
        v-if="open === false"
        class="material-icons text-black cursor-pointer"
        >keyboard_arrow_down</span
      >
      <span v-else class="material-icons text-black cursor-pointer"
        >keyboard_arrow_up</span
      >
    </div>
    <div class="w-full border-t pt-4 px-2 mt-4" v-show="open">
      <div v-if="day.aSlot.length > 0" class="flex flex-wrap w-full">
        <div
          @click="handleSelect(slot)"
          v-for="slot in day.aSlot"
          :key="slot.nIdCreneau"
          class="creneau-item p-1"
          :class="getWidth"
        >
          <PlanningLightItemDetail
            :creneau="slot"
            :nbPersonneConseille="nbPersonneConseille"
            :isSelected="
              dataSelected.some(
                (data) => parseInt(data) === parseInt(slot.nIdCreneau)
              )
            "
          />
        </div>
      </div>
    </div>
    <AuthModal
      @close="showModalConnection = false"
      :open="showModalConnection"
    />
  </div>
</template>

<script>
import PlanningLightItemDetail from "@/views/fitarena/PlanningLightItemDetail";
import AuthModal from "@/views/auth/AuthModal";

export default {
  name: "PlanningLightItem",
  data() {
    return {
      open: false,
      dataSelected: [],
      dayData: null,
      showModalConnection: false,
    };
  },
  props: {
    day: Object,
    nbPersonneConseille: String,
    setOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlanningLightItemDetail,
    AuthModal,
  },
  mounted() {
    this.open = this.setOpen;
  },
  methods: {
    emitClickTimeslot(nIdCreneau) {
      this.$emit("clickTimeslot", nIdCreneau);
    },
    async handleSelect(info) {
      info.selected = true;
      const userId = this.$store.getters["UserModule/get_user_id"];

      if (userId === null) this.showModalConnection = true;
      else this.emitClickTimeslot(info);
    },
  },
  computed: {
    getCart() {
      return this.$store.getters["CartModule/get_cart"];
    },
    getIdSport() {
      return this.$store.getters["FitArenaModule/get_id_sport"];
    },
    getDay() {
      return this.$props.day;
    },
    getWidth() {
      if (window.innerWidth < 360) {
        return " w-full ";
      } else if (window.innerWidth >= 360 && window.innerWidth < 768) {
        return " w-1/2 ";
      } else if (window.innerWidth >= 768 && window.innerWidth < 900) {
        return " w-1/3 ";
      } else if (window.innerWidth >= 900 && window.innerWidth < 1024) {
        return " w-1/4 ";
      } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
        return " w-1/5 ";
      }
      return " w-1/6 ";
    },
  },
  watch: {
    getDay(val) {
      this.dayData = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.planning-title {
  font-family: $doi--text-font-family5;
  font-size: $doi--text-font-size4;
}

.planning-title::first-letter {
  text-transform: uppercase;
}
</style>
