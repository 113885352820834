<template>
  <div class="flex flex-1 justify-center">
    <div
      class="md:container items-center px-2 md:px-20 w-full justify-center h-full"
    >
      <div class="mb-6 p-4 bg-gray-100">
        <DoiHeader :level="5" :headerColor="0">Comment ça marche ?</DoiHeader>
        <div>
          <ol>
            <template v-if="isOrganizer">
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >1 - Renseignez vos coordonnées bancaires afin de
                <strong>réserver définitivement le créneau.</strong></DoiText
              >
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >2 - Vos invités devront
                <strong>renseigner leurs coordonnées bancaires</strong> lors de
                l'acceptation de l'invitation.</DoiText
              >
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >3 -
                <strong
                  >Chaque participant sera débité, après la partie,</strong
                >
                d'une somme équivalente au montant total divisé par le nombre de
                participants.</DoiText
              >
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >4 - L'annulation est possible au plus tard
                <strong>48h avant</strong> le début de la partie.</DoiText
              >
            </template>
            <template v-else>
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >1 - Renseignez vos coordonnées bancaires afin de
                <strong>valider votre participation.</strong></DoiText
              >
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >2 -
                <strong
                  >Chaque participant sera débité, après la partie,</strong
                >
                d'une somme équivalente au montant total divisé par le nombre de
                participants.</DoiText
              >
              <DoiText :textColor="1" :fontFamily="5" :fontSize="3"
                >3 - L'annulation est possible au plus tard
                <strong>48h avant</strong> le début de la partie.</DoiText
              >
            </template>
          </ol>
        </div>
      </div>
      <DoiHeader :level="4" class="text-center">MON PANIER</DoiHeader>
      <div class="mt-8 flex justify-between">
        <CartBookingList :cartList="cartList" :readOnly="true" />
      </div>
      <div v-if="!readOnly">
        <div
          class="flex items-center bg-gray-100 p-5 flex-col text-center lg:flex-row"
        >
          <div class="mb-2 sm:mb-0">
            <button @click="setShare('alone')">
              <span
                class="material-icons border-2 border-black rounded-full p-1 ml-2"
                :class="[{ 'bg-red': shareBill == false }]"
                >person</span
              >
            </button>
            <button @click="setShare('everyone')">
              <span
                class="material-icons border-2 border-black rounded-full p-1 ml-2"
                :class="[{ 'bg-green': shareBill == true }]"
                >people</span
              >
            </button>
          </div>
          <DoiText :textColor="3" :fontFamily="2" v-if="shareBill" class="ml-4"
            >PARTAGER LE MONTANT ENTRE LES MEMBRES DE LA RÉSERVATION</DoiText
          >
          <DoiText :textColor="3" :fontFamily="2" v-if="!shareBill" class="ml-4"
            >PAYER L'INTÉGRALITÉ DE LA RÉSERVATION</DoiText
          >
        </div>
      </div>

      <DoiHeader class="my-6 text-center" :level="4"
        >MON MOYEN DE PAIEMENT</DoiHeader
      >
      <PaymentMeans />

      <div v-if="!readOnly">
        <div class="mt-8 flex flex-1 justify-center">
          <div class="flex flex-col sm:flex-row justify-center">
            <DoiButton
              :disabled="isDisabled"
              @click="transformCartToOrder"
              class="mx-1"
              :fontFamily="6"
              :bgHover="1"
            >
              VALIDER
              <DoiSpinner :show="isFetching" :color="1" class="ml-3" />
            </DoiButton>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :open="open"
      :displayHeader="false"
      size="xs"
      type="success"
      :keepratio="true"
      @close-modal="open = false"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="3" :fontSize="3" :fontFamily="5"
            >Votre réservation a bien été prise en compte.</DoiText
          >
        </div>
      </template>
      <template slot="modal_footer">
        <div class="p-4">
          <DoiButton link="/profile" @click="open = false" :bgColor="6"
            >OK</DoiButton
          >
        </div>
      </template>
    </Modal>
    <Modal
      :video="video"
      title="CONSIGNES DE SÉCURITÉ"
      :displayHeader="true"
      :open="openVideo"
      size="s"
      :keepratio="true"
      @close-modal="openVideo = false"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <video preload="metadata" id="video" controls="true">
            <source src="../../../src/assets/video/FA_A.mp4" type="video/mp4" />
            <source
              src="../../../src/assets/video/FA_A.webm"
              type="video/webm"
            />
          </video>
        </div>
      </template>
      <template slot="modal_footer">
        <div class="block p-4 sm:hidden">
          <div>
            <button
              class="button red w-11/12 mx-auto font text-center"
              @click="submitVideo"
              type="submit"
            >
              J'atteste avoir compris<br />
              et vu la vidéo dans son intégralité
              <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
            </button>
          </div>
        </div>
        <div class="p-4 hidden sm:block">
          <div>
            <button
              class="button red mx-auto"
              @click="submitVideo"
              type="submit"
            >
              <DoiText
                >J'atteste avoir compris et vu la vidéo dans son
                intégralité</DoiText
              >
              <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CartBookingList from "@/views/cart/CartBookingList";
import PaymentMeans from "@/views/reglements/PaymentMeans";

import Modal from "@/components/molecules/Modal";

import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";

import { setValidationInfo } from "@/api/ValidationService";

export default {
  name: "PaymentBookingAbstract",
  data() {
    return {
      isFetching: false,
      totalPrice: 0,
      open: false,
      openError: false,
      shareBill: true,
      openVideo: false,
      video: {},
      showSpinner: false,
      videoEnded: false,
      afficherVideoSecurite: "",
      idFitArena: "",
      validationVideo: false,
      infosVideo: {
        date: "",
        heure: "",
        videoRegardee: "",
        idFitArena: "",
      },
    };
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    isCartLoading: Boolean,
    cartList: Object,
  },
  components: {
    Modal,
    CartBookingList,
    PaymentMeans,
  },
  computed: {
    ...mapGetters("UserModule", ["get_user"]),
    ...mapGetters("ReservationModule", ["reservation"]),
    isFetched() {
      return this.$store.getters["PaymentModule/isFetched"];
    },
    paymentAlias() {
      return this.$store.getters["PaymentModule/alias"];
    },
    isPaymentValid() {
      return this.paymentAlias && this.isFetched;
    },
    isDisabled() {
      if (this.get_user.paiementActif) {
        if (this.get_user.portable && this.isPaymentValid) return false;
        else if (this.validationCodeNumber && this.isPaymentValid) return false;
        return true;
      } else {
        return (
          (this.get_user.portable === "" || this.get_user.portable === null) &&
          this.messageSuccess === ""
        );
      }
    },
  },
  methods: {
    ...mapActions("CartModule", ["validateCart"]),
    ...mapActions("UserModule", ["SET_PROFIL_INFO"]),
    async transformCartToOrder() {
      this.isFetching = true;
      if (!this.isCartLoading) {
        try {
          await this.validateCart(this.shareBill);
          this.open = true;
        } catch (error) {
          this.openError = false;
          this.isFetching = false;
        }
      }
    },
    submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) this.showSpinner = false;
      else this.setUserData();
    },
    async setUserData() {
      try {
        await this.SET_PROFIL_INFO(this.userInfoCopy);
        this.close();
      } catch (e) {
      } finally {
        this.showSpinner = false;
      }
    },
    submitVideo() {
      this.showSpinner = true;
      this.copyInfosVideo();
    },
    copyInfosVideo() {
      this.infosVideo.date = dayjs().format("YYYY-MM-DD");
      this.infosVideo.heure = dayjs().format("HH:mm:ss");
      this.infosVideo.idFitArena = this.cartList.fitArena.id;
      this.infosVideo.videoRegardee = true;
      setValidationInfo(this.infosVideo);
      this.validationVideo = true;
      this.openVideo = false;
      this.showSpinner = false;
    },
    setShare(type) {
      this.shareBill = type === "alone" ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.font {
  border-bottom: 2px solid $doi--color-color-lighter4;
  font-family: $doi--text-font-family5;
}

.bg-green {
  background-color: $doi--color-color2;
}

.bg-red {
  background-color: $doi--color-color0;
}
</style>
