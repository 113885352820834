<template>
  <div
    class="flex"
    :class="borderLeftColorStyle + bgCardColorStyle + borderBottomStyle"
    v-on="$listeners"
  >
    <div class="flex items-center">
      <slot name="left"></slot>
    </div>
    <div class="flex w-full justify-between">
      <div class="flex flex-col justify-center">
        <slot name="center"></slot>
      </div>
      <div class="flex flex-col justify-center">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import bgCardColorMixin from "@/components/mixins/bgCardColorMixin.js";
import borderColorMixin from "@/components/mixins/borderColorMixin.js";

export default {
  name: "DoiCard",
  mixins: [bgCardColorMixin, borderColorMixin],
  props: {
    borderBottom: Number,
  },
  computed: {
    borderBottomStyle() {
      let style = " ";
      switch (this.borderBottom) {
        case 0:
          return (style = " border-bottom-black ");
        case 1:
          return (style = " border-bottom-lightgray ");
      }
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-lightgray {
  background: $doi-bg-lightgrey-color;
}

.border-bottom-black {
  border-bottom: 1px solid $doi-bg-dark-color;
}

.border-bottom-lightgray {
  border-bottom: 1px solid $doi-bg-lightgrey-color;
}
</style>
