<template>
  <th
    class="border-collapse border-gray-900 border-2 text-xs md:text-basic text-center"
  >
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "DoiColumnTable",
};
</script>
