<template>
  <h1
    v-if="level === 1"
    class="header1 uppercase"
    :class="headerColorStyle + fontFamilyStyle + borderStyle"
  >
    <slot></slot>
  </h1>
  <h2
    v-else-if="level === 2"
    class="header2 uppercase"
    :class="headerColorStyle + fontFamilyStyle + borderStyle"
  >
    <slot></slot>
  </h2>
  <h3
    v-else-if="level === 3"
    class="header3"
    :class="headerColorStyle + fontFamilyStyle + borderStyle"
  >
    <slot></slot>
  </h3>
  <h4
    v-else-if="level === 4"
    class="header4"
    :class="headerColorStyle + fontFamilyStyle + borderStyle"
  >
    <slot></slot>
  </h4>
  <h5
    v-else-if="level === 5"
    class="header5"
    :class="headerColorStyle + fontFamilyStyle + borderStyle"
  >
    <slot></slot>
  </h5>
  <h6
    v-else-if="level === 6"
    class="header6"
    :class="headerColorStyle + fontFamilyStyle + borderStyle"
  >
    <slot></slot>
  </h6>
</template>

<script>
import headerColorMixin from "@/components/mixins/headerColorMixin.js";
import fontFamilyMixin from "@/components/mixins/fontFamilyMixin.js";

export default {
  name: "DoiHeader",
  mixins: [headerColorMixin, fontFamilyMixin],
  props: {
    level: {
      type: Number,
      default: 1,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    borderStyle() {
      return this.border ? " border-y " : " ";
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.header1 {
  font-size: $doi--header1;
}
.header2 {
  font-size: $doi--header2;
}
.header3 {
  font-size: $doi--header3;
}
.header4 {
  font-size: $doi--header4;
}
.header5 {
  font-size: $doi--header5;
}
.header6 {
  font-size: $doi--header6;
}
.border-y {
  border-top: 2px solid $doi--color-color0;
  border-bottom: 2px solid $doi--color-color0;
}
</style>
