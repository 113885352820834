<template>
  <div
    class="container mx-auto max-w-5xl flex justify-center p-20 flex-col items-center w-full lg:w-5/12"
  >
    <div class="flex justify-center flex-col mt-20">
      <div v-if="success" class="mx-auto">
        <span class="material-icons text-green-500 text-6xl">check_circle</span>
      </div>

      <div v-if="error" class="mx-auto">
        <span class="material-icons text-red-500 text-6xl">error</span>
      </div>

      <DoiText
        :fontSize="6"
        :textColor="1"
        v-if="showSpinner"
        class="text-center"
        >Activation en cours...</DoiText
      >
      <DoiText :fontSize="6" :textColor="1" v-if="success" class="text-center"
        >Votre compte a bien été activé, vous pouvez désormais vous
        connecter.</DoiText
      >
      <DoiText :fontSize="6" :textColor="1" v-if="error" class="text-center"
        >Une erreur est survenue lors de l'activation de votre compte, veuillez
        réessayer plus tard ou contacter un administrateur.</DoiText
      >
    </div>
    <Modal :open="open" :keepratio="true" size="xs" :displayHeader="false">
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
            >Le compte est déjà actif.</DoiText
          >
        </div>
      </template>
      <template slot="modal_footer">
        <DoiButton @click="close()" :bgHover="1" class="mx-auto w-4/12"
          >OK</DoiButton
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import { activation } from "@/api/AuthService";
import Modal from "@/components/molecules/Modal";

export default {
  name: "Validation",
  data() {
    return {
      sJeton: "",
      nIdGrandPublic: null,
      message: "",
      showSpinner: true,
      success: false,
      error: false,
      open: false,
    };
  },
  components: {
    Modal,
  },
  mounted() {
    this.sJeton = this.$route.params.sJeton;
    this.nIdGrandPublic = this.$route.params.nIdGrandPublic;
    this.vActivation();
  },
  computed: {
    getToken() {
      return this.$store.getters["AuthModule/get_token"];
    },
  },
  methods: {
    async vActivation() {
      this.showSpinner = true;
      const response = await activation(this.sJeton, this.nIdGrandPublic);

      if (response === undefined) {
        this.open = true;
      }

      if (response.bSucces) {
        this.success = true;
        if (response.szToken !== undefined && response.szToken !== "")
          this.$store.dispatch("AuthModule/SET_TOKEN", response.szToken);
        this.$router.push("/signin");
      } else {
        this.error = true;
      }
      this.showSpinner = false;
    },
    close() {
      this.open = false;
      this.$router.push("/signin");
    },
  },
  watch: {
    getToken(token) {
      if (token !== null && token !== "") {
        if (localStorage.getItem("attente_reponse") !== null) {
          const sIdReservation = localStorage.getItem("id_reservation");
          const sJeton = localStorage.getItem("jeton_invitation");

          localStorage.removeItem("attente_reponse");
          localStorage.removeItem("id_reservation");
          localStorage.removeItem("jeton_invitation");

          this.$router.push(`/invitation/${sIdReservation}/${sJeton}`);
        } else {
          this.$router.push("/signin");
        }
      }
    },
  },
};
</script>
