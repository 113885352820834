<template>
  <div class="card">
    <div class="no-flip">
      <div class="front">
        <div
          class="chip h-10 overflow-hidden rounded relative flex items-center justify-center"
        >
          <span class="chip-line" />
          <span class="chip-line" />
          <span class="chip-line" />
          <span class="chip-line" />
          <span class="chip-main rounded w-5" />
        </div>

        <svg
          class="wave relative"
          viewBox="0 3.71 26.959 38.787"
          width="26.959"
          height="38.787"
          fill="white"
        >
          <path
            d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"
          ></path>
          <path
            d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"
          ></path>
          <path
            d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"
          ></path>
        </svg>

        <div class="card-number relative flex justify-between items-center">
          <span class="section">{{ sPanFormatedArray[0] }}</span>
          <span class="section">{{ sPanFormatedArray[1] }}</span>
          <span class="section">{{ sPanFormatedArray[2] }}</span>
          <span class="section">{{ sPanFormatedArray[3] }}</span>
        </div>

        <div class="end uppercase ml-6">
          <span class="end-text">exp. end:</span>
          <span class="end-date"
            >{{ card.nMoisExpiration }}/{{ card.nAnneeExpiration }}</span
          >
        </div>
      </div>
      <div class="back">
        <div class="strip-black absolute left-0 w-full bg-black" />
        <div
          class="ccv absolute left-0 right-0 p-2.5 mx-auto my-0 rounded text-right text-black bg-white"
        >
          <label class="block uppercase text-white">ccv</label>
          <div>123</div>
        </div>
        <div class="terms absolute p-5 text-justify">
          <p>Use of this card is subject to the credit card agreement.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCard",
  props: {
    card: Object,
  },
  computed: {
    sPanFormatedArray() {
      return this.card.sPan.match(/.{4}/g);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.card {
  width: 300px;
  height: 188px;
}

.no-flip {
  width: inherit;
  height: inherit;
}

.front,
.back {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #111, #555);
  overflow: hidden;
}
.front {
  transform: translateZ(0);
}

.chip {
  top: 30px;
  left: 17.5px;
  width: 50px;
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
}

.chip .chip-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #333;
}
.chip .chip-line:nth-child(1) {
  top: 13px;
}
.chip .chip-line:nth-child(2) {
  top: 20px;
}
.chip .chip-line:nth-child(3) {
  top: 28px;
}
.chip .chip-line:nth-child(4) {
  left: 25px;
  width: 1px;
  height: 50px;
}
.chip .chip-main {
  height: 25px;
  border: 1px solid #333;
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;
}
.wave {
  top: -10px;
  left: 80px;
}

.card-number {
  margin: 20px 20px 10px;
  font-size: 17px;
  font-family: "cc font", monospace;
}

.end {
  font-family: "cc font", monospace;
}

.end .end-text {
  font-size: 9px;
  color: rgba(255, 255, 255, 0.8);
}

.card:hover .flip {
  transform: rotateY(180deg);
}

.back {
  transform: rotateY(180deg) translateZ(0);
  background: #9e9e9e;
}
.back .strip-black {
  top: 30px;
  height: 50px;
}
.back .ccv {
  top: 110px;
  height: 36px;
  width: 90%;
  letter-spacing: 1px;
}
.back .ccv label {
  margin: -30px 0 15px;
  font-size: 10px;
}
.back .terms {
  top: 150px;
  font-size: 10px;
}

@media (min-width: $doi-breakpoint-desktop) {
  .card-number {
    margin: 40px 25px 10px;
    font-size: 23px;
  }

  .end .end-text {
    font-size: 7px;
  }

  .chip {
    top: 60px;
    left: 25px;
  }

  .card {
    width: 400px;
    height: 250px;
  }

  .wave {
    top: 20px;
    left: 100px;
  }
}
</style>
