<template>
  <div
    class="flex flex-col lg:flex-row justify-center lg:justify-start items-center border-b-2 py-5"
  >
    <div class="w-full lg:w-6/12 text-center lg:text-left">
      <DoiText v-if="participant.pseudo" :textColor="3" :fontSize="4">{{
        participant.pseudo
      }}</DoiText>
      <DoiText v-else :textColor="3" :fontSize="4"
        >{{ participant.prenom }} {{ participant.nom
        }}<DoiText
          type="span"
          :textColor="0"
          :fontFamily="4"
          :fontSize="4"
          class="ml-5"
          >{{ participant.identifiant }}</DoiText
        ></DoiText
      >
    </div>
    <div
      class="w-full flex flex-col lg:flex-row justify-between p-4 lg:p-0"
      :class="isOrganizer ? 'lg:w-2/12' : 'lg:w-6/12'"
    >
      <!-- la réservation n'est pas complète -->
      <div
        v-if="!isFull"
        @click.prevent="emitUpdate(2)"
        class="radio valide flex items-center justify-center cursor-pointer rounded-sm mb-3 lg:mb-0 w-full"
      >
        <input
          type="checkbox"
          :id="`reponse_valide_${participant.id}`"
          true-value="2"
          v-model="response"
        />
        <label
          :for="`reponse_valide_${participant.id}`"
          class="flex justify-center font-span w-full transition duration-300 hover:shadow-md text-fa-dark-gray"
        >
          <span class="flex items-center mr-2 green"
            ><i class="material-icons">check</i></span
          >
          ACCEPTER
        </label>
      </div>
      <!-- la réservation est complète -->
      <div
        v-if="isFull && response == 2"
        class="radio valide flex items-center justify-center cursor-pointer rounded-sm mb-3 lg:mb-0 w-full"
      >
        <input type="checkbox" true-value="2" v-model="response" />
        <label
          :for="`reponse_valide_${participant.id}`"
          class="flex justify-center font-span w-full transition duration-300 hover:shadow-md text-fa-dark-gray"
        >
          <span class="flex items-center mr-2 green"
            ><i class="material-icons">check</i></span
          >
          ACCEPTER
        </label>
      </div>
      <div
        v-if="isFull && response !== 2"
        class="radio valide disabled flex items-center justify-center rounded-sm mb-3 lg:mb-0 w-full"
      >
        <input type="checkbox" readonly />
        <label class="flex justify-center font-span w-full text-fa-dark-gray">
          <span class="flex items-center mr-2 green"
            ><i class="material-icons">check</i></span
          >
          COMPLET
        </label>
      </div>

      <div
        v-if="!isOrganizer"
        @click.prevent="emitUpdate(1)"
        class="radio refuse flex items-center justify-center cursor-pointer rounded-sm mb-3 lg:mx-3 lg:mb-0 w-full"
      >
        <input
          type="checkbox"
          :id="`reponse_refuse_${participant.id}`"
          true-value="1"
          v-model="response"
        />
        <label
          :for="`reponse_refuse_${participant.id}`"
          class="flex justify-center font-span transition duration-300 w-full hover:shadow-md text-fa-dark-gray"
        >
          <span class="flex items-center mr-2 text-fa-red"
            ><i class="material-icons">close</i></span
          >
          REFUSER
        </label>
      </div>

      <div
        v-if="!isOrganizer"
        @click.prevent="emitUpdate(0)"
        class="radio peut_etre flex items-center justify-center cursor-pointer rounded-sm mb-3 lg:mb-0 w-full"
      >
        <input
          type="checkbox"
          :id="`reponse_peut_etre_${participant.id}`"
          true-value="0"
          v-model="response"
        />
        <label
          :for="`reponse_peut_etre_${participant.id}`"
          class="flex justify-center font-span transition duration-300 w-full hover:shadow-md text-fa-dark-gray"
        >
          <span class="flex items-center mr-2"
            ><i class="material-icons">help_outline</i></span
          >
          PEUT-ÊTRE
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingResponseContactListItem",
  data() {
    return {
      enable: false,
    };
  },
  props: {
    participant: Object,
    response: Number,
    isFull: Boolean,
  },
  computed: {
    isOrganizer() {
      return (
        this.participant.hasOwnProperty("organisateur") &&
        this.participant.organisateur
      );
    },
  },
  methods: {
    emitUpdate(response) {
      this.$emit("update", {
        id: this.participant.id,
        response,
        participant: this.participant,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.font-span {
  font-family: $doi--text-font-family6;
}
.green {
  color: $doi--color-color2;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio {
  position: relative;
}
.radio label {
  padding: 6px;
  display: flex;
}
.radio:hover input ~ label {
  cursor: pointer;
}
.radio input:checked ~ label {
  background-color: #fff;
  color: #fff;
}
.radio input:checked ~ label span {
  color: #fff;
}
.radio.valide input ~ label {
  border: solid 2px #66bb6a;
}
.radio.valide input:checked ~ label {
  background: #66bb6a;
}
.radio.valide.disabled input ~ label {
  background: lightgray;
  border: solid 2px $doi--color-color4;
}
.radio.refuse input ~ label {
  border: solid 2px $doi--color-color0;
}
.radio.refuse input:checked ~ label {
  background: $doi--color-color0;
}
.radio.peut_etre input ~ label {
  border: solid 2px $doi--color-color4;
}
.radio.peut_etre input:checked ~ label {
  background: $doi--color-color4;
}
.radio label:after {
  display: none;
}
</style>
