<template>
  <tr class="text-xs border border-gray-300">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "DoiTableRow",
};
</script>
