<template>
  <div class="doi-switch text-center -ml-5">
    <input
      class="doi-switch-checkbox"
      :checked="checked"
      @change="onChange"
      type="checkbox"
      :id="idInput"
    />
    <label class="doi-switch-label" :for="idInput">
      <span class="doi-switch-span"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "DoiSwitch",
  props: {
    id: {
      type: String,
      default: "0",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    idInput() {
      return "doi-switch-" + this.id;
    },
  },
  methods: {
    onChange(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.doi-switch {
  border-radius: 5px;
  color: "#fff";
  background: "#18172c";
  text-align: center;
}

.doi-switch-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: auto;
  cursor: pointer;
}

.doi-switch-checkbox {
  position: absolute;
  left: -9999px;
}

.doi-switch-span {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background: "#434257";
  transition: all 0.3s;
}

.doi-switch-span:last-child::before,
.doi-switch-span:last-child::after {
  content: "";
  position: absolute;
}
.doi-switch-span:last-child::before {
  left: 1px;
  top: 1px;
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 4px;
  z-index: 1;
  transition: transform 0.3s;
}
.doi-switch-span:last-child::after {
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  background: url(../../assets/icons/common/uncheck-mark.svg);
  background-size: 12px 12px;
}
.doi-switch-span:last-child {
  background-color: $doi--switch-color0;
}

.doi-switch-checkbox:checked + .doi-switch-label .doi-switch-span:last-child {
  background: $doi--switch-color1;
}
.doi-switch-checkbox:checked
  + .doi-switch-label
  .doi-switch-span:last-child::before {
  transform: translateX(30px);
}
.doi-switch-checkbox:checked
  + .doi-switch-label
  .doi-switch-span:last-child::after {
  width: 14px;
  height: 14px;
  left: 8px;
  background-image: url(../../assets/icons/common/check-mark.svg);
  background-size: 14px 14px;
}
</style>
