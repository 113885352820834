<template>
  <label
    >{{ label }}
    <input
      class="doi-input"
      :class="errorClass + hideClass"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      @input="onInput"
      @change="onChange"
    />
  </label>
</template>

<script>
export default {
  name: "DoiInput",
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "text",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "Enter Text",
    },
    value: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorClass() {
      return this.error ? "doi-input-error" : "";
    },
    hideClass() {
      return this.hide ? " doi-input-hidden" : "";
    },
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
    onChange(event) {
      this.type === "file"
        ? this.$emit("change", event)
        : this.$emit("change", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.doi-input {
  width: 100%;
  padding: 10px 7px;
  border-bottom: solid 0.13rem rgb(225, 225, 225);
  background-color: transparent;
}
.doi-input::placeholder {
  color: $doi--color-color4;
  font-family: $doi--text-font-family6;
  font-size: 0.9rem;
}
.doi-input:focus {
  outline: none;
}

.doi-input-error {
  border-bottom: solid 1px $doi--color-color0;
}

.doi-input-hidden {
  display: none !important;
}
</style>
