<template>
  <div>
    <div v-if="displaySubHeader" class="bg-fa-light-gray py-5">
      <div
        class="container mx-auto max-w-5xl p-5 flex flex-col text-center justify-between tracking-wider items-center lg:flex-row 4k:max-w-7xl"
      >
        <div class="mb-5 lg:mb-0">
          <DoiText :textColor="3" :fontSize="4" class="2k:text-2xl 4k:text-4xl"
            >VOUS ÊTES UNE COLLECTIVITÉ <br />
            ET VOUS SOUHAITEZ VOTRE FIT ARENA ?</DoiText
          >
        </div>
        <div class="mx-auto">
          <button class="button">
            <a
              href="https://www.smc2-construction.com/sports-jeux/salles-multisports-connectees/"
              target="_blank"
              >EN SAVOIR PLUS</a
            >
          </button>
        </div>
      </div>
    </div>
    <div class="bg-fa-dark-gray py-10">
      <div
        class="container mx-auto max-w-5xl p-5 flex-col lg:flex lg:flex-row 4k:max-w-7xl"
      >
        <div class="flex flex-col flex-1">
          <div class="flex justify-center">
            <img src="@/assets/img/logo-footer.png" alt="Fit Arena" />
          </div>
          <div v-if="!is_connected" class="flex justify-between mt-5">
            <div class="mr-4">
              <DoiButton link="/signup" :textColor="3" :bgColor="4"
                >INSCRIPTION</DoiButton
              >
            </div>
            <div>
              <DoiButton link="/signin">CONNEXION</DoiButton>
            </div>
          </div>
          <div v-else class="flex justify-center lg:justify-start mt-5">
            <div class="flex">
              <DoiButton @click="disconnect" :bgColor="4" :textColor="3"
                >DÉCONNEXION</DoiButton
              >
            </div>
          </div>
        </div>
        <div
          v-if="!isCordova()"
          class="flex text-white flex-1 justify-center px-8 mt-10 lg:mt-0"
        >
          <ul class="text-center lg:text-left">
            <li class="footer-link 4k:text-2xl">
              <router-link to="/mon-parcours-dans-la-salle"
                >Mon parcours dans la salle</router-link
              >
            </li>
            <li class="footer-link 4k:text-2xl">
              <router-link to="/faq">FAQ</router-link>
            </li>
            <a
              v-if="!isFlutter()"
              href="https://play.google.com/store/apps/details?id=fr.smc2.fitarena&gl=FR"
              target="_blank"
              ><img
                class="border border-white rounded mb-2 mx-auto sm:mx-0"
                src="@/assets/img/play-store.png"
                alt="Téléchargement de l'application Fit Arena sur le Play Store"
            /></a>
            <a
              v-if="!isFlutter()"
              href="https://apps.apple.com/fr/app/fit-arena/id1609216600"
              target="_blank"
              ><img
                class="border border-white rounded mx-auto sm:mx-0"
                src="@/assets/img/app-store.png"
                alt="Téléchargement de l'application Fit Arena sur l'App Store"
            /></a>
          </ul>
        </div>

        <div
          class="divide-y-2 divide-black divide-opacity-25 divide-solid mt-10"
        >
          <div></div>
          <div></div>
        </div>

        <div
          class="flex flex-col text-white flex-1 items-center text-center mt-10 lg:mt-0 lg:text-left lg:items-start"
        >
          <ul>
            <li class="footer-link 4k:text-2xl">
              <router-link to="/rgpd">RGPD</router-link>
            </li>
            <li class="footer-link 4k:text-2xl">
              <router-link to="/mentions-legales">Mentions légales</router-link>
            </li>
            <li class="footer-link 4k:text-2xl">
              <router-link to="/politique-de-confidentialite"
                >Politique de confidentialité</router-link
              >
            </li>
          </ul>
          <div class="flex justify-between w-36">
            <div>
              <a
                target="_blank"
                rel="noopener"
                href="https://www.facebook.com/Fit-Arena-135920413695770/"
              >
                <img
                  width="30"
                  class="4k:w-10 4k:mr-20"
                  src="@/assets/img/share/facebook.png"
                  alt="facebook fit arena"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener"
                href="https://twitter.com/_FitArena_"
              >
                <img
                  width="30"
                  class="4k:w-10 4k:mr-20"
                  src="@/assets/img/share/twitter.png"
                  alt="twitter fit arena"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener"
                href="https://instagram.com/fitarena_officiel"
              >
                <img
                  width="30"
                  class="4k:w-10 4k:mr-20"
                  src="@/assets/img/share/instagram.png"
                  alt="instagram fit arena"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com/channel/UCBnso9VI6kJkVNBpQ80TwEQ"
              >
                <img
                  width="30"
                  class="4k:w-10 4k:mr-20"
                  src="@/assets/img/share/youtube.png"
                  alt="youtube fit arena"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  props: {
    displaySubHeader: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    isCordova() {
      return window.navigator.userAgent.includes("cordova");
    },
    disconnect() {
      this.$store.dispatch("AuthModule/DECONNEXION");
    },
    isFlutter() {
      return document.cookie.includes("isFlutterApp");
    },
  },
  computed: {
    ...mapGetters("AuthModule", ["is_connected"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.button,
.button:hover {
  color: $doi--text-color3;
  background-color: $doi--button-color4;
  font-size: $doi--text-font-size3;
  font-family: $doi--text-font-family2;
  letter-spacing: 0.05em;
}

.footer-link {
  margin: 0px 0px 20px 0px;
  display: block;
}
</style>
