import Network from "@/utils/Network";

const url1 = process.env.VUE_APP_API_URL;
const url2 = process.env.VUE_APP_API_URL_V2;
const url3 = process.env.VUE_APP_API_URL_V2_AUTH;

const login = (payload) => {
  return Network.execute({
    secure: false,
    method: "POST",
    url: url1 + "Connexion",
    payload: payload,
  });
};

const login2 = (payload) => {
  return Network.execute({
    secure: false,
    method: "POST",
    url: url3 + "auth",
    payload: payload,
  });
};

const loginSocialNetwork = (reseau) => {
  return Network.execute({
    secure: false,
    method: "POST",
    url: url1 + "reseau-sociaux/connexion",
    payload: {
      reseau: reseau,
    },
  });
};

const register = (payload) => {
  return Network.execute({
    method: "POST",
    url: url1 + "grand-public/inscription",
    payload,
    secure: false,
  });
};

const registerV2 = (payload) => {
  return Network.execute({
    method: "POST",
    url: url2 + "users",
    payload,
    secure: false,
  });
};

const activation = (sJeton, nIdGrandPublic) => {
  return Network.execute({
    method: "POST",
    url:
      url1 + `grand-public/validation/inscription/${sJeton}/${nIdGrandPublic}`,
    secure: false,
  });
};

const accountConfirmation = (uuidUser, uuidConfirmation) => {
  return Network.execute({
    method: "POST",
    url: url2 + `user/confirmation`,
    payload: {
      uuidUser: uuidUser,
      uuidConfirmation: uuidConfirmation,
    },
    secure: false,
  });
};

const sendEmailReinit = (email, host) => {
  return Network.execute({
    method: "POST",
    url: url3 + "forgot_password/",
    payload: {
      email: email,
      host: host,
    },
    secure: false,
  });
};

const reinitPassword = (password, token) => {
  return Network.execute({
    method: "POST",
    url: url3 + `forgot_password/${token}`,
    payload: {
      password: password,
    },
    secure: false,
  });
};

const verifyToken = (token) => {
  return Network.execute({
    method: "GET",
    url: url3 + `forgot_password/${token}`,
    secure: false,
  });
};

export {
  login,
  login2,
  loginSocialNetwork,
  register,
  registerV2,
  accountConfirmation,
  activation,
  sendEmailReinit,
  reinitPassword,
  verifyToken,
};
