import { getHistoryPaymentV2 } from "@/api/PaymentService";
import Vue from "vue";

const initState = () => {
  return {
    isFetched: false,
    data: {
      historyList: [],
    },
  };
};

export default {
  name: "paymentHistoryList",
  namespaced: true,
  state: initState(),
  actions: {
    async retrieveHistoryList({ commit }) {
      try {
        commit("setFetchedDirty");
        const response = await getHistoryPaymentV2();
        if (response) commit("setHistoryList", response);
        else commit("setFetchedOnError");
      } catch (err) {
        commit("setFetchedOnError");
      }
    },
  },
  mutations: {
    setHistoryList(state, payload) {
      Vue.set(state.data, "historyList", [...payload]);
      state.data.historyList.splice(state.data.historyList.length);
      state.isFetched = true;
    },
    resetStore: (state) => {
      Object.assign(state, initState());
    },
    setFetchedOnError: (state) => {
      state.isFetched = true;
    },
    setFetchedDirty: (state) => {
      state.isFetched = false;
    },
  },
  getters: {
    historyList: (state) => {
      return state.data.historyList;
    },
    isFetched: (state) => {
      return state.isFetched;
    },
  },
};
