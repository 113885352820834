<template>
  <div class="min-h-screen flex flex-col flex-1 h-full">
    <div v-if="!isLoading">
      <div class="title-long-border"></div>
      <div class="container mx-auto max-w-7xl flex justify-center">
        <div
          v-if="reservation.reservation.statut === 'validate'"
          class="items-center flex justify-start w-full h-full flex-col"
        >
          <div class="mb-8">
            <DoiHeader
              :headerColor="0"
              :fontFamily="1"
              class="mx-auto text-center"
              id="title"
              >À vous de jouer !</DoiHeader
            >
          </div>
          <div class="bg-gray-100 w-full flex flex-col items-center mb-10 pt-4">
            <div class="w-24 h-24">
              <img
                :src="
                  require(`@/assets/icons/utils/sports/${sportIcon(
                    reservation.activite.libelle
                  )}`)
                "
              />
            </div>
            <div class="flex mb-4 p-2 justify-center text-center flex-col">
              <DoiText :textColor="3" class="mb-4" :fontSize="5"
                >{{
                  reservation.reservation.nbPlaceMax - isFullAcceptedResponse
                }}
                place(s) restante(s)</DoiText
              >
              <DoiText
                v-if="!isOrganizer"
                :textColor="3"
                :fontFamily="4"
                :fontSize="3"
                ><DoiText type="span" :textColor="3" :fontSize="3">{{
                  organisateur.pseudo
                }}</DoiText>
                vous invite à une partie de {{ reservation.activite.libelle }},
                le {{ reservationDate }}<br />à
                {{ reservation.fitArena.libelle }}.</DoiText
              >
              <DoiText v-else :textColor="3" :fontFamily="4" :fontSize="3"
                >Vous êtes l'organisateur de la partie de
                {{ reservation.activite.libelle }}, le {{ reservationDate
                }}<br />à {{ reservation.fitArena.libelle }}.</DoiText
              >
              <DoiText
                v-if="!isFull"
                :textColor="3"
                class="mt-4"
                :fontSize="4"
                :fontFamily="2"
                >Participerez-vous à cette session ?</DoiText
              >
              <DoiText v-if="isFull" :textColor="3" class="mt-4" :fontSize="3"
                >Cette réservation est complète.</DoiText
              >
              <DoiText
                v-if="isFull"
                :textColor="3"
                :fontSize="3"
                :fontFamily="4"
                >Veuillez contacter l'organisateur pour qu'il vous prévienne en
                cas de désistement.</DoiText
              >
            </div>
          </div>
          <div class="relative flex-col w-full lg:mb-0">
            <div v-if="checkIsLoggedIn">
              <div>
                <div
                  v-for="participant in participants"
                  :key="`participant_${participant.id}`"
                  class="mb-5"
                >
                  <BookingResponseContactListItem
                    @update="updateResponse"
                    :participant="participant"
                    :response="participant.reponse"
                    :isFull="isFull"
                  />
                </div>
                <div
                  class="flex items-center bg-gray-100 px-5 flex-col text-center lg:flex-row"
                  v-if="contactList !== undefined && contactList.length >= 1"
                >
                  <DoiSwitch v-model="openFamilyBlock" />
                  <DoiText :textColor="3" :fontFamily="2"
                    >AJOUTER DES MEMBRES DE MA FAMILLE NE POSSÉDANT PAS DE
                    COMPTE OU DE SMARTPHONE</DoiText
                  >
                </div>
                <div v-show="openFamilyBlock">
                  <div
                    v-for="participant in contactList"
                    :key="`contact_${participant.id}`"
                  >
                    <BookingResponseContactListItem
                      @update="updateResponseFamily"
                      :response="participant.reponse"
                      :participant="participant"
                      :isFull="isFull"
                    />
                  </div>
                </div>
              </div>
              <!-- conditions si un des participants accepte, hésite ou refuse -->
              <div v-if="!isPortableCheck" class="mb-4 text-center">
                <DoiHeader class="my-6" :level="4"
                  >AJOUTER MON TÉLÉPHONE</DoiHeader
                >
                <div
                  class="flex-col flex items-center mx-auto w-full lg:max-w-5xl lg:w-3/12"
                >
                  <input
                    type="tel"
                    minlength="10"
                    maxlength="10"
                    pattern="[0-9]"
                    required
                    v-model="$v.userInfo.numero.$model"
                    :error="$v.userInfo.numero.$error"
                    placeholder="Téléphone portable"
                    class="border border-gray-400 rounded-lg py-4 pl-14 ml-4 focus:outline-none"
                  />
                  <DoiText
                    italic
                    :textColor="0"
                    :fontSize="1"
                    v-if="
                      !$v.userInfo.numero.required && $v.userInfo.numero.$error
                    "
                    >Ce champ est requis</DoiText
                  >
                </div>
                <div class="mt-2 mx-auto w-1/2 lg:max-w-5xl lg:w-3/12">
                  <DoiButton @click="validationPhoneNumber"
                    >VÉRIFIER MON TÉLÉPHONE</DoiButton
                  >
                </div>
              </div>
              <!-- conditions si un des participants accepte ET que le paiement est requis -->
              <div v-if="paiementSection" class="mt-10">
                <div class="flex flex-1 justify-center mb-4">
                  <span class="hidden sm:flex flex-1" />
                  <DoiHeader
                    class="flex flex-1 justify-center items-center text-center mx-auto"
                    :level="4"
                    :headerColor="1"
                    :fontFamily="0"
                    >PAIEMENT DE LA SÉANCE</DoiHeader
                  >
                  <span class="hidden sm:flex flex-1 items-center"
                    ><DoiLine
                  /></span>
                </div>
                <PaymentBookingAbstract
                  :readOnly="true"
                  :isCartLoading="isLoading"
                  :cartList="reservation"
                  :isOrganizer="isOrganizer"
                />
              </div>

              <div v-if="discountIsAvailable">
                <div class="flex flex-1 mt-8 items-center">
                  <div class="red-line"></div>
                  <DoiText
                    :fontSize="5"
                    :textColor="1"
                    :fontFamily="0"
                    class="uppercase ml-2"
                    >MES RÉDUCTIONS
                  </DoiText>
                </div>

                <div v-if="discountsTemp.length > 0">
                  <div v-for="item in discountsTemp" :key="`reduc_${item.id}`">
                    <div
                      class="w-3/12 flex items-center gap-12 p-4 border-b border-gray-300"
                    >
                      <img
                        @click.stop.prevent="deleteDiscount(item.id)"
                        class="cursor-pointer"
                        src="@/assets/img/trash.png"
                      />
                      <div class="flex items-center gap-20">
                        <div>
                          <DoiText
                            v-if="item.label"
                            :textColor="1"
                            :fontFamily="6"
                            :fontSize="3"
                            >{{ item.label }}</DoiText
                          >
                          <DoiText
                            v-if="item.codeReduction"
                            :textColor="1"
                            :fontFamily="6"
                            :fontSize="3"
                            >{{ item.codeReduction }}</DoiText
                          >
                          <DoiText
                            v-if="item.hasOwnProperty('cumulative')"
                            :textColor="1"
                            :fontFamily="4"
                            :fontSize="3"
                            >{{
                              item.cumulative ? "Cumulative" : "Non cumulative"
                            }}</DoiText
                          >
                          <DoiText
                            v-if="item.hasOwnProperty('codeCumulable')"
                            :textColor="1"
                            :fontFamily="4"
                            :fontSize="3"
                            >{{
                              item.codeCumulable ? "Cumulatif" : "Non cumulatif"
                            }}</DoiText
                          >
                        </div>
                        <div v-if="item.couponId">
                          <DoiText
                            v-if="item.valeur > 0"
                            :textColor="1"
                            :fontFamily="6"
                            :fontSize="3"
                            >{{ item.valeur / 100 }} €</DoiText
                          >
                          <DoiText
                            v-else
                            :textColor="1"
                            :fontFamily="6"
                            :fontSize="3"
                            >{{ item.valeurPourcent }} %</DoiText
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col space-y-6 items-center sm:flex-row sm:gap-4 sm:space-y-0 sm:justify-start mt-6"
                >
                  <DoiButton
                    @click="openDiscount()"
                    class="w-min"
                    :fontFamily="6"
                    :bgColor="2"
                    >AJOUTER UNE RÉDUCTION</DoiButton
                  >
                  <DoiButton
                    @click="openDiscountCode = true"
                    class="w-min"
                    :fontFamily="6"
                    :bgColor="2"
                    >AJOUTER UN CODE DE RÉDUCTION</DoiButton
                  >
                </div>
              </div>

              <DiscountCodeModal
                :discountCode="discountCode"
                @closeModal="openDiscountCode = false"
                :openDiscountCode="openDiscountCode"
                @validateDiscountCode="validateDiscountCode"
              />
              <DiscountModal
                :openUserDiscount="openUserDiscount"
                :itemsDiscounts="itemsDiscounts"
                @closeModal="openUserDiscount = false"
                @validateDiscount="validateDiscount"
                @updateDiscount="updateDiscount"
              />

              <div
                v-if="messageSuccess"
                class="text-center mx-auto flex justify-center my-4"
              >
                <span class="material-icons check mr-2">done</span>
                <DoiText :textColor="3" :fontSize="3" class="">{{
                  messageSuccess
                }}</DoiText>
              </div>
              <DoiText
                :textColor="3"
                :fontSize="3"
                class="text-center mx-auto my-4"
                >{{ messageError }}</DoiText
              >

              <div class="border-none flex justify-center my-4">
                <!-- conditions pour savoir si la validation de l'invitation est possible -->
                <DoiButton
                  id="sendInvitationResponse"
                  :disabled="!isValidatedResponse || submitSpinner"
                  @click="submitInvitation()"
                  :bgHover="1"
                >
                  ENVOYER LA RÉPONSE
                  <DoiSpinner :show="submitSpinner" :color="1" class="ml-1" />
                </DoiButton>
              </div>
            </div>
            <div v-else>
              <BookingResponseContactListItem
                @update="updateResponseDisconnect"
                :participant="participantDisconnect"
                :response="participantDisconnect.reponse"
              />
              <div class="mt-3 flex justify-center flex-col p-4 lg:p-0">
                <DoiText
                  class="text-center"
                  :fontFamily="6"
                  :textColor="3"
                  :fontSize="4"
                  >Un dernier clic avant le grand jeu ! <br />Connectez-vous ou
                  inscrivez-vous pour pouvoir répondre à cette
                  invitation.</DoiText
                >
                <div
                  class="flex justify-center items-center w-full flex-col lg:flex-row lg:w-6/12 mx-auto"
                >
                  <div class="lg:mr-4 mt-4">
                    <DoiButton @click="goTo('connexion')">CONNEXION</DoiButton>
                  </div>
                  <div class="lg:ml-4 mt-4">
                    <DoiButton @click="goTo('inscription')" :bgColor="2"
                      >INSCRIPTION</DoiButton
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="items-center flex justify-start w-full h-full flex-col my-12"
        >
          <div
            v-if="
              reservation.statut === 'finished' ||
              reservation.statut === 'completed' ||
              reservation.statut === 'closed'
            "
          >
            <DoiHeader
              :level="2"
              :fontFamily="1"
              :headerColor="0"
              class="text-center reservation"
              >Réservation expirée</DoiHeader
            >
            <DoiText
              :textColor="1"
              :fontFamily="4"
              :fontSize="4"
              class="mx-auto text-center w-10/12 my-6"
              >La réservation est terminée.</DoiText
            >
          </div>
          <div v-else-if="reservation.statut === 'on_going'">
            <DoiHeader
              :level="2"
              :fontFamily="1"
              :headerColor="0"
              class="text-center reservation"
              >Réservation en cours</DoiHeader
            >
            <DoiText
              :textColor="1"
              :fontFamily="4"
              :fontSize="4"
              class="mx-auto text-center w-10/12 my-6"
              >La réservation est actuellement en cours.</DoiText
            >
          </div>
          <div v-else-if="reservation.statut === 'cancelled'">
            <DoiHeader
              :level="2"
              :fontFamily="1"
              :headerColor="0"
              class="text-center reservation"
              >Réservation supprimée</DoiHeader
            >
            <DoiText
              :textColor="1"
              :fontFamily="4"
              :fontSize="4"
              class="mx-auto text-center w-10/12 my-6"
              >La réservation a été supprimée par l'organisateur.</DoiText
            >
          </div>
          <div v-else>
            <DoiHeader
              :level="2"
              :fontFamily="1"
              :headerColor="0"
              class="text-center reservation"
              >Réservation inexistante</DoiHeader
            >
            <DoiText
              :textColor="1"
              :fontFamily="4"
              :fontSize="4"
              class="mx-auto text-center w-10/12 my-6"
              >La réservation est introuvable.</DoiText
            >
          </div>
          <div
            class="items-center flex justify-start w-full h-full flex-col mt-4"
          >
            <DoiButton link="/" :fontSize="3">Accueil</DoiButton>
          </div>
        </div>
      </div>
      <div class="long-border-right" />
    </div>
    <span v-else class="flex flex-col flex-1 items-center justify-center">
      <DoiSpinner :spinnerSize="1" />
    </span>
    <Modal
      title="VALIDATION TÉLÉPHONE"
      :open="openValidationNumber"
      :displayHeader="true"
      size="xs"
      :keepratio="true"
      @close-modal="openValidationNumber = false"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="3" :fontSize="3" :fontFamily="5"
            >Vous allez recevoir un sms contenant un code, veuillez l’insérer
            ci-dessous</DoiText
          >
          <div class="flex-col items-center w-full mt-4 lg:max-w-5xl lg:w-3/12">
            <input
              type="tel"
              pattern="[0-9]"
              required
              v-model="$v.codeInfo.code.$model"
              :error="$v.codeInfo.code.$error"
              placeholder="Code de vérification"
              class="border border-gray-400 rounded-lg py-4 pl-14 ml-4 focus:outline-none"
            />
            <DoiText
              v-if="!$v.codeInfo.code.required && $v.codeInfo.code.$error"
              italic
              :textColor="0"
              :fontSize="1"
              >Ce champ est requis</DoiText
            >
          </div>
        </div>
      </template>
      <template slot="modal_footer">
        <div class="p-4">
          <DoiButton @click="validationCode" :bgHover="1"
            >VALIDER MON CODE</DoiButton
          >
        </div>
      </template>
    </Modal>
    <DoiText
      v-if="reservation.statut == 'finalisee'"
      :textColor="1"
      :fontFamily="4"
      :fontSize="3"
      class="mx-auto text-center w-8/12"
      >Dans les 15 minutes précédant la séance, vous ne pouvez plus modifier
      votre réponse.</DoiText
    >
  </div>
</template>

<script>
import BookingResponseContactListItem from "@/views/booking/BookingResponseContactListItem";
import PaymentBookingAbstract from "@/views/reglements/PaymentBookingAbstract";

import Modal from "@/components/molecules/Modal";
import DiscountModal from "@/components/molecules/DiscountModal.vue";
import DiscountCodeModal from "@/components/molecules/DiscountCodeModal.vue";

import sports from "@/data/sports.json";

import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";

import {
  setPhoneNumberV2,
  setValidationPhoneNumberV2,
  verifyDiscountCode,
} from "@/api/ValidationService";
import { getUserDiscount } from "@/api/UserService";

export default {
  name: "BookingResponse",
  data() {
    return {
      paiementSection: false,
      allParticipants: [],
      discountIsAvailable: false,
      openUserDiscount: false,
      openDiscountCode: false,
      discountCode: "",
      itemsDiscounts: [],
      discountsTemp: [],
      discounts: [],
      discount: "",
      response: "",
      uuidInvitation: "",
      uuidReservation: "",
      contactInvitation: [],
      nIdGrandPublic: "",
      openFamilyBlock: false,
      onError: false,
      errorStatus: null,
      participantDisconnect: {
        id: null,
        pseudo: "Moi",
        reponse: null,
      },
      submitSpinner: false,
      participantReponse: "",
      orgaReponse: 2,
      openValidationNumber: false,
      validationCodeNumber: false,
      messageSuccess: "",
      messageError: "",
      numero: "",
      userInfo: {
        sNom: "",
        sPrenom: "",
        sEmail: "",
        numero: "",
      },
      codeInfo: {
        code: "",
      },
      inputPortable: "",
    };
  },
  components: {
    BookingResponseContactListItem,
    PaymentBookingAbstract,
    Modal,
    DiscountModal,
    DiscountCodeModal,
  },
  validations: {
    userInfo: {
      sNom: { required },
      sPrenom: { required },
      sEmail: { required },
      numero: { required },
    },
    codeInfo: {
      code: { required },
    },
  },
  created() {
    this.copyUser();
  },
  async mounted() {
    this.uuidReservation = this.$route.params.sIdReservation;
    this.uuidInvitation = this.$route.params.sJetonInvitation;
    this.cleanReservation();
    this.getBooking(this.uuidReservation, this.uuidInvitation);

    if (this.$route.params.sReponse !== undefined)
      this.response = this.$route.params.sReponse;

    if (!this.checkIsLoggedIn) {
      this.setInvitation({
        id_reservation: this.uuidReservation,
        jeton_invitation: this.uuidInvitation,
      });
      const message =
        "Connectez-vous ou inscrivez-vous pour accéder à l'invitation";
      if (screen.width > 640) {
        this.$toast.success(message);
      } else {
        this.$toast.success(message, {
          position: "top",
        });
      }
      this.$router.push("/signin");
    } else {
      this.getFamilyContacts();
    }

    setTimeout(() => {
      this.discountIsAvailable = this.reservation.reservation.paiementRequis;
      const response = this.participants.find(
        (participant) => participant.id === this.getUserId
      ).reponse;
      if (response === 2 && this.reservation.reservation.paiementRequis)
        this.paiementSection = true;
      this.contactList.forEach((x) => {
        this.allParticipants.forEach((participant) => {
          if (participant.id === x.id) {
            x.reponse = participant.reponse;
          }
        });
      });
    }, 2000);
  },
  computed: {
    ...mapGetters("InvitationModule", ["invitation"]),
    ...mapGetters("ReservationModule", ["reservation", "isLoading"]),
    ...mapGetters("ContactListModule", ["contactList"]),
    ...mapGetters("UserModule", ["get_user"]),
    getUserInfo() {
      return this.$store.getters["UserModule/get_user"];
    },
    isFetched() {
      return this.$store.getters["PaymentModule/isFetched"];
    },
    paymentAlias() {
      return this.$store.getters["PaymentModule/alias"];
    },
    isPaymentValid() {
      // paiement valide si retour = true
      return this.paymentAlias && this.isFetched;
    },
    reservationDate() {
      const date = dayjs(this.reservation.reservation.date).format(
        "DD/MM/YYYY"
      );
      const heureDebut = dayjs(this.reservation.reservation.heureDebut).format(
        "HH:mm"
      );
      const heureFin = dayjs(this.reservation.reservation.heureFin).format(
        "HH:mm"
      );
      return `${date} de ${heureDebut} à ${heureFin}`;
    },
    familyMembers() {
      if (this.checkIsLoggedIn && this.contactList) {
        const contactList = this.contactList.filter((contact) => {
          const contactAlreadyParticipant = this.participants.find(
            (participant) => {
              return parseInt(contact.id) === parseInt(participant.id);
            }
          );
          return !contactAlreadyParticipant;
        });

        return contactList.map((contact) => {
          return {
            id: contact.id,
            nom: contact.nom,
            prenom: contact.prenom,
            pseudo: contact.identifiant,
            reponse: contact.reponse,
          };
        });
      } else {
        return [];
      }
    },
    familyMembersResponse() {
      let i = false;
      this.familyMembers.forEach((participant) => {
        const response = this.getResponse(participant);
        if (response == "validation") i = true; // un membre de ma famille accepte l'invitation
      });
      return i;
    },
    organisateur() {
      return this.reservation.participants.find(
        (participant) => participant.organisateur === true
      );
    },
    participants() {
      let participant = [];
      if (this.allParticipants && !this.isLoading && !this.onError) {
        this.allParticipants.forEach((p) => {
          if (p.reponse === "validation") p.reponse = 2;
          if (p.reponse === "refus") p.reponse = 1;
          if (p.reponse === "attente") p.reponse = 0;
          if (p.id === this.getUserId) {
            p.pseudo = "Moi";
            p.nom = "";
            p.prenom = "";
            participant.push(p);
          }
        });
        if (participant.length === 0) {
          participant.push({
            id: this.getUserId,
            pseudo: "Moi",
            nom: "",
            prenom: "",
            reponse: null,
          });
        }
      }
      return participant;
    },
    isOrganizer() {
      if (this.organisateur && !this.isLoading && !this.onError) {
        return (
          this.organisateur && parseInt(this.organisateur.id) == this.getUserId
        );
      }
    },
    getUserId() {
      return this.$store.getters["AuthModule/get_userIdV2"];
    },
    checkIsLoggedIn() {
      return this.$store.getters["UserModule/get_user_id"];
    },
    getBasePath() {
      return process.env.VUE_APP_SPORT_IMG;
    },
    isPortableCheck() {
      // si le participant a un téléphone OU si le participant a enregistré un téléphone
      if (this.get_user.portable || this.validationCodeNumber) return true;

      // si le participant refuse l'invitation ou hésite ET si aucun enfant n'est ajouté à l'invitation
      if (
        this.participantReponse !== 2 &&
        this.familyMembersAcceptedResponse === 0
      ) {
        return true;
      }

      return false;
    },
    isValidatedResponse() {
      // si aucune réponse n'est donnée
      if (this.participantReponse === "") return false;
      // si l'utilisateur refuse ou hésite ET si aucun enfant n'est ajouté à l'invitation
      if (
        this.participantReponse !== 2 &&
        this.familyMembersAcceptedResponse === 0
      )
        return true;

      // si le paiement est requis
      if (this.reservation.reservation.paiementRequis) {
        // si le moyen de paiement est valide && si un téléphone est enregistré
        if (this.isPaymentValid && this.isPortableCheck) return true;
      } else {
        // paiement non requis
        // si un téléphone est enregistré
        if (this.isPortableCheck) return true;
      }
      return false;
    },
    isAcceptedResponseForPayment() {
      let x = false;
      this.allParticipants.forEach((participant) => {
        if (
          participant.reponse == 2 &&
          participant.id == this.get_user.userId
        ) {
          x = true; // un des participants accepte
        }
      });

      // si un des participants accepte et que le paiement est requis
      if (x && this.reservation.reservation.paiementRequis) return true;
      return false;
    },
    isRefusedResponseForPayment() {
      let y = false;
      this.allParticipants.forEach((participant) => {
        if (
          participant.reponse !== 2 &&
          participant.id == this.get_user.userId
        ) {
          y = true; // un des participants refuse
        }
      });

      // si un des participants hésite ou refuse ET si un enfant accepte ET si le paiement est requis
      if (
        y &&
        this.familyMembersResponse &&
        this.reservation.reservation.paiementRequis
      )
        return true;
      return false;
    },
    responseForPhone() {
      let z = false;
      this.allParticipants.forEach((participant) => {
        if (
          participant.reponse == 2 &&
          participant.id == this.get_user.userId
        ) {
          z = true; // un des participants accepte
        }
      });

      // si un des participants accepte ET qu'il n'a pas de téléphone enregistré
      if (z && !this.get_user.portable) return true;

      // si un des participants hésite ou refuse ET si un enfant accepte ET qu'il n'a pas de téléphone enregistré
      if (!z && this.familyMembersResponse && !this.get_user.portable)
        return true;
      return false;
    },
    isFullAcceptedResponse() {
      let count = 0;
      for (let b = 0; b < this.allParticipants.length; b++) {
        if (this.allParticipants[b].reponse === 2) count++;
      }
      return count + this.familyMembersAcceptedResponse;
    },
    isFull() {
      return (
        this.isFullAcceptedResponse >= this.reservation.reservation.nbPlaceMax
      );
    },
    familyMembersAcceptedResponse() {
      let count = 0;
      for (let f = 0; f < this.contactList.length; f++) {
        if (this.contactList[f].reponse === 2) count++;
      }
      return count;
    },
  },
  methods: {
    ...mapActions("InvitationModule", ["setInvitation", "valideInvitation"]),
    ...mapActions("ReservationModule", [
      "retrieveReservation",
      "retrieveReservationV2",
      "setParticipantResponse",
      "cleanReservation",
    ]),
    ...mapActions("ContactListModule", [
      "retrieveContactList",
      "setContactResponse",
    ]),
    ...mapActions("UserModule", ["SET_PROFIL_INFO"]),
    getFamilyContacts() {
      this.retrieveContactList();
    },
    updateResponse({ id, response, participant }) {
      // si l'utilisateur accepte l'invitation et que le paiement est requis, le paiement et les réductions s'affichent

      // si le paiement est requis
      // si l'utilisateur accepte l'invitation, le paiement et les réductions s'affichent
      // sinon
      // si un enfant accepte, le paiement et les réductions s'affichent
      if (this.reservation.reservation.paiementRequis) {
        if (response === 2) {
          this.discountIsAvailable = true;
          this.paiementSection = true;
        } else {
          if (this.familyMembersResponse) {
            this.discountIsAvailable = true;
            this.paiementSection = true;
          } else {
            this.discountIsAvailable = false;
            this.paiementSection = false;
          }
        }
      }

      this.participantReponse = response;
      this.allParticipants.forEach((participant) => {
        if (participant.id === id) this.orgaReponse = response;
      });

      this.setParticipantResponse({ id, response, participant });
    },
    updateResponseFamily({ id, response }) {
      if (response === 2 && this.reservation.reservation.paiementRequis) {
        this.discountIsAvailable = true;
        this.paiementSection = true;
      }
      this.setContactResponse({ id, response });
    },
    updateResponseDisconnect({ id, response }) {
      this.participantDisconnect.reponse = response;
      if (typeof response === "number") this.saveInvitation();
    },
    goTo(route) {
      route === "connexion"
        ? this.$router.push("/signin")
        : this.$router.push("/signup");
    },
    saveInvitation() {
      if (this.participantDisconnect && this.participantDisconnect.reponse) {
        const reponse = this.getResponse(this.participantDisconnect);
        if (reponse) {
          const responseData = [{ sReponse: reponse }];
          if (responseData && responseData.length === 1) {
            this.setInvitation({
              id_reservation: this.uuidReservation,
              jeton_invitation: this.uuidInvitation,
              response_data: responseData,
              toSave: true,
            });
          }
        }
      }
    },
    getResponse(participant) {
      const item = parseInt(participant.reponse);
      if (item === 0) return "attente";
      else if (item === 1) return "refus";
      else if (item === 2) return "validation";
      return null;
    },
    preCalcResponseData() {
      const responseData = [];

      let temp = [];
      this.discountsTemp.forEach((item) => {
        temp.push({
          discountId: item.id ?? item.couponId,
        });
      });

      this.participants.forEach((participant) => {
        const response = this.getResponse(participant);
        if (response) {
          responseData.push({
            idUser: participant.id,
            reponse: response,
            discounts: temp,
          });
        }
      });

      if (this.openFamilyBlock) {
        this.familyMembers.forEach((participant) => {
          const response = this.getResponse(participant);
          if (response) {
            responseData.push({
              idUser: participant.id,
              reponse: response,
            });
          }
        });
      }
      return responseData;
    },
    async getBooking(uuidReservation, uuidInvitation) {
      await this.retrieveReservationV2({ uuidReservation, uuidInvitation });
      this.allParticipants = this.reservation.participants;

      if (this.allParticipants !== null) {
        this.orgaReponse = this.allParticipants.find(
          (participant) => participant.organisateur === true
        ).reponse;
        this.participantReponse = this.allParticipants.find(
          (participant) => participant.id === this.checkIsLoggedIn
        );
      }
    },
    async submitInvitation() {
      this.submitSpinner = true;
      const responseData = this.preCalcResponseData();

      try {
        await this.valideInvitation({
          idReservation: this.uuidReservation,
          jetonInvitation: this.uuidInvitation,
          responseData,
        });
        this.$router.push("/profile?q=reservation");
      } catch (err) {
        console.error(err);
      } finally {
        this.submitSpinner = false;
      }
    },
    copyUser() {
      this.userInfo.sNom = this.get_user.nom;
      this.userInfo.sPrenom = this.get_user.prenom;
      this.userInfo.sEmail = this.get_user.userMail;
      this.userInfo.numero = this.get_user.portable;
    },
    validationPhoneNumber() {
      if (this.$v.userInfo.numero.$model === "") return;
      this.openValidationNumber = true;
      setPhoneNumberV2(this.userInfo);
    },
    async validationCode() {
      if (this.codeInfo.code === "") return;
      await setValidationPhoneNumberV2(this.codeInfo).then((response) => {
        if (response.bSucces) {
          this.messageSuccess =
            "Votre numéro de téléphone a bien été validé par notre système.";
          this.messageError = "";
          this.numero = this.userInfo.numero;
          this.validationCodeNumber = true;
        } else {
          this.messageError =
            "Votre code de validation est erroné, veuillez recommencer l'opération.";
        }
      });
      this.openValidationNumber = false;
    },
    sportIcon(sportName) {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === sportName.toLowerCase().trim()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    async openDiscount() {
      this.itemsDiscounts = [];
      const response = await getUserDiscount(
        this.getUserId,
        this.reservation.fitArena.id
      );
      this.discounts = response.statusBasedDiscounts;
      // si la taille de la liste temporaire n'est pas égale à la liste globale
      if (this.discounts.length !== this.discountsTemp.length) {
        // dans la liste globale des réductions
        this.discounts.forEach((discount) => {
          if (this.discountsTemp.length !== 0) {
            // dans la liste temporaire des réductions ajoutées au panier
            this.discountsTemp.forEach((item) => {
              // si la réduction n'est pas déjà dans la liste temporaire, l'ajouter dans la liste des items proposés
              if (item.label !== discount.label)
                this.itemsDiscounts.push(discount.label);
            });
          } else this.itemsDiscounts.push(discount.label);
        });
      }
      this.openUserDiscount = true;
    },
    updateDiscount(item) {
      this.discount = item;
    },
    async deleteDiscount(id) {
      this.discountsTemp.forEach((item, i) => {
        if (item.id === id) this.discountsTemp.splice(i, 1);
      });
    },
    async validateDiscount() {
      const discountTemp = this.discounts.find(
        (item) => item.label === this.discount
      );

      discountTemp.cartUsageEndDate = dayjs(
        discountTemp.cartUsageEndDate
      ).format("DD/MM/YYYY");
      this.discountsTemp.push(discountTemp);

      this.discountCode = "";
      this.openUserDiscount = false;
    },
    async validateDiscountCode(item) {
      const response = await verifyDiscountCode(item);
      if (response) this.discountsTemp.push(response);
      else this.$toast.error("Le code de réduction est invalide");
      this.openDiscountCode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.title-long-border {
  content: "";
  height: 2px;
  width: calc(50% - 300px);
  background-color: $doi--color-color0;
  position: relative;
  top: 40px;
}

.long-border-right {
  content: "";
  height: 2px;
  width: calc(50% - 250px);
  background-color: $doi--color-color0;
  position: relative;
  top: -20px;
  float: right;
}

.red-line {
  border-top: 1px solid $doi--color-color0;
  width: 35px;
}

@media (max-width: $doi-breakpoint-mobile) {
  #title,
  .reservation {
    font-size: $doi--text-font-size5;
  }
}
</style>
