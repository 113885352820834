<template>
  <div>
    <div
      :class="
        open === false && !this.$route.path.includes('/cart')
          ? 'border-t border-black'
          : ''
      "
      class="flex justify-end lg:justify-start"
    >
      <div
        @click="$emit('toggle')"
        class="bg-black text-white text-xs font-family lg:-bottom-10 flex items-center justify-between uppercase tracking-wider cursor-pointer"
        :class="
          this.$route.path.includes('/cart')
            ? 'rounded-t-lg lg:px-5 lg:py-2 lg:4/12'
            : 'px-5 py-2 w-full lg:w-4/12'
        "
      >
        {{ displayText }}
        <span class="material-icons text-white">{{
          open === false ? "keyboard_arrow_down" : "keyboard_arrow_up"
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoiAccordionButton",
  props: {
    primaryText: {
      type: String,
      default: "DÉTAILS",
    },
    secondaryText: String,
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayText() {
      return this.open && this.secondaryText
        ? this.secondaryText
        : this.primaryText;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.font-family {
  font-family: $doi--text-font-family5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
