<template>
  <div>
    <!-- <div v-if="isMobile" class="text-center mx-auto px-6">
      <DoiText :textColor="1" :fontSize="5" :fontFamily="0"
        >Message aux utilisateurs de notre application mobile</DoiText
      >
      <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
        >Nous rencontrons actuellement quelques soucis sur l'application mobile.
        Nos équipes travaillent à les résoudre. Si vous rencontrez des
        problèmes, n'hésitez pas à accéder directement à notre site via votre
        navigateur à l'adresse suivante :
        <a href="https://www.fit-arena.fr/" class="underline" target="_blank"
          >www.fit-arena.fr</a
        >. Merci pour votre compréhension.</DoiText
      >
    </div> -->
    <header>
      <Header />
    </header>
    <section class="mt-6" id="fit-arena-list">
      <div class="flex flex-1 justify-center">
        <span class="flex flex-1 items-center"><DoiLine class="line" /></span>
        <DoiHeader
          class="flex text-center title"
          :level="2"
          :headerColor="1"
          :fontFamily="0"
          >JE RÉSERVE MA FIT ARENA</DoiHeader
        >
        <span class="flex flex-1"></span>
      </div>
      <FitArenaList />
    </section>
    <section>
      <BookPresentation />
    </section>
    <section>
      <PlayPresentation />
    </section>
    <section>
      <SharePresentation />
    </section>
    <section class="container mt-6 mx-auto 4k:mt-20" id="sport">
      <div class="flex flex-1 justify-center">
        <span class="flex flex-1 items-center"><DoiLine class="line" /></span>
        <DoiHeader
          class="flex text-center title"
          :level="2"
          :headerColor="1"
          :fontFamily="0"
          >LES SPORTS DISPONIBLES AVEC FIT ARENA</DoiHeader
        >
        <span class="flex flex-1"></span>
      </div>
      <SportList />
    </section>
    <footer>
      <ShareSection />
    </footer>
  </div>
</template>

<script>
import SportList from "./components/SportList";
import FitArenaList from "./components/FitArenaList";
import Header from "./components/Header";
import ShareSection from "@/views/share/ShareSection";
import BookPresentation from "./components/BookPresentation";
import PlayPresentation from "./components/PlayPresentation";
import SharePresentation from "./components/SharePresentation";

export default {
  name: "FitArenaHolding",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Réservation de salle multisports connectée, intelligente et autonome. Partage d'expériences sportives",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.fit-arena.fr/" }],
  },
  components: {
    SportList,
    FitArenaList,
    Header,
    ShareSection,
    BookPresentation,
    PlayPresentation,
    SharePresentation,
  },
  computed: {
    isMobile() {
      return window.navigator.userAgent.includes("cordova");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.line {
  width: 80%;
}

@media (max-width: $doi-breakpoint-mobile) {
  .title {
    font-size: $doi--header5;
    margin-top: 2rem;
  }
}
</style>
