export default {
  props: {
    bgHover: Number,
  },
  computed: {
    bgHoverStyle() {
      if (typeof this.bgHover === "number") return " bg-hover" + this.bgHover;
      return " ";
    },
  },
};
