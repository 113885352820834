export default {
  props: {
    fontWeight: Number,
  },
  computed: {
    fontWeightStyle() {
      if (typeof this.fontWeight === "number")
        return " font-weight" + this.fontWeight;
      return " ";
    },
  },
};
