import { getContactsV2 } from "@/api/ContactService";
import Vue from "vue";

const initState = () => {
  return {
    isFetched: false,
    data: [],
  };
};

export default {
  namespaced: true,
  namespace: "contactList",
  state: initState(),
  actions: {
    async retrieveContactList({ commit }) {
      const contactList = await getContactsV2();
      commit("setContactList", contactList);
    },
    setContactResponse({ commit }, { id, response }) {
      commit("setContactResponse", { id, response });
    },
    resetStore({ commit }) {
      commit("resetStore");
    },
  },
  mutations: {
    resetStore: (state) => {
      Object.assign(state, initState());
    },
    setContactResponse: (state, { id, response }) => {
      const contact = state.data.find((contact) => {
        return id === contact.id;
      });
      contact.reponse = response;

      Vue.set(state, "data", [...state.data]);
      state.data.splice(state.data.length);
    },
    setContactList: (state, payload) => {
      Vue.set(state, "data", [...payload]);
      state.data.splice(state.data.length);
      state.isFetched = true;
    },
  },
  getters: {
    contactList: (state) => {
      return state.data;
    },
    isFetched: (state) => {
      return state.isFetched;
    },
  },
};
