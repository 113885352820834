<template>
  <div class="bg-fa-red">
    <div class="md:hidden">
      <div class="flex justify-center">
        <div>
          <DoiText
            :fontSize="7"
            :fontFamily="0"
            class="share-title relative pt-6 tracking-widest"
            >SHARE !</DoiText
          >
        </div>
      </div>
      <div class="flex flex-col">
        <div class="w-full flex flex-col justify-center mt-3">
          <DoiText class="text-center px-5" :fontSize="4" :fontFamily="0"
            >JE VISIONNE LES TEMPS FORTS DE MES PARTIES</DoiText
          >
          <div class="flex flex-1 justify-center">
            <img
              class="max-w-xs"
              alt="présentation de l'application smartphone lors du visionnage des temps forts"
              src="@/assets/img/presentation/ShareIphone1.png"
            />
          </div>
        </div>
        <div class="w-full flex flex-col justify-center mt-3">
          <DoiText class="text-center px-5" :fontSize="4" :fontFamily="0"
            >J'ANALYSE MES PERFORMANCES SPORTIVES</DoiText
          >
          <div class="flex flex-1 justify-center">
            <img
              class="max-w-xs"
              alt="présentation de l'application smartphone sur la page d'analyse des performances sportives"
              src="@/assets/img/presentation/ShareIphone2.png"
            />
          </div>
        </div>
        <div class="w-full flex flex-col justify-center mt-3">
          <DoiText class="text-center px-5" :fontSize="4" :fontFamily="0"
            >JE COMPARE MES STATISTIQUES AVEC MES AMIS ET LES JOUEURS DU MONDE
            ENTIER !</DoiText
          >
          <div class="flex flex-1 justify-center">
            <img
              class="max-w-xs"
              alt="présentation de l'application smartphone lors de la comparaison des statistiques avec les autres joueurs et amis"
              src="@/assets/img/presentation/ShareIphone3.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block px-14 lg:px-32">
      <carousel
        :navigationEnabled="true"
        navigationPrevLabel="&#10140;"
        navigationNextLabel="&#10140;"
        :perPage="1"
      >
        <slide>
          <div class="share-slider">
            <div class="share-slider-img">
              <img
                class="max-w-xs"
                alt="présentation de l'application smartphone lors du visionnage des temps forts"
                src="@/assets/img/presentation/ShareIphone1.png"
              />
            </div>
            <div class="share-slider-wrapper">
              <div class="share-slider-spacer"></div>
              <div class="share-slider-text-wrapper">
                <div>
                  <DoiText
                    :fontSize="7"
                    :fontFamily="0"
                    class="share-slider-title tracking-widest 2k:text-7xl 4k:text-9xl"
                    >SHARE !</DoiText
                  >
                </div>
                <DoiText class="share-slider-text" :fontFamily="0" :fontSize="4"
                  >JE VISIONNE LES TEMPS FORTS DE MES PARTIES</DoiText
                >
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="share-slider">
            <div class="share-slider-img">
              <img
                class="max-w-xs"
                alt="présentation de l'application smartphone sur la page d'analyse des performances sportives"
                src="@/assets/img/presentation/ShareIphone2.png"
              />
            </div>
            <div class="share-slider-wrapper">
              <div class="share-slider-spacer"></div>
              <div class="share-slider-text-wrapper">
                <div>
                  <DoiText
                    :fontSize="7"
                    :fontFamily="0"
                    class="share-slider-title tracking-widest 2k:text-7xl 4k:text-9xl"
                    >SHARE !</DoiText
                  >
                </div>
                <DoiText class="share-slider-text" :fontFamily="0" :fontSize="4"
                  >J'ANALYSE MES PERFORMANCES SPORTIVES</DoiText
                >
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="share-slider">
            <div class="share-slider-img">
              <img
                class="max-w-xs"
                alt="présentation de l'application smartphone lors de la comparaison des statistiques avec les autres joueurs et amis"
                src="@/assets/img/presentation/ShareIphone3.png"
              />
            </div>
            <div class="share-slider-wrapper">
              <div class="share-slider-spacer"></div>
              <div class="share-slider-text-wrapper">
                <div>
                  <DoiText
                    :fontSize="7"
                    :fontFamily="0"
                    class="share-slider-title tracking-widest 2k:text-7xl 4k:text-9xl"
                    >SHARE !</DoiText
                  >
                </div>
                <DoiText class="share-slider-text" :fontFamily="0" :fontSize="4"
                  >JE COMPARE MES STATISTIQUES AVEC MES AMIS ET LES JOUEURS DU
                  MONDE ENTIER !</DoiText
                >
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "SharePresentation",
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.VueCarousel-navigation-prev {
  transform: rotateY(180deg) translateY(-50%) translateX(100%) !important;
}
.VueCarousel-navigation-button {
  background-color: #4c4c4c !important;
  color: white !important;
  padding: 7px 10px !important;
}
.VueCarousel-dot {
  background: transparent !important;
  border-radius: 0px !important;
  border: 1px solid white !important;
  padding: 0px !important;
  margin: 0.5rem !important;
  margin-bottom: 1rem !important;
}
.VueCarousel-dot--active {
  background: white !important;
}

.share-title {
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid white;
}

.share-title:before {
  content: "";
  height: 1px;
  bottom: -1px;
  position: absolute;
  width: 100vw;
  transform: translateX(-5rem);
  background-color: #fff;
  margin-left: -5rem;
}

.share-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.share-slider-img {
  display: flex;
  justify-content: center;
}

.share-slider-wrapper {
  display: flex;
}

.share-slider-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.share-slider-spacer {
  width: 5rem;
}

.share-slider-title {
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid white;
}

.share-slider-title:before {
  content: "";
  height: 1px;
  bottom: -1px;
  position: absolute;
  width: 5rem;
  background-color: #fff;
  margin-left: -5rem;
}

.share-slider-text {
  font-family: $doi--text-font-family0;
  margin-top: 2.25rem;
  line-height: 2rem;
  max-width: 24rem;
}

@media (min-width: $doi-breakpoint-large-screen) {
  .share-slider-title {
    border-bottom: 3px solid white;
  }

  .share-slider-text {
    font-size: 2rem;
    line-height: 3rem;
  }
}
</style>
