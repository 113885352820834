import { getPerformances } from "@/api/StatisticService";

const initState = () => {
  return {
    isFetched: false,
    data: [],
  };
};

export default {
  namespaced: true,
  namespace: "performance",
  state: initState(),
  actions: {
    async retrievePerformance({ commit }) {
      commit("resetStore");
      const response = await getPerformances();
      commit("setPerformance", response);
    },
  },
  mutations: {
    resetStore: (state) => {
      Object.assign(state, initState());
    },
    setPerformance: (state, payload) => {
      state.data = payload;
      Object.assign(state, state.data);
      state.isFetched = true;
    },
  },
  getters: {
    performance: (state) => {
      return state.data;
    },
    isFetched: (state) => {
      return state.isFetched;
    },
  },
};
