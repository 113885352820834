<template>
  <td
    :class="
      bgColorTableClass + textColorStyle + fontFamilyStyle + fontSizeStyle
    "
    class="px-2 py-2 border-right-cell"
  >
    <slot></slot>
  </td>
</template>

<script>
import bgColorTableMixin from "@/components/mixins/bgColorTableMixin.js";
import textColorMixin from "@/components/mixins/textColorMixin.js";
import fontFamilyMixin from "@/components/mixins/fontFamilyMixin.js";
import fontSizeMixin from "@/components/mixins/fontSizeMixin.js";

export default {
  name: "DoiTableCell",
  mixins: [bgColorTableMixin, textColorMixin, fontFamilyMixin, fontSizeMixin],
};
</script>
