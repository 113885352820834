<template>
  <div class="flex flex-col h-full">
    <slot name="img" />
    <div class="p-4 flex flex-1 flex-col">
      <DoiHeader :level="3" :headerColor="0" class="text-2xl">
        <slot name="article-title" />
      </DoiHeader>
      <div class="divider w-5/12 my-3" />
      <div class="mb-8">
        <DoiText :fontFamily="4" :textColor="3" :fontSize="3">
          <slot name="article-description" />
        </DoiText>
      </div>
    </div>
    <div class="flex justify-end">
      <slot name="button" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DoiArticleCard",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.divider {
  height: 1px;
  background-color: $doi--color-color4;
}
</style>
