<template>
  <div class="flex justify-center">
    <img src="@/assets/img/Page_maintenance.jpg" alt="Page non trouvée" />
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>
