<template>
  <div class="text-center leading-none w-full">
    <h1 class="font-title-presentation tracking-widest">
      <slot />
    </h1>
    <DoiText :textColor="3" :fontFamily="4" class="tracking-wider mt-6 title"
      >MA SALLE DE SPORT</DoiText
    >
    <DoiText :textColor="3" :fontFamily="4" class="tracking-wider mt-4 title"
      >EN TROIS CLICS</DoiText
    >
  </div>
</template>

<script>
export default {
  name: "TitlePresentation",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.font-title-presentation {
  font-family: $doi--text-font-family0;
  color: $doi--header-color0;
  font-size: $doi--header1;
}

.title {
  font-size: 1.7rem !important;
}
</style>
