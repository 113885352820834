<template>
  <div class="container mx-auto max-w-6xl">
    <div class="flex flex-col lg:flex-row lg:justify-between">
      <span v-click-outside="clickOutsideDropdown" class="relative">
        <div class="w-full" v-show="openOtherArenaDropdown">
          <div
            v-for="otherFitArena in otherFitArenaList"
            :key="otherFitArena.cle"
            class="z-10 flex bg-white w-full shadow-lg origin-center right-0 flex-col link-navigation"
          >
            <router-link
              v-if="otherFitArena.active"
              :to="'/fit-arena/' + otherFitArena.cle"
              class="cursor-pointer border-b grey py-0.5"
              >{{ otherFitArena.libelleSalle }}</router-link
            >
          </div>
        </div>
      </span>
    </div>

    <div class="flex items-center py-4 pl-4">
      <DoiHeader :level="2" :fontFamily="0" class="header-font"
        >JE CHOISIS MON ACTIVITÉ</DoiHeader
      >
    </div>
    <div
      class="flex mb-10 overflow-x-auto lg:flex-wrap pb-4 lg:-ml-3 lg:-mr-3 justify-start"
    >
      <div
        v-for="sport in sportList"
        :key="sport.idSport"
        class="text-center cursor-pointer"
        @click="
          selectSport(sport.idSport, sport.libelle, sport.NbPersonnePayante)
        "
      >
        <SportSelectorItem
          :sport="sport"
          :isSelected="idSport === sport.idSport"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SportSelectorItem from "@/views/fitarena/SportSelectorItem";

import { mapGetters } from "vuex";

export default {
  name: "SportSelector",
  data() {
    return {
      idSport: null,
      openOtherArenaDropdown: false,
    };
  },
  components: {
    SportSelectorItem,
  },
  props: {
    fitArena: Object,
    sportListProps: Object,
  },
  computed: {
    ...mapGetters("FitArenaListModule", ["fitArenaList"]),
    libelleSalle() {
      if (this.fitArena && typeof this.fitArena.libelleSalle === "string")
        return this.fitArena.libelleSalle.toUpperCase();
      return "";
    },
    sportList() {
      if (this.sportListProps) return Object.values(this.sportListProps);
      return [];
    },
    otherFitArenaList() {
      return this.fitArenaList.filter((fitArena) => {
        return this.fitArena.cle !== fitArena.cle;
      });
    },
  },
  methods: {
    clickOutsideDropdown() {
      this.openOtherArenaDropdown = false;
    },
    selectSport(idSport, libelle, NbPersonnePayante) {
      this.idSport = idSport;
      this.$store.dispatch("FitArenaModule/setSport", {
        nIdSport: idSport,
        sLibelle: libelle,
        nNbPersonnePayante: NbPersonnePayante,
      });
    },
  },
  watch: {
    sportList(value) {
      if (value && value.length > 0) {
        this.selectSport(
          value[0].idSport,
          value[0].libelle,
          value[0].NbPersonnePayante
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.grey {
  border-color: $doi--color-color-lighter4;
}

.link-navigation {
  font-family: $doi--text-font-family2;
  color: $doi--color-color4;
}

.header-font {
  font-size: $doi--header4;
  letter-spacing: 1px;
}
</style>
