<template>
  <table>
    <thead>
      <tr>
        <slot name="thead"></slot>
      </tr>
    </thead>
    <tbody>
      <tr>
        <slot name="tbody1"></slot>
      </tr>
      <tr>
        <slot name="tbody2"></slot>
      </tr>
      <tr>
        <slot name="tbody3"></slot>
      </tr>
      <tr>
        <slot name="tbody4"></slot>
      </tr>
      <tr>
        <slot name="tbody5"></slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "DoiTable",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
thead > tr {
  border: 1px solid black;
}
th {
  background: $doi--color-color0;
  color: white;
  font-weight: bold;
}
td,
th {
  padding: 6px;
}
td {
  padding-left: 20px;
}
tr:nth-of-type(odd) {
  background: #eee;
}

@media (min-width: 2047px) {
  table {
    width: 70%;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border-right: 1px solid black;
    border-left: 1px solid black;
  }

  td:first-child {
    border-top: 1px solid black;
  }
  td {
    border: none;
    border-bottom: 1px solid black;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
