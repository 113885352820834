var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'p')?_c('p',{class:_vm.fontFamilyStyle +
    _vm.fontSizeStyle +
    _vm.fontWeightStyle +
    _vm.textColorStyle +
    _vm.italicClass},[_vm._t("default")],2):(_vm.type === 'span')?_c('span',{class:_vm.fontFamilyStyle +
    _vm.fontSizeStyle +
    _vm.fontWeightStyle +
    _vm.textColorStyle +
    _vm.italicClass},[_vm._t("default")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }