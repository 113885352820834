<template>
  <div class="container flex max-w-6xl mx-auto mt-8 mb-12">
    <div
      v-if="this.$route.path === '/fit-arena/padel-park'"
      class="flex w-full flex-wrap"
    >
      <div
        v-for="(articlePadel, index) in articlesPadel"
        :key="index"
        class="w-full lg:w-4/12 mt-4 mb-6 shadow-xl article-border flex items-center justify-center px-2 rounded"
      >
        <DoiArticleCard>
          <template slot="img">
            <div
              v-if="articlePadel.imageUrl === ''"
              class="bg-fa-light-gray article-img-container"
            ></div>
            <div v-else class="article-img-container">
              <img
                class="article-img"
                :src="
                  require('@/assets/img/mock/cmsActu/' + articlePadel.imageUrl)
                "
              />
            </div>
          </template>
          <template slot="article-title">{{
            articlePadel.shortTitle
          }}</template>
          <template slot="article-description">{{
            articlePadel.shortDescription
          }}</template>
          <template slot="button">
            <router-link :to="articlePadel.link" class="flex">
              <DoiButton @click="setRoute()" :fontFamily="4"
                >LIRE LA SUITE</DoiButton
              >
              <span class="bg w-10 h-full flex items-center justify-center">
                <img src="@/assets/icons/common/article-link.svg" />
              </span>
            </router-link>
          </template>
        </DoiArticleCard>
      </div>
    </div>
    <!-- Fit Arena Demo -->
    <div v-else class="flex w-full flex-wrap">
      <div
        v-for="(article, index) in articles"
        :key="index"
        class="w-full lg:w-4/12 mt-4 mb-6 shadow-xl article-border flex items-center justify-center px-2 rounded"
      >
        <DoiArticleCard>
          <template slot="img">
            <div
              v-if="article.imageUrl === ''"
              class="bg-fa-light-gray article-img-container"
            ></div>
            <div v-else class="article-img-container">
              <img
                class="article-img"
                :src="require('@/assets/img/mock/cmsActu/' + article.imageUrl)"
              />
            </div>
          </template>
          <template slot="article-title">{{ article.shortTitle }}</template>
          <template slot="article-description">{{
            article.shortDescription
          }}</template>
          <template slot="button">
            <router-link :to="article.link" class="flex">
              <DoiButton @click="setRoute()" :fontFamily="4"
                >LIRE LA SUITE</DoiButton
              >
              <span
                class="rounded w-10 h-full bg flex items-center justify-center"
              >
                <img src="@/assets/icons/common/article-link.svg" />
              </span>
            </router-link>
          </template>
        </DoiArticleCard>
      </div>
    </div>
  </div>
</template>

<script>
import articles from "@/data/mock/articles.json";
import articlesPadel from "@/data/mock/articles-padel-park.json";

export default {
  name: "ArticleList",
  data() {
    return {
      articles: articles,
      articlesPadel: articlesPadel,
    };
  },
  props: {
    fitArenaName: String,
  },
  methods: {
    setRoute() {
      localStorage.setItem("route", this.fitArenaName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.article-img-container {
  max-width: 100%;
  height: 200px;
}

.article-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

span.bg {
  background-color: $doi--color-color4;
}
</style>
