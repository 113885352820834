<template>
  <h1 class="m-4 uppercase text-center title">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: "H1Article",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.title {
  font-size: $doi--header1;
  color: $doi--header-color0;
  font-family: $doi--text-font-family2;
}

@media (max-width: 640px) {
  .title {
    font-size: 2rem;
    margin-top: 0;
  }
}
</style>
