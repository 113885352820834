import { getFitArena, getFitArena2 } from "@/api/FitArenaService";

const initState = () => {
  return {
    isFetched: false,
    data: {
      idFitArena: null,
      idSport: null,
      libelle: "",
      nbPersonnePayante: 0,
      nbPersonneConseille: 0,
      sports: {},
      libelleSalle: "",
      cle: "",
      adresse: {},
    },
  };
};

export default {
  namespaced: true,
  namespace: "fitArena",
  state: initState(),
  actions: {
    setSport({ commit }, payload) {
      commit("setSport", payload);
    },
    async retrieveFitArena({ commit }, cle) {
      commit("resetStore");
      const response = await getFitArena(cle);
      commit("setFitArena", response);
    },
    async retrieveFitArena2({ commit }, cle) {
      commit("resetStore");
      const response = await getFitArena2(cle);
      commit("setFitArena", response);
    },
  },
  mutations: {
    setSport: (state, payload) => {
      state.data.idSport = payload.nIdSport;
      state.data.libelle = payload.sLibelle;
      state.data.nbPersonnePayante = payload.nbPersonnePayante;
      state.data.nbPersonneConseille = payload.nbPersonneConseille;
      Object.assign(state, state.data);
    },
    resetStore: (state) => {
      Object.assign(state, initState());
    },
    setFitArena: (state, payload) => {
      state.data.sports = payload.sports;
      Object.assign(state.data, payload);
    },
  },
  getters: {
    fitArena: (state) => {
      return state.data;
    },
    isFetched: (state) => {
      return state.isFetched;
    },
    idFitArena: (state) => {
      return state.data.idFitArena;
    },
    selectedSport: (state) => {
      const sport = Object.values(state.data.sports).find((sport) => {
        return state.data.idSport === sport.idSport;
      });
      return sport || null;
    },
    get_id_sport: (state) => {
      return state.data.idSport;
    },
    get_sport_libelle: (state) => {
      return state.data.libelle;
    },
    get_nb_personne_payante: (state) => {
      return state.data.nbPersonnePayante;
    },
    get_nb_personne_conseille: (state) => {
      return state.data.nbPersonneConseille;
    },
  },
};
