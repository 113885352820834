<template>
  <div class="progress-circle">
    <slot></slot>
    <div v-show="percent > 50" class="progress-mask-left">
      <div :style="rotationStyleLeft" class="progress-left"></div>
    </div>
    <div class="progress-mask-right">
      <div :style="rotationStyleRight" class="progress-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressCircle",
  props: {
    percent: Number,
  },
  computed: {
    rotation() {
      return (this.percent * 3.6).toFixed(0);
    },
    rotationStyleRight() {
      if (this.percent >= 50) return "transform:rotate(180deg);";
      else return "transform:rotate(" + this.rotation + "deg);";
    },
    rotationStyleLeft() {
      if (this.percent >= 50)
        return "transform:rotate(" + this.rotation + "deg);";
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.progress-circle {
  position: relative;
  box-sizing: border-box;
  font-size: 12em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.15em solid $doi--color-color-lighter1;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-start;
}

.progress-mask-right {
  position: absolute;
  width: 1em;
  height: 1em;
  left: -0.15em;
  top: -0.15em;
  clip: rect(0, 1em, 1em, 0.5em);
}

.progress-right {
  position: absolute;
  box-sizing: border-box;
  border-width: 0.15em;
  border-style: solid;
  border-color: $doi--color-color0;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  clip: rect(0, 0.5em, 1em, 0);
}

.progress-mask-left {
  position: absolute;
  width: 1em;
  height: 1em;
  left: -0.15em;
  top: -0.15em;
}

.progress-left {
  position: absolute;
  box-sizing: border-box;
  border-width: 0.15em;
  border-style: solid;
  border-color: $doi--color-color0;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  clip: rect(0, 0.5em, 1em, 0);
}
</style>
