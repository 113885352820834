export default {
  props: {
    borderLeftColor: Number,
  },
  computed: {
    borderLeftColorStyle() {
      if (typeof this.borderLeftColor === "number")
        return " bl" + this.borderLeftColor;
      return " ";
    },
  },
};
