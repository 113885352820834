<template>
  <span class="flex flex-col items-center w-24">
    <div
      class="w-20 h-20 bg-white rounded-lg shadow-xl mx-3 mt-3 p-2 border-2 border-white hover:border-fa-red duration-200"
      :class="isSelected ? 'border-fa-red' : ''"
    >
      <img v-if="sport.icone" :src="sportWithIcone" />
      <img v-else :src="require(`@/assets/icons/utils/sports/${sportIcon}`)" />
    </div>
    <DoiText
      :textColor="3"
      :fontSize="2"
      :fontFamily="5"
      :class="isSelected ? 'red' : ''"
      class="mt-1 tracking-wider"
      >{{ sport.libelle }}
    </DoiText>
  </span>
</template>

<script>
import sports from "@/data/sports.json";
import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

export default {
  name: "SportSelectorItem",
  props: {
    sport: Object,
    isSelected: Boolean,
  },
  computed: {
    sportIcon() {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === this.sport.libelle.toLowerCase().trim()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    sportWithIcone() {
      return generateImageUrlV2(this.sport.icone);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.red {
  color: $doi--color-color0;
}
</style>
