<template>
  <div class="justify-center w-9/12 text-center mx-auto mb-4">
    <router-link to="/" class="flex justify-center mt-4">
      <img
        class="w-52 logo"
        src="@/assets/img/logo-fitarena.png"
        alt="Fit Arena"
      />
    </router-link>
    <DoiHeader
      :level="1"
      :fontFamily="1"
      class="text-2xl md:text-4xl my-4 tracking-wide"
      >MES PERFORMANCES - {{ url[0] }}</DoiHeader
    >
    <div
      class="pb-6 items-center px-4 sm:mx-auto sm:px-0 w-full sm:w-9/12 lg:w-5/12"
    >
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="3"
        class="border-b py-2"
        >Nombre de matchs joués :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[1]
        }}</DoiText></DoiText
      >
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="3"
        class="border-b py-2"
        >Victoires :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[2]
        }}</DoiText></DoiText
      >
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="3"
        class="border-b py-2"
        >Nuls :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[3]
        }}</DoiText></DoiText
      >
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="3"
        class="border-b py-2"
        >Défaites :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[4]
        }}</DoiText></DoiText
      >
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="3"
        class="border-b py-2"
        >Temps de jeu :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[5]
        }}</DoiText></DoiText
      >
    </div>
    <ProgressCircle
      :percent="parseInt(url[6], 10)"
      class="items-center mx-auto"
    >
      <div class="flex flex-col text-center">
        <DoiText :fontSize="4" :textColor="1" :fontFamily="6" class="mt-8 mb-2"
          >RATIO V</DoiText
        >
        <DoiText :textColor="0" :fontSize="5" class="-mt-4">{{
          url[7]
        }}</DoiText>
      </div>
    </ProgressCircle>
    <div
      class="px-4 py-6 items-center text-center mx-auto w-full sm:w-9/12 lg:w-5/12"
    >
      <DoiText :textColor="0" :fontFamily="1" :fontSize="4"
        >VICTOIRE {{ url[8] }}%</DoiText
      >
      <DoiText :textColor="6" :fontFamily="1" :fontSize="4"
        >DÉFAITE {{ url[9] }}%</DoiText
      >
      <DoiText :textColor="3" :fontFamily="1" :fontSize="4"
        >NUL {{ url[10] }}%</DoiText
      >
    </div>
    <div
      class="bg-gray-200 px-6 py-2 mb-4 items-center mx-auto w-full sm:w-9/12 lg:w-5/12"
    >
      <DoiText :textColor="1" :fontSize="3" :fontFamily="5" class="py-2"
        >Nombre total de buts marqués :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[11]
        }}</DoiText></DoiText
      >
      <DoiText :textColor="1" :fontSize="3" :fontFamily="5" class="py-2"
        >Nombre moyen de buts marqués par match :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[12]
        }}</DoiText></DoiText
      >
      <DoiText :textColor="1" :fontSize="3" :fontFamily="5" class="py-2"
        >Nombre total de fautes :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[13]
        }}</DoiText></DoiText
      >
      <DoiText :textColor="1" :fontSize="3" :fontFamily="5" class="py-2"
        >Nombre moyen de fautes par match :
        <DoiText type="span" :textColor="1" :fontSize="4">{{
          url[14]
        }}</DoiText></DoiText
      >
    </div>
  </div>
</template>

<script>
import ProgressCircle from "@/components/atomic/ProgressCircle";

export default {
  name: "SharePerformance",
  data() {
    return {
      url: this.$route.params.code,
    };
  },
  props: ["code"],
  components: { ProgressCircle },
  mounted() {
    this.decode;
  },
  computed: {
    decode() {
      this.url = window.atob(this.url);
      this.url = this.url.split("&");
    },
  },
};
</script>
