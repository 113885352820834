var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"btn"},_vm.$listeners),[(_vm.link && !_vm.disabled)?_c('router-link',{staticClass:"btn-foreground cursor-pointer py-3 px-4",class:_vm.bgColorStyle +
      _vm.bgHoverStyle +
      _vm.textColorStyle +
      _vm.fontFamilyStyle +
      _vm.fontSizeStyle +
      _vm.fontWeightStyle,attrs:{"to":_vm.link}},[_vm._t("default")],2):_c('button',{staticClass:"btn-foreground cursor-pointer py-3 px-4",class:_vm.bgColorStyle +
      _vm.bgHoverStyle +
      _vm.textColorStyle +
      _vm.fontFamilyStyle +
      _vm.fontSizeStyle +
      _vm.fontWeightStyle,attrs:{"disabled":_vm.disabled}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }