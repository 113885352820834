export default {
  props: {
    textColor: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    textColorStyle() {
      if (typeof this.textColor === "number")
        return " text-color" + this.textColor;
    },
  },
};
