import { getInfoEquipe } from "@/api/ScoreServices";

const initState = () => {
  return {
    isFetched: false,
    data: {
      equipe1: [],
      equipe2: [],
      total_equipe: [],
      non_joueur: [],
    },
  };
};

export default {
  namespaced: true,
  namespace: "teams",
  state: initState(),
  actions: {
    async retrieveTeams({ commit }, idPartie) {
      commit("resetStore");
      const response = await getInfoEquipe(idPartie);
      commit("setTeams", response);
    },
  },
  mutations: {
    resetStore: (state) => {
      Object.assign(state, initState());
    },
    setTeams: (state, payload) => {
      state.data.teams = payload.equipe;
      Object.assign(state.data, state.data);
      state.isFetched = true;
    },
  },
  getters: {
    teams: (state) => {
      return state.data.teams;
    },
    isFetched: (state) => {
      return state.isFetched;
    },
  },
};
