import Network from "@/utils/Network";

const url1 = process.env.VUE_APP_API_URL;
const url2 = process.env.VUE_APP_API_URL_V2;

const getFormToken = () => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url1 + "paiement/alias/gestion",
  });
};

const getFormTokenV2 = (host) => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url2 + `paiement/alias/gestion?host=${host}`,
  });
};

const savePaymentAlias = (alias, dateCreation, fournisseur, idContact) => {
  return Network.execute({
    method: "POST",
    secure: true,
    url: url1 + "paiement/alias/gestion",
    params: {
      sJetonAlias: alias,
      sDateCreation: dateCreation,
      sFournisseurAlias: fournisseur,
      nIdContact: idContact,
    },
  });
};

const getPaymentAlias = () => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url1 + "paiement/alias/etat",
  });
};

const getPaymentAliasV2 = () => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url2 + "paiement/alias/etat",
  });
};

const getHistoryPayment = () => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url1 + "paiement/utilisateur/historique",
  });
};

const getHistoryPaymentV2 = (id) => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url2 + `user/${id}/payment/liste?type=past`,
  });
};

const getPaymentsToBeSettled = (id) => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url2 + `user/${id}/payment/liste?type=rectify`,
  });
};

const getPaymentsToCome = (id) => {
  return Network.execute({
    method: "GET",
    secure: true,
    url: url2 + `user/${id}/payment/liste?type=coming`,
  });
};

const sendPaymentOrder = (paymentId) => {
  return Network.execute({
    method: "POST",
    secure: true,
    url: url2 + "contentieux/generer-ordre",
    payload: {
      paiementId: paymentId,
      commentaireOdp: "",
    },
  });
};

export {
  getFormToken,
  getFormTokenV2,
  savePaymentAlias,
  getPaymentAlias,
  getPaymentAliasV2,
  getHistoryPayment,
  getHistoryPaymentV2,
  getPaymentsToBeSettled,
  getPaymentsToCome,
  sendPaymentOrder,
};
