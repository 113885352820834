<template>
  <div class="justify-center w-9/12 text-center mx-auto">
    <router-link to="/" class="flex justify-center mt-4">
      <img
        class="w-52 logo"
        src="@/assets/img/logo-fitarena.png"
        alt="Fit Arena"
      />
    </router-link>
    <DoiHeader
      :level="1"
      :fontFamily="1"
      class="text-2xl md:text-4xl my-4 tracking-wide"
      >MON CLASSEMENT</DoiHeader
    >
    <div class="flex flex-row bg-gray-200">
      <div class="bg-fa-red img-icon items-center flex justify-center">
        <DoiSvg
          alt=""
          fill="white"
          stroke="white"
          class="w-8 lg:w-20"
          :path="url[0]"
        />
      </div>
      <div class="flex items-center w-1/2">
        <DoiText
          class="ml-6 sm:ml-10 sm:text-2xl lg:text-3xl sport-label"
          :textColor="1"
          :fontSize="5"
          :fontFamily="4"
          >{{ url[1] }}</DoiText
        >
      </div>
      <div class="flex items-center w-1/2 relative">
        <DoiText
          :textColor="1"
          :fontFamily="5"
          class="absolute hidden right-10 sm:block"
          >VOTRE POSITION :
          <DoiText
            type="span"
            :textColor="0"
            :fontFamily="5"
            class="lg:text-3xl"
            >{{ url[2] }}</DoiText
          ></DoiText
        >
        <DoiText
          :textColor="1"
          :fontFamily="5"
          class="absolute right-5 sm:hidden"
          >POS. :
          <DoiText type="span" :textColor="0" :fontFamily="5">{{
            url[2]
          }}</DoiText></DoiText
        >
      </div>
    </div>
    <div class="overflow-x-scroll mb-4">
      <DoiTableNew>
        <template slot="table-head">
          <DoiTableHead :bgColorTable="3" :fontFamily="4">POS.</DoiTableHead>
          <DoiTableHead :bgColorTable="3" :fontFamily="4" class="text-left"
            >JOUEUR</DoiTableHead
          >
          <DoiTableHead :bgColorTable="3" :fontFamily="4" class="text-left"
            >MATCHS JOUÉS</DoiTableHead
          >
          <DoiTableHead :bgColorTable="0" :fontFamily="4" class="text-left"
            >VICTOIRES</DoiTableHead
          >
          <DoiTableHead :bgColorTable="3" :fontFamily="4" class="text-left"
            >NULS</DoiTableHead
          >
          <DoiTableHead :bgColorTable="1" :fontFamily="4" class="text-left"
            >DÉFAITES</DoiTableHead
          >
          <DoiTableHead :bgColorTable="2" :fontFamily="4" class="text-left"
            >RATIO V</DoiTableHead
          >
        </template>
        <template slot="table-body">
          <DoiTableRow>
            <DoiTableCell
              :textColor="2"
              :fontSize="3"
              :fontFamily="2"
              :bgColorTable="2"
              >{{ url[3] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[4] }} {{ url[5] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[6]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[7]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[8]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[9]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[7], url[6])
            }}</DoiTableCell>
          </DoiTableRow>
          <DoiTableRow v-if="url[10] !== '' && url[10] !== undefined">
            <DoiTableCell
              :textColor="2"
              :fontSize="3"
              :fontFamily="2"
              :bgColorTable="5"
              >{{ url[10] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[11] }} {{ url[12] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[13]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[14]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[15]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[16]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[14], url[13])
            }}</DoiTableCell>
          </DoiTableRow>
          <DoiTableRow v-if="url[17] !== '' && url[17] !== undefined">
            <DoiTableCell
              :textColor="2"
              :fontSize="3"
              :fontFamily="2"
              :bgColorTable="4"
              >{{ url[17] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[18] }} {{ url[19] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[20]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[21]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[22]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[23]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[21], url[20])
            }}</DoiTableCell>
          </DoiTableRow>
          <tr
            class="dot-wrapper-row w-full h-10 relative"
            v-if="url[24] !== '' && url[24] !== undefined"
          >
            <td colspan="7" class="w-full">
              <div class="flex justify-center items-center w-full">
                <span class="dot"></span> <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </td>
          </tr>
          <DoiTableRow v-if="url[24] !== '' && url[24] !== undefined">
            <DoiTableCell :textColor="1" :fontSize="3" :fontFamily="2">{{
              url[24]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[25] }} {{ url[26] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[27]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[28]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[29]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[30]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[28], url[27])
            }}</DoiTableCell>
          </DoiTableRow>
          <DoiTableRow v-if="url[31] !== '' && url[31] !== undefined">
            <DoiTableCell :textColor="1" :fontSize="3" :fontFamily="2">{{
              url[31]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[32] }} {{ url[33] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[34]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[35]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[36]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[37]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[35], url[34])
            }}</DoiTableCell>
          </DoiTableRow>
          <DoiTableRow v-if="url[38] !== '' && url[38] !== undefined">
            <DoiTableCell :textColor="1" :fontSize="3" :fontFamily="2">{{
              url[38]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[39] }} {{ url[40] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[41]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[42]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[43]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[44]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[42], url[41])
            }}</DoiTableCell>
          </DoiTableRow>
          <DoiTableRow v-if="url[45] !== '' && url[45] !== undefined">
            <DoiTableCell :textColor="1" :fontSize="3" :fontFamily="2">{{
              url[45]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[46] }} {{ url[47] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[48]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[49]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[50]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[51]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[49], url[48])
            }}</DoiTableCell>
          </DoiTableRow>
          <DoiTableRow v-if="url[52] !== '' && url[52] !== undefined">
            <DoiTableCell :textColor="1" :fontSize="3" :fontFamily="2">{{
              url[52]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10"
              >{{ url[53] }} {{ url[54] }}</DoiTableCell
            >
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[55]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[56]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[57]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              url[58]
            }}</DoiTableCell>
            <DoiTableCell :textColor="1" :fontFamily="10">{{
              ratioV(url[56], url[55])
            }}</DoiTableCell>
          </DoiTableRow>
        </template>
      </DoiTableNew>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharePartie",
  data() {
    return {
      url: this.$route.params.code,
      length: Number,
    };
  },
  props: ["code"],
  mounted() {
    this.decode;
  },
  computed: {
    decode() {
      this.url = window.atob(this.url);
      this.url = this.url.split("&");
    },
  },
  methods: {
    ratioV(victoire, nbMatch) {
      return (victoire / nbMatch).toFixed("2");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.img-icon {
  width: 3.75rem;
  height: 3.75rem;
}

.dot-wrapper-row {
  border-bottom: 1px solid $doi--color-color4;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: $doi--color-color0;
  border-radius: 50%;
  margin-right: 0.5rem;
  display: inline-block;
}

@media (min-width: 320px) and (max-width: 639px) {
  .sport-label {
    font-size: $doi--text-font-size2;
  }
}
</style>
