<template>
  <div>
    <Modal
      :title="title"
      :open="open"
      size="xs"
      :keepratio="true"
      @close-modal="open = false"
    >
      <template slot="modal_content">
        <DoiText
          :textColor="1"
          :fontSize="3"
          :fontFamily="5"
          class="text-center"
          >{{ message }}</DoiText
        >
        <div class="flex justify-center mt-4">
          <DoiButton @click="goBack()">OK</DoiButton>
        </div>
      </template>
    </Modal>

    <div class="title-long-border"></div>
    <div
      class="mx-auto lg:max-w-5xl flex justify-center p-8 lg:p-20 flex-col items-center w-full lg:w-5/12"
    >
      <div class="py-8 flex flex-col items-center">
        <div class="flex flex-col items-center justify-center">
          <DoiHeader
            :level="4"
            :fontFamily="1"
            :headerColor="0"
            class="mb-4 text-center"
            >JE CRÉE MON COMPTE</DoiHeader
          >
          <DoiHeader
            :level="4"
            :fontFamily="0"
            :headerColor="2"
            class="tracking-widest text-center mt-2 mb-7"
            >BOOK, PLAY, SHARE !</DoiHeader
          >
        </div>
      </div>

      <div v-if="!isCordova()" class="flex lg:flex-row flex-col mb-4">
        <!-- <DoiButton :bgColor="4" :textColor="1" class="connect-btn lg:mb-0" @click="socialNetwork('facebook')"><img class="pr-4 w-11" src="@/assets/img/connection/facebook.png" alt="Logo Facebook" />Connexion avec Facebook</DoiButton> -->
        <!-- <DoiButton :bgColor="4" :textColor=  "1" class="connect-btn lg:mb-0" @click="socialNetwork('google')"><img class="pr-4 w-11" src="@/assets/img/connection/google.png" alt="Logo Google" />Connexion avec Google</DoiButton> -->
        <!-- <DoiButton :bgColor="4" :textColor="1" class="connect-btn lg:mb-0" @click="socialNetwork('apple')"><img class="pr-4 w-11" src="@/assets/img/connection/apple.png" alt="Logo Apple" />Connexion avec Apple</DoiButton> -->
      </div>

      <form @submit.prevent="submit" class="w-full" autocomplete="off">
        <div class="mb-4 mr-8 w-full">
          <DoiInput
            v-model="$v.nom.$model"
            :error="$v.nom.$error"
            placeholder="Nom"
          />
          <DoiText
            italic
            :textColor="0"
            v-if="!$v.nom.required && $v.nom.$error"
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="mb-4 w-full">
          <DoiInput
            v-model="$v.prenom.$model"
            :error="$v.prenom.$error"
            placeholder="Prénom"
          />
          <DoiText
            italic
            :textColor="0"
            v-if="!$v.prenom.required && $v.prenom.$error"
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="mb-4">
          <input
            maxlength="12"
            required
            v-model="$v.nom_joueur.$model"
            :error="$v.nom_joueur.$error"
            placeholder="Pseudonyme"
            class="doi-input"
          />
          <DoiText
            italic
            :textColor="0"
            v-if="!$v.nom_joueur.required && $v.nom_joueur.$error"
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="mb-4">
          <DoiInput
            type="email"
            v-model="$v.email.$model"
            :error="$v.email.$error"
            placeholder="Email"
          />
          <DoiText
            italic
            :textColor="0"
            v-if="!$v.email.required && $v.email.$error"
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="mb-4">
          <DoiInput
            type="password"
            v-model="$v.mot_de_passe.$model"
            :error="$v.mot_de_passe.$error"
            placeholder="Mot de passe"
          />
          <DoiText
            italic
            :textColor="0"
            v-if="!$v.mot_de_passe.required && $v.mot_de_passe.$error"
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="mb-4">
          <DoiInput
            :error="$v.confirmation_mot_de_passe.$error"
            type="password"
            v-model="$v.confirmation_mot_de_passe.$model"
            placeholder="Confirmer votre mot de passe"
          />
          <DoiText
            italic
            :textColor="0"
            :fontSize="2"
            v-if="
              !$v.confirmation_mot_de_passe.required &&
              $v.confirmation_mot_de_passe.$error
            "
            >Ce champ est requis</DoiText
          >
          <DoiText
            italic
            :textColor="0"
            :fontSize="2"
            v-if="
              !$v.confirmation_mot_de_passe.sameAsPassword &&
              $v.confirmation_mot_de_passe.$error
            "
            >Les mots de passe ne concordent pas</DoiText
          >
        </div>
        <div class="mt-10">
          <div class="mb-4" :class="{ 'form-group--error': $v.cgu.$error }">
            <input
              type="checkbox"
              v-model="$v.cgu.$model"
              class="form-tick h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
              id="cgu"
              @change="handleChange()"
            />
            <label for="cgu" class="label-cgu">
              <DoiText :textColor="3" :fontFamily="5" class="ml-6 -mt-5"
                >En validant mon formulaire d'inscription, je déclare avoir lu
                et accepté les
                <router-link to="/rgpd" class="red underline" target="_blank"
                  >Conditions Générales d'Utilisation</router-link
                >
                de la plateforme et je consens au traitement de mes données
                conformément à la
                <router-link
                  to="/politique-de-confidentialite"
                  class="red underline"
                  target="_blank"
                  >Politique de Confidentialité</router-link
                >
                pour l'ouverture et la gestion de mon compte.</DoiText
              >
            </label>
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!$v.cgu.required && $v.cgu.$error"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="mb-4">
            <input
              type="checkbox"
              v-model="$v.infos_com.$model"
              class="form-tick h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
              id="infos_com"
              @change="handleChange()"
            />
            <label for="infos_com" class="label-infos-com">
              <DoiText :textColor="3" :fontFamily="5" class="ml-2"
                >Je souhaite recevoir des informations par email concernant les
                offres, services, produits et évènements de FIT ARENA et de ses
                partenaires conformément à la
                <router-link
                  to="/politique-de-confidentialite"
                  class="red underline"
                  target="_blank"
                  >Politique de Confidentialité</router-link
                >. Vous pouvez vous désinscrire à tout moment sur votre espace
                en ligne ou en cliquant sur le lien « Se désinscrire » qui se
                trouve en bas de toutes nos communications par email.</DoiText
              >
            </label>
          </div>
          <div class="mb-4" :class="{ 'form-group--error': $v.age.$error }">
            <input
              type="checkbox"
              v-model="$v.age.$model"
              class="form-tick h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
              id="age"
              @change="handleChange()"
            />
            <label for="age" class="label-age">
              <DoiText :textColor="3" :fontFamily="5" class="ml-2"
                >J'atteste que j'ai plus de 16 ans.</DoiText
              >
            </label>
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!$v.age.required && $v.age.$error"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="mb-4">
            <DoiText :textColor="3" :fontFamily="5"
              >Pour en savoir plus sur la gestion de vos données personnelles et
              pour exercer vos droits, reportez-vous à la notice
              <router-link
                to="/politique-de-confidentialite"
                class="red underline"
                target="_blank"
                >ci-jointe.</router-link
              ></DoiText
            >
          </div>
        </div>
        <div class="flex justify-center mt-10">
          <DoiButton
            :disabled="!this.age"
            id="validateRegistration"
            class="mx-1"
            :fontFamily="6"
            :bgHover="1"
          >
            VALIDER
            <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
          </DoiButton>
        </div>
      </form>
    </div>
    <div class="bg-gray">
      <div
        class="mx-auto lg:max-w-2k flex justify-center p-8 lg:p-10 flex-col items-center w-full lg:w-5/12"
      >
        <DoiHeader
          :level="5"
          :fontFamily="1"
          :headerColor="0"
          class="md:title-border mb-4"
          >J'ai déjà un compte</DoiHeader
        >
        <div class="flex justify-center mt-4">
          <DoiButton link="/signin" :bgColor="2">M'IDENTIFIER</DoiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import { registerV2 } from "@/api/AuthService";
import Modal from "@/components/molecules/Modal";

export default {
  name: "SignUp",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Page d'inscription du site fit-arena.fr. Réservations des salles multisports connectée, intelligente et autonome. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.fit-arena.fr/signup" }],
  },
  data() {
    return {
      nom: "",
      prenom: "",
      nom_joueur: "",
      telephone: "",
      email: "",
      mot_de_passe: "",
      confirmation_mot_de_passe: "",
      cgu: false,
      infos_com: false,
      age: false,
      showSpinner: false,
      open: false,
      message: "",
      title: "",
      link: "",
    };
  },
  components: { Modal },
  validations: {
    nom: { required },
    prenom: { required },
    nom_joueur: { required },
    telephone: {},
    email: { required },
    mot_de_passe: { required },
    confirmation_mot_de_passe: {
      required,
      sameAsPassword: sameAs("mot_de_passe"),
    },
    cgu: { sameAs: sameAs(() => true) },
    infos_com: {},
    age: { sameAs: sameAs(() => true) },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) this.showSpinner = false;
      else this.subscriptionForm();
    },
    handleChange() {},
    async subscriptionForm() {
      this.showSpinner = true;

      const response = await registerV2({
        actif: true,
        email: this.email,
        idParent: 0,
        identifiant: this.nom_joueur,
        mineur: false,
        nom: this.nom,
        plainPassword: this.mot_de_passe,
        portable: this.telephone,
        prenom: this.prenom,
        infosCom: this.infos_com,
        responsable: true,
      });

      if (response === undefined) {
        this.open = true;
        this.title = "Erreur lors de l'inscription";
        this.message = "Pseudo ou email déjà utilisé";
      } else {
        this.open = true;
        this.title = "Validation de votre inscription";
        this.message =
          "Votre compte a bien été enregistré, un email contenant un lien va vous être envoyé pour confirmer votre inscription, veuillez cliquer sur ce lien pour valider votre inscription.";
        this.link = "/signin";
      }
      this.showSpinner = false;
    },
    goBack() {
      if (this.link !== "") this.$router.push(this.link);
      // inscription OK
      else this.open = false; // inscription NOK
      this.link = "";
    },
    isCordova() {
      return window.navigator.userAgent.includes("cordova");
    },
    async socialNetwork(social) {
      window.open(
        `${process.env.VUE_APP_BACK}admin/authentification/reseau-sociaux?social=${social}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red {
  color: $doi--color-color0;
  font-family: $doi--text-font-family6;
}

.title-long-border {
  content: "";
  height: 2px;
  width: calc(50% - 250px);
  background-color: $doi--color-color0;
  position: relative;
  top: 130px;
}

.label-infos-com,
.label-age {
  overflow: hidden;
  display: flex;
  margin-left: 15px;
  top: 0;
  position: relative;
  margin-top: -20px;
}

.bg-gray {
  background: rgb(232, 232, 232);
}

.title-border {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.title-border::before {
  content: "";
  width: 400px;
  height: 1px;
  display: block;
  background-color: rgb(207, 207, 207);
  margin-right: 25px;
}
.title-border::after {
  content: "";
  width: 400px;
  height: 1px;
  display: block;
  background-color: rgb(207, 207, 207);
  margin-left: 25px;
}

.connect-btn {
  border: 1px solid $doi--button-color2;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.doi-input {
  width: 100%;
  padding: 10px 7px;
  border-bottom: solid 0.13rem rgb(225, 225, 225);
  background-color: transparent;
}
.doi-input::placeholder {
  color: $doi--color-color4;
  font-family: $doi--text-font-family6;
  font-size: 0.9rem;
}
.doi-input:focus {
  outline: none;
}
</style>
