export default {
  props: {
    headerColor: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    headerColorStyle() {
      if (typeof this.headerColor === "number")
        return " header-color" + this.headerColor;
    },
  },
};
