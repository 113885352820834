<template>
  <span
    class="doi-svg"
    :style="strokeStyle + fillStyle"
    v-html="require(`!!raw-loader!@/assets/icons/utils/${path}`).default"
  ></span>
</template>

<script>
export default {
  name: "DoiSvg",
  props: {
    path: String,
    fill: {
      type: String,
      default: "transparent",
    },
    stroke: {
      type: String,
      default: "white",
    },
  },
  computed: {
    strokeStyle() {
      return "stroke:" + this.stroke + ";";
    },
    fillStyle() {
      return "fill:" + this.fill + ";";
    },
  },
};
</script>

<style scoped>
.doi-svg {
  width: 100%;
  align-items: center;
  height: 100%;
}

.doi-svg > svg {
  display: block;
  margin: 0 auto;
  text-align: center;
}
</style>
