<template>
  <h3 class="title3">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: "H3Article",
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.title3 {
  font-size: $doi--header6;
  font-family: $doi--text-font-family2;
  color: $doi--header-color1;
  margin-bottom: 8px;
}
</style>
