<template>
  <section>
    <div class="flex justify-center">
      <img src="@/assets/img/Page_404.jpg" alt="Page non trouvée" />
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
