<template>
  <div class="mb-10">
    <nav class="w-full" :class="floating ? 'absolute' : 'relative'">
      <div class="relative pb-4">
        <img :src="fitarenaImg" class="entete-bg" />
        <HeaderConnectDefault @onOpenCart="openCartRecap = true" />
      </div>
    </nav>
    <div class="container mx-auto lg:max-w-6xl flex h-full items-start">
      <div class="bg-white flex flex-col h-full w-full">
        <div class="flex flex-col">
          <router-link to="/" class="items-center hidden lg:flex">
            <img src="@/assets/img/back-v2.png" alt="retour page precedente" />
            <DoiText
              type="span"
              class="ml-2"
              :textColor="3"
              :fontFamily="5"
              :fontSize="3"
              >RETOUR</DoiText
            >
          </router-link>
          <div class="pt-6 px-4 uppercase">
            <DoiText :textColor="1" :fontFamily="1" :fontSize="5"
              >{{ fitArena.libelleSalle }} -
              <DoiText
                type="span"
                :textColor="1"
                :fontFamily="0"
                :fontSize="5"
                >{{ fitArena.adresse.commune }}</DoiText
              ></DoiText
            >
          </div>
        </div>
        <div class="card-info mt-4 rounded-lg mx-4">
          <div class="flex py-2 px-6 justify-between items-center">
            <DoiText :textColor="0" :fontFamily="5" :fontSize="4"
              >INFORMATIONS</DoiText
            >
            <div @click="openModal = true" class="cursor-pointer">
              <img src="@/assets/img/picto-gallery.png" />
            </div>
          </div>
          <div class="divider" />
          <div class="flex py-4 px-6 items-center">
            <img src="@/assets/img/localisation.svg" class="w-3" />
            <DoiText :textColor="1" :fontFamily="4" :fontSize="3" class="ml-4"
              >{{ fitArena.adresse.rue }},
              {{ fitArena.adresse.complement }}
              {{ fitArena.adresse.codePostal }}
              {{ fitArena.adresse.commune }}</DoiText
            >
          </div>
          <template v-if="servicesAvailable">
            <div class="flex flex-wrap py-4 px-6">
              <div
                v-for="service in fitArena.services"
                class="w-1/2 sm:w-4/12 flex items-center"
              >
                <img :src="serviceIcon(service.urlIcone)" class="w-4" />
                <DoiText
                  :textColor="1"
                  :fontFamily="4"
                  :fontSize="3"
                  class="ml-4"
                  >{{ service.libelle }}</DoiText
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Modal
      title="VOTRE FIT ARENA EN IMAGES"
      :displayHeader="false"
      :open="openModal"
      size="xs"
      :keepratio="true"
      @close-modal="openModal = false"
    >
      <template slot="modal_content">
        <div class="block">
          <div
            @click="openModal = false"
            class="items-center w-3/12 cursor-pointer mb-4 hidden lg:flex"
          >
            <img src="@/assets/img/back-v2.png" alt="retour page precedente" />
            <DoiText
              type="span"
              class="ml-2"
              :textColor="3"
              :fontFamily="5"
              :fontSize="3"
              >RETOUR</DoiText
            >
          </div>
          <DoiText :textColor="1" :fontFamily="0" :fontSize="4"
            >VOTRE FIT ARENA EN IMAGES</DoiText
          >
          <carousel
            v-if="this.$route.path === '/fit-arena/padel-park'"
            id="FA-carousel"
            :navigationEnabled="true"
            navigationPrevLabel="&#10140;"
            navigationNextLabel="&#10140;"
            :perPage="1"
          >
            <slide v-for="(image, i) in photoListPadelPark" :key="i">
              <div class="fa-slider">
                <div class="fa-slider-img">
                  <img class="max-w-xs" :alt="image.alt" :src="image.img" />
                </div>
              </div>
            </slide>
          </carousel>
          <carousel
            v-else-if="this.$route.path === '/fit-arena/fitarena-chateauroux'"
            id="FA-carousel"
            :navigationEnabled="true"
            navigationPrevLabel="&#10140;"
            navigationNextLabel="&#10140;"
            :perPage="1"
          >
            <slide v-for="(image, i) in photoListCha" :key="i">
              <div class="fa-slider">
                <div class="fa-slider-img">
                  <img class="max-w-xs" :alt="image.alt" :src="image.img" />
                </div>
              </div>
            </slide>
          </carousel>
          <carousel
            v-else
            :navigationEnabled="true"
            navigationPrevLabel="&#10140;"
            id="FA-carousel"
            navigationNextLabel="&#10140;"
            :perPage="1"
          >
            <slide v-for="(image, i) in photoList" :key="i">
              <div class="fa-slider">
                <div class="fa-slider-img">
                  <img class="max-w-xs" :alt="image.alt" :src="image.img" />
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import imageA from "@/assets/img/mock/listePhotos/FITA_A.png";
import imageD from "@/assets/img/mock/listePhotos/FITA_D.png";
import imageF from "@/assets/img/mock/listePhotos/FITA_F.png";
import imageG from "@/assets/img/mock/listePhotos/FITA_G.png";
import imageH from "@/assets/img/mock/listePhotos/FITA_H.png";

import image1 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon.jpg";
import image2 from "@/assets/img/mock/listePhotos/SMC2_Terrain_PadelPark_Dijon_.jpg";
import image3 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-10.jpg";
import image4 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia.jpg";
import image5 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-15.jpg";
import image6 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-17.jpg";
import image7 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-18.jpg";
import image8 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-20.jpg";
import image9 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-34.jpg";
import image10 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-38.jpg";
import image11 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-39.jpg";
import image12 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-53.jpg";
import image13 from "@/assets/img/mock/listePhotos/SMC2_PadelPark_Dijon_YoanJeudy_Andia-57.jpg";

import image1CHA from "@/assets/img/mock/listePhotos/CHA_sport-halle_F.jpg";

import HeaderConnectDefault from "@/components/organisms/HeaderConnectDefault";
import Modal from "@/components/molecules/Modal";

import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "FitArenaHeader",
  data() {
    return {
      floating: false,
      openCartRecap: false,
      openModal: false,
      link: "",
      rue: "",
      commune: "",
      photoList: [
        { img: imageA, alt: "Fit Arena" },
        { img: imageD, alt: "Fit Arena" },
        { img: imageF, alt: "Fit Arena" },
        { img: imageG, alt: "Fit Arena" },
        { img: imageH, alt: "Fit Arena" },
      ],
      photoListPadelPark: [
        { img: image1, alt: "Padel Park" },
        { img: image2, alt: "Padel Park" },
        { img: image3, alt: "Padel Park" },
        { img: image4, alt: "Padel Park" },
        { img: image5, alt: "Padel Park" },
        { img: image6, alt: "Padel Park" },
        { img: image7, alt: "Padel Park" },
        { img: image8, alt: "Padel Park" },
        { img: image9, alt: "Padel Park" },
        { img: image10, alt: "Padel Park" },
        { img: image11, alt: "Padel Park" },
        { img: image12, alt: "Padel Park" },
        { img: image13, alt: "Padel Park" },
      ],
      photoListCha: [{ img: image1CHA, alt: "Châteauroux" }],
    };
  },
  props: {
    fitArena: Object,
  },
  components: {
    HeaderConnectDefault,
    Modal,
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters("FitArenaListModule", ["fitArenaList"]),
    fitArenaRue() {
      if (typeof this.fitArena.adresse.rue === "string") {
        return this.fitArena.adresse.rue
          ? this.fitArena.adresse.rue + ", "
          : "";
      }
      return "";
    },
    fitArenaAdresse() {
      if (
        this.fitArena.adresse &&
        typeof this.fitArena.adresse.codePostal === "string" &&
        typeof this.fitArena.adresse.commune === "string"
      ) {
        return (
          this.fitArenaRue +
          this.fitArena.adresse.codePostal +
          "" +
          this.fitArena.adresse.commune
        );
      }
      return "";
    },
    classObject() {
      return {
        "lg:h-96": this.$route.path !== "/fit-arena/padel-park",
        "h-header": this.$route.path === "/fit-arena/padel-park",
      };
    },
    fitarenaImg() {
      if (
        this.fitArena.hasOwnProperty("urlBandeau") &&
        this.fitArena.urlBandeau !== ""
      )
        return generateImageUrlV2(this.fitArena.urlBandeau);
      return require("@/assets/img/presentation/FitArenaDefault.png");
    },
    servicesAvailable() {
      return (
        this.fitArena.hasOwnProperty("services") &&
        Object.keys(this.fitArena.services).length !== 0
      );
    },
  },
  methods: {
    serviceIcon(img) {
      return generateImageUrlV2(img);
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.divider {
  height: 1px;
  background-color: $doi--color-color0;
}
.card-info {
  background-color: #fcfcfc;
  border: 1px solid #e9e9e9;
}

.menu-separator {
  display: flex;
  flex: 1;
  border: solid 1px $doi--color-color0;
  height: 1px;
}

.font-menu-list {
  color: $doi--color-color4;
  font-family: $doi--text-font-family2;
}

.font-service-list {
  font-family: $doi--text-font-family5;
}

.icon-sport-tennis {
  color: $doi--color-color0;
}

.entete-bg {
  background-position: 0% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  border-bottom: 15px solid $doi--color-color0;
}

#FA-carousel .VueCarousel-navigation-prev {
  transform: rotateY(180deg) translateY(-50%) translateX(100%) !important;
}

#FA-carousel .VueCarousel-navigation-button {
  display: none;
}

#FA-carousel .VueCarousel-dot {
  background: white !important;
  border-radius: 50px !important;
  padding: 0px !important;
  margin: 0.5rem !important;
  margin-bottom: 1rem !important;
  outline: 1px solid $doi--color-color0;
  outline-offset: 1px;
}

#FA-carousel .VueCarousel-dot--active {
  background: $doi--color-color0 !important;
}

.fa-slider {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
}

.fa-slider-img {
  display: flex;
  justify-content: center;
}

@media (min-width: $doi-breakpoint-desktop) {
  .h-header {
    height: 18rem;
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .logo {
    display: none;
  }
}
</style>
