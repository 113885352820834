<template>
  <div
    class="flex z-40 px-4 py-2 rounded-lg shadow-md"
    :class="
      floating
        ? 'fixed top-2 right-2 bg-white'
        : 'absolute top-8 right-6 bg-padel'
    "
  >
    <div class="lg:mb-0 mr-6 h-4 justify-between relative">
      <div v-if="floating">
        <router-link
          v-if="
            this.$route.path.includes('fit-arena/') ||
            this.$route.path.includes('/cart')
          "
          to="/"
          class="block lg:hidden"
        >
          <div
            class="w-8 h-8 bg-arrow-back rounded-full flex items-center justify-center fixed top-2 left-2 bg-white"
          >
            <img
              src="@/assets/img/arrow-back.svg"
              alt="retour page précédente"
            />
          </div>
        </router-link>
        <router-link to="/cart">
          <span
            class="cursor-pointer bg-black w-9 h-9 rounded-full flex items-center justify-center relative"
          >
            <img src="@/assets/icons/common/cart.png" />
            <span
              class="absolute top-0 shadow -right-1 rounded-full w-4 h-4 text-xs text-white flex items-center justify-center bg-fa-red"
              >{{ getCartLength }}</span
            >
          </span>
        </router-link>
      </div>
      <div class="lg:hidden" v-if="!floating">
        <router-link
          v-if="
            this.$route.path.includes('fit-arena/') ||
            this.$route.path.includes('/cart')
          "
          to="/"
        >
          <div
            class="w-8 h-8 bg-arrow-back rounded-full flex items-center justify-center fixed top-10 left-4 bg-padel"
          >
            <img
              src="@/assets/img/arrow-back.svg"
              alt="retour page précédente"
            />
          </div>
        </router-link>
        <router-link to="/cart">
          <span
            class="cursor-pointer bg-black w-9 h-9 rounded-full flex items-center justify-center relative"
          >
            <img src="@/assets/icons/common/cart.png" />
            <DoiText class="hidden lg:inline-block">PANIER</DoiText>
            <span
              v-if="getCartLength > 0"
              class="absolute top-0 shadow -right-1 rounded-full w-4 h-4 text-xs text-white flex items-center justify-center bg-fa-red"
              >{{ getCartLength }}</span
            >
          </span>
        </router-link>
      </div>
      <div v-if="!floating" class="hidden lg:block">
        <router-link to="/cart">
          <span
            class="button-panier cursor-pointer px-2 py-1 md:px-8 text-white h-11 4k:p-16"
          >
            <img
              class="mr-2 cart-img 4k:mr-6"
              src="@/assets/icons/common/cart.svg"
            />
            <DoiText class="hidden md:inline-block">PANIER</DoiText>
            <span
              v-if="getCartLength > 0"
              class="absolute flex items-center justify-center -top-1 shadow -right-1 rounded-full w-4 h-4 text-xs lg:text-white text-fa-red lg:bg-fa-red bg-white"
              >{{ getCartLength }}</span
            >
          </span>
        </router-link>
      </div>
    </div>
    <div v-if="pending" class="flex items-center">
      <DoiSpinner :show="pending" />
    </div>
    <div v-else-if="!is_connected" class="flex justify-between">
      <div class="mr-4 hidden lg:flex">
        <DoiButton link="/signin" :bgColor="2" :fontFamily="5"
          >CONNEXION</DoiButton
        >
      </div>
      <div class="hidden lg:flex">
        <DoiButton link="/signup" :fontFamily="5">INSCRIPTION</DoiButton>
      </div>
      <div class="flex items-center lg:hidden">
        <div @click="vGoToDashboard()">
          <DoiImage
            :rounded="true"
            size="2.2rem"
            :src="require('@/assets/img/no-avatar.png')"
            class="cursor-pointer"
          />
        </div>
        <div @click="sidebar = true">
          <img
            v-if="!floating"
            width="25"
            src="@/assets/img/menu-black.png"
            class="ml-8 cursor-pointer"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="header-connect-profil flex items-center cursor-pointer lg:px-2 lg:py-1 icon no-padding"
      :class="floating ? '' : ''"
    >
      <div :class="floating ? '' : 'lg:mr-2'" @click="vGoToDashboard()">
        <DoiImage :rounded="true" size="2.2rem" :src="userAvatar" />
      </div>
      <div @click="sidebar = true" class="lg:hidden">
        <img
          v-if="!floating"
          width="25"
          src="@/assets/img/menu-black.png"
          class="ml-8 cursor-pointer"
        />
      </div>
    </div>
    <SidebarPadel :open="sidebar" @closeSidebar="sidebar = false" />
  </div>
</template>

<script>
import SidebarPadel from "@/views/holding/components/SidebarPadel";
import { mapActions, mapGetters } from "vuex";
import { generateImageUrl } from "@/utils/imgUrlBuilder";

export default {
  data() {
    return {
      pending: false,
      userInfo: {
        sNom: "",
        sPrenom: "",
        sAvatar: "",
      },
      floating: false,
      sidebar: false,
    };
  },
  components: { SidebarPadel },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    const contactId = this.$store.getters["UserModule/get_contact_id"];
    if (contactId !== undefined && contactId !== null) this.getProfileInfo();

    this.$store.dispatch("CartModule/INIT_CART2");
  },
  methods: {
    ...mapActions("UserModule", ["GET_PROFIL_INFO"]),
    openResponsiveSidebar() {
      this.$emit("responsiveSidebar");
    },
    handleScroll() {
      this.floating = window.scrollY > 50 ? true : false;
    },
    async getProfileInfo() {
      this.pending = true;
      await this.GET_PROFIL_INFO();
      this.pending = false;
    },
    vGoToDashboard() {
      this.$router.push("/profile?q=profile");
    },
  },
  computed: {
    ...mapGetters("UserModule", ["get_user"]),
    ...mapGetters("AuthModule", ["is_connected"]),
    isOnPaymentPage() {
      return this.$route.name === "paymentPage";
    },
    getUserInfo() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
    getCartLength() {
      return this.$store.getters["CartModule/getCartLength"];
    },
    userAvatar() {
      if (
        this.get_user &&
        this.get_user.avatar !== "" &&
        this.get_user.avatar !== null
      ) {
        return generateImageUrl(this.get_user.avatar);
      }
      return require("@/assets/img/no-avatar.png");
    },
  },
  watch: {
    is_connected(value) {
      if (!value) this.pending = false;
    },
    get_user() {
      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.button-panier {
  background-color: #000;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 4px;
}

.bg-padel {
  background-color: $doi-bg-lightgrey-padel;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

@media (min-width: $doi-breakpoint-desktop) {
  .header-connect-profil {
    background: $doi-bg-gray-color;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .bg-padel {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: $doi-breakpoint-large-screen) {
  .button-panier,
  .button.gray,
  .button.red {
    font-size: 1rem;
  }
}

@media (min-width: $doi-breakpoint-extra-large-screen) {
  .button-panier,
  .button.gray,
  .button.red {
    font-size: 3rem;
  }

  .cart-img {
    width: 3rem;
  }
}
</style>
