export default {
  props: {
    fontFamily: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    fontFamilyStyle() {
      if (typeof this.fontFamily === "number")
        return " font-family" + this.fontFamily;
    },
  },
};
