import {
  cartUpdate2,
  getCart2,
  cartDelete2,
  cartValidate2,
} from "@/api/CartService";

import Vue from "vue";
import router from "@/router/index.js";

const initState = () => {
  return {
    isFetched: false,
    isFetching: false,
    cart: {
      uuid: "",
      contenu: [],
      tarif: 0,
    },
  };
};

export default {
  namespaced: true,
  namespace: "cart",
  state: initState(),
  actions: {
    async ADD_TO_CART2({ commit }, { data, idSport }) {
      commit("setFetchedDirty");
      const dataToSend = {
        nIdSport: idSport,
        nIdCreneau: data.nIdCreneau,
        nIdZone: data.oFitArena.nIdZone,
      };

      const response = await cartUpdate2(dataToSend);

      if (response !== undefined) {
        if (response._type === "succes") {
          if (screen.width > 640) {
            Vue.$toast.success(`${response._message}`, {
              onClick: () => router.push("/cart"),
            });
          } else {
            Vue.$toast.success(`${response._message}`, {
              position: "top",
              onClick: () => router.push("/cart"),
            });
          }
        }
        if (response._type === "erreur") {
          if (screen.width > 640) {
            Vue.$toast.error(`${response._message}`);
          } else {
            Vue.$toast.error(`${response._message}`, {
              position: "top",
            });
          }
          if (
            response._message ===
            "Vous n'êtes pas autorisé à réserver dans cette Fit Arena"
          ) {
            this.$router.push("/#fit-arena-list");
          }
        }
        if (response._type === "info") {
          if (screen.width > 640) {
            Vue.$toast.warning(`${response._message}`);
          } else {
            Vue.$toast.warning(`${response._message}`, {
              position: "top",
            });
          }
        }
        commit("INIT_CART", response);
      }
      commit("setFetchFinish");
    },
    async INIT_CART2({ commit, rootGetters }) {
      commit("setIsFetchingTrue");
      if (rootGetters["AuthModule/is_connected_2"]) {
        const data = await getCart2();
        if (data?.contenu) {
          commit("INIT_CART", data);
        }
      }
      commit("setFetchFinish");
    },
    async deleteCartElement2({ commit, dispatch }, id) {
      commit("setFetchedDirty");
      await cartDelete2(id);
      dispatch("INIT_CART2");
      commit("setFetchFinish");
    },
    async validateCart2({ commit }, shareBill) {
      commit("setFetchedDirty");
      const data = await cartValidate2(shareBill);
      if (data?.bSucces) return data;
      else {
        const message =
          "Une erreur est survenue lors de la validation du panier";
        if (screen.width > 640) {
          Vue.$toast.error(message);
        } else {
          Vue.$toast.error(message, {
            position: "top",
          });
        }
        commit("setFetchError");
      }
      commit("setFetchFinish");
    },
    CART_TRANSFORM({ commit }) {
      commit("CART_TRANSFORM");
    },
    resetInfo({ commit }) {
      commit("resetInfo");
    },
  },
  getters: {
    isCartLoading: (state) => {
      return !state.isFetched;
    },
    isCartFetching: (state) => {
      return state.isFetching;
    },
    get_cart: (state) => {
      return state.cart;
    },
    getCartLength: (state) => {
      return state.cart.contenu.length || 0;
    },
    get_total_cart: (state) => {
      return state.length > 0
        ? state.reduce((a, b) => a + parseFloat(b.sTarif), 0)
        : 0;
    },
  },
  mutations: {
    ADD_TO_CART: (state, payload) => {
      state.cart = [...state.cart, payload];
    },
    INIT_CART: (state, payload) => {
      state.cart = payload;
    },
    setFetchError: (state) => {
      state.isFetched = true;
      state.isFetching = false;
    },
    setFetchFinish: (state) => {
      state.isFetched = true;
      state.isFetching = false;
    },
    setIsFetchingTrue: (state) => {
      state.isFetching = true;
    },
    setFetchedDirty: (state) => {
      state.isFetched = false;
      state.isFetching = false;
    },
    resetInfo: (state) => {
      Object.assign(state, initState());
    },
  },
};
