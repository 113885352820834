<template>
  <Modal
    title="MOYEN DE PAIEMENT"
    :open="open"
    size="xs"
    :keepratio="true"
    @close-modal="closeModal"
  >
    <template slot="modal_content">
      <div v-if="formToken === ''" class="flex justify-center">
        <DoiSpinner :spinnerSize="1" />
      </div>
      <div v-else class="flex justify-center mt-4">
        <div>
          <PaymentForm
            @close="closePaymentForm"
            :formToken="formToken"
            :v2="v2"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import PaymentForm from "@/views/reglements/PaymentForm";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentModal",
  props: {
    open: Boolean,
    v2: String,
  },
  components: {
    Modal,
    PaymentForm,
  },
  computed: {
    ...mapGetters("PaymentTokenModule", ["formToken"]),
    ...mapGetters("UserModule", ["get_contact_id"]),
  },
  methods: {
    ...mapActions("PaymentTokenModule", [
      "retrieveFormToken",
      "retrieveFormTokenV2",
      "resetStore",
    ]),
    closeModal() {
      this.$emit("close");
    },
    closePaymentForm() {
      this.closeModal();
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.resetStore();
        if (this.v2 === "false") this.retrieveFormToken();
        else this.retrieveFormTokenV2(process.env.VUE_APP_API_URL_V2);
      }
    },
  },
};
</script>
