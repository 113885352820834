<template>
  <div class="flex flex-col w-full mt-6">
    <GymPresentationPanel img="img/FITA_H.png" img_position="left">
      <DoiHeader
        :level="2"
        :headerColor="0"
        class="mb-4 text-3xl sm:ml-6 xl:ml-16 2k:text-4xl 2k:ml-20 4k:text-7xl 4k:ml-40"
        >L'ACCUEIL</DoiHeader
      >
      <ul
        class="text-black sm:ml-6 sm:mr-2 md:ml-12 xl:ml-16 2k:mt-14 2k:ml-20 4k:ml-40"
      >
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Je consulte l'écran</DoiText
            >
            pour voir si mes partenaires de jeu sont arrivés.</DoiText
          >
        </li>
        <br />
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Je consulte le plan de la salle</DoiText
            >
            avec les consignes de sécurité.</DoiText
          >
        </li>
      </ul>
    </GymPresentationPanel>
    <GymPresentationPanel img="img/FITA_C.png" img_position="right">
      <DoiHeader
        :level="2"
        :headerColor="0"
        class="mb-4 text-3xl sm:ml-6 xl:ml-14 2k:text-4xl 2k:ml-20 4k:text-7xl 4k:ml-40"
        >ZONE D'ATTENTE</DoiHeader
      >
      <ul
        class="text-black sm:ml-6 sm:mr-2 md:ml-6 xl:ml-14 2k:mt-14 2k:ml-20 4k:ml-40"
      >
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Distributeurs</DoiText
            >
            de matériel sportif (ballons, raquettes, volants de badminton...) et
            de chasubles à ma disposition.</DoiText
          >
        </li>
        <br />
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Vestiaires</DoiText
            >
            je laisse mes affaires dans les casiers ou je les emporte avec moi
            pour les ranger près du terrain, sous le banc de touche. Je pense à
            <DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >m'équiper de chaussures de sport propres adaptées</DoiText
            >
            à une pratique en intérieur.</DoiText
          >
        </li>
        <br />
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Un écran à l'entrée de la zone de jeu</DoiText
            >
            m'informe du début de ma session.</DoiText
          >
        </li>
      </ul>
    </GymPresentationPanel>
    <GymPresentationPanel img="img/FITA_E.png" img_position="left">
      <DoiHeader
        :level="2"
        :headerColor="0"
        class="mb-4 text-3xl sm:ml-6 xl:ml-16 2k:text-4xl 2k:ml-20 4k:text-7xl 4k:ml-40"
        >UN TERRAIN MODULABLE ET AUTOMATISÉ</DoiHeader
      >
      <DoiText
        :textColor="1"
        :fontSize="3"
        :fontFamily="4"
        class="mb-8 sm:ml-6 sm:mr-2 md:ml-12 xl:ml-16 2k:text-xl 2k:mt-14 2k:ml-20 4k:text-5xl 4k:leading-loose 4k:ml-40"
        >La salle
        <DoiText
          type="span"
          :textColor="1"
          :fontSize="3"
          class="2k:text-xl 4k:text-5xl"
          >se configure automatiquement avec mes équipements sportifs</DoiText
        >
        selon le sport que j'ai réservé.</DoiText
      ><br />
      <ul
        class="text-black sm:ml-6 sm:mr-2 md:ml-12 xl:ml-16 2k:ml-20 4k:ml-40"
      >
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Des tablettes tactiles</DoiText
            ><br />Pour composer mes équipes, pour inscrire les scores et pour
            renseigner les temps forts.</DoiText
          >
        </li>
        <br />
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Des caméras</DoiText
            ><br />Reliées aux tablettes, elles filment la partie et
            enregistrent les vidéos de mes actions de jeu (buts, fautes, temps
            forts...).</DoiText
          >
        </li>
        <br />
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Un écran géant</DoiText
            ><br />Il retransmet le score en direct et indique le temps de jeu.
            Il me prévient quand ma session est bientôt terminée.</DoiText
          >
        </li>
      </ul>
    </GymPresentationPanel>
    <GymPresentationPanel img="img/FITA_H.png" img_position="right">
      <DoiHeader
        :level="2"
        :headerColor="0"
        class="mb-4 text-3xl sm:ml-6 xl:ml-14 2k:text-4xl 2k:ml-20 4k:text-7xl 4k:ml-40"
        >CORNER APRÈS MATCH</DoiHeader
      >
      <ul
        class="text-black sm:ml-6 sm:mr-2 md:ml-6 xl:ml-14 2k:mt-14 2k:ml-20 4k:ml-40"
      >
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            ><DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >Je consulte</DoiText
            >
            ma feuille de match, mes vidéos et mes statistiques.</DoiText
          >
        </li>
        <br />
        <li class="flex items-start">
          <img src="@/assets/icons/common/arrow-right.svg" />
          <DoiText
            :textColor="1"
            :fontSize="3"
            :fontFamily="4"
            class="ml-2 2k:text-xl 4k:text-5xl 4k:leading-loose 4k:ml-10"
            >Je débriefe avec mes amis et
            <DoiText
              type="span"
              :textColor="1"
              :fontSize="3"
              class="2k:text-xl 4k:text-5xl"
              >je revis les meilleurs moments</DoiText
            >
            de notre partie.</DoiText
          >
        </li>
      </ul>
    </GymPresentationPanel>
  </div>
</template>

<script>
import GymPresentationPanel from "./GymPresentationPanel";

export default {
  name: "GymPresentation",
  components: {
    GymPresentationPanel,
  },
};
</script>
