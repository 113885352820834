<template>
  <li class="flex">
    <span class="mr-4">- </span>
    <span>
      <slot></slot>
    </span>
  </li>
</template>

<script>
export default {
  name: "HyphenListItem",
};
</script>
