import {
  getFormToken,
  getFormTokenV2,
  savePaymentAlias,
} from "@/api/PaymentService";

const initState = () => {
  return {
    isFetched: false,
    data: {
      formToken: "",
    },
  };
};

export default {
  name: "paymentToken",
  namespaced: true,
  state: () => initState(),
  mutations: {
    setFetchedDirty(state) {
      state.isFetched = false;
    },
    setFormToken(state, payload) {
      state.data.formToken = payload;
      state.isFetched = true;
    },
    resetStore(state) {
      state.isFetched = false;
      state.data.formToken = "";
    },
  },
  actions: {
    async retrieveFormToken({ commit }) {
      const response = await getFormToken();
      if (response.bSucces === true)
        commit("setFormToken", response.oReponse.sFormToken);
    },
    async retrieveFormTokenV2({ commit }, host) {
      const response = await getFormTokenV2(host);
      if (response.bSucces === true)
        commit("setFormToken", response.oReponse.sFormToken);
    },
    async savePaymentAlias(
      { commit },
      { alias, dateCreation, fournisseur, idContact }
    ) {
      const response = await savePaymentAlias(
        alias,
        dateCreation,
        fournisseur,
        idContact
      );
      if (response.bSucces === true)
        commit("setFormToken", response.oReponse.sFormToken);
      else throw new Error("savePaymentAlias service error");
    },
    resetStore({ commit }) {
      commit("resetStore");
    },
  },
  getters: {
    isFetched: (state) => {
      return state.isFetched;
    },
    formToken: (state) => {
      return state.data.formToken;
    },
  },
};
