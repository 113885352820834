import {
  getGroupeInvitation,
  getGroupeInvitationV2,
} from "@/api/BookingService";

const initState = () => {
  return {
    isFetched: false,
    data: {},
  };
};

export default {
  name: "reservation",
  namespaced: true,
  state: () => initState(),
  actions: {
    cleanReservation({ commit }) {
      commit("resetState");
    },
    setParticipantResponse({ commit }, { id, response, participant }) {
      commit("setParticipantResponse", { id, response, participant });
    },
    async retrieveReservation({ commit }, { idReservation, jetonInvitation }) {
      const data = await getGroupeInvitation({
        sIdReservation: idReservation,
        sJetonInvitation: jetonInvitation,
      });

      if (data && data.aReservations && !data.sCodeErreur) {
        const reservation = Object.values(data.aReservations)[0];
        if (reservation) commit("setReservation", reservation);
        else commit("setReservation", null);
      } else throw new Error("bad response format");
    },
    async retrieveReservationV2(
      { commit },
      { uuidReservation, uuidInvitation }
    ) {
      const data = await getGroupeInvitationV2(uuidReservation, uuidInvitation);

      if (data) commit("setReservation", data);
      else throw new Error("bad response format");
    },
  },
  mutations: {
    resetState: (state) => {
      Object.assign(state, initState());
    },
    setParticipantResponse(state, { id, response, participant }) {
      let currentParticipantIndex = state.data.participants.findIndex(
        (x) => x.id === id
      );

      if (currentParticipantIndex === -1) {
        state.data.participants.push(participant);
        currentParticipantIndex = state.data.participants.findIndex(
          (x) => x.id === id
        );
      }
      state.data.participants[currentParticipantIndex].reponse = response;
      state.data = Object.assign({}, state.data);
    },
    setReservation: (state, payload) => {
      state.data = Object.assign({}, payload);
      state.isFetched = true;
    },
    setFetchFinish: (state) => {
      state.isFetched = true;
    },
    setFetchedDirty: (state) => {
      state.isFetched = false;
    },
  },
  getters: {
    isLoading: (state) => {
      return !state.isFetched;
    },
    reservation: (state) => {
      return state.data;
    },
  },
};
