import FitArenaHolding from '@/views/holding/FitArenaHolding'
import FitArenaMonParcoursDansLaSalle from '@/views/holding/FitArenaMonParcoursDansLaSalle'
import FitArenaArticle from '@/views/fitarena/FitArenaArticle'
import FitArenaArticleSport from '@/views/fitarena/FitArenaArticleSport'
import FitArenaArticlePadelPark from '@/views/fitarena/FitArenaArticlePadelPark'
import FitArena from '@/views/fitarena/FitArena'
import SignIn from '@/views/auth/SignIn'
import SignUp from '@/views/auth/SignUp'
import BookingResponse from '@/views/booking/BookingResponse'
import Validation from '@/views/auth/Validation'
import ForgotPassword from '@/views/auth/ForgotPassword'
import ReinitPassword from '@/views/auth/ReinitPassword'
import NotFound from '@/views/error/NotFound'
import SharePartie from '@/views/share/SharePartie'
import SharePerformance from '@/views/share/SharePerformance'
import ShareReplay from '@/views/share/ShareReplay'
import FitArenaShareMiniature from '@/views/fitarena/FitArenaShareMiniature'
import CartRecap from '@/views/cart/CartRecap'
import Maintenance from '@/views/maintenance/Maintenance'

export default [
  {
    path: '/',
    component: FitArenaHolding,
    meta: {
      layout: 'containerApp',
      displaySubFooter: true
    }
  },
  {
    path: '/mon-parcours-dans-la-salle',
    component: FitArenaMonParcoursDansLaSalle,
    meta: {
      layout: 'containerApp',
      displaySubFooter: true
    }
  },
  {
    path: '/rgpd',
    component: () => import(/* webpackChunkName: "cgus" */ '@/views/cgus/Rgpd'),
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/mentions-legales',
    component: () => import(/* webpackChunkName: "cgus" */ '@/views/cgus/MentionsLegales'),
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/politique-de-confidentialite',
    component: () => import(/* webpackChunkName: "cgus" */ '@/views/cgus/PolitiqueDeConfidentialite'),
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "cgus" */ '@/views/cgus/Faq'),
    meta: {
      layout: 'containerApp'
    }
  },
  {
    name: 'fitarena',
    path: '/fit-arena/:fitArenaName',
    component: FitArena,
    meta: {
      layout: 'fitarena'
    }
  },
  {
    name: 'fitarenaArticle',
    path: '/fit-arena/article/:indexArticle',
    component: FitArenaArticle,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    name: 'fitarenaArticle',
    path: '/fit-arena/article-sport/:indexArticle',
    component: FitArenaArticleSport,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    name: 'fitarenaArticlePadelPark',
    path: '/fit-arena/article-padel-park/:indexArticle',
    component: FitArenaArticlePadelPark,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/signin',
    component: SignIn,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/signup',
    component: SignUp,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/reinitialisation/:token',
    component: ReinitPassword,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/profile/:sIdReservation?/',
    component: () => import(/* webpackChunkName: "securePages" */ '@/views/dashboard/Dashboard'),
    props: route => ({ query: route.query.q, open: route.query.open }),
    meta: {
      secure: true,
      layout: 'containerApp'
    }
  },
  {
    path: '/invitation/:sIdReservation/:sJetonInvitation/:sReponse?/:nIdContact?',
    name: 'test',
    component: BookingResponse,
    props: true,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    name: 'paymentPage',
    path: '/cart/payment',
    component: () => import(/* webpackChunkName: "securePages" */ '@/views/cart/Payment'),
    meta: {
      secure: true,
      layout: 'containerApp'
    }
  },
  {
    name: 'cartRecap',
    path: '/cart',
    component: CartRecap,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/utilisateur/:sJeton/:nIdGrandPublic/validation-inscription.html',
    component: Validation,
    props: true,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    path: '/404',
    component: NotFound,
    meta: {
      layout: 'containerApp'
    }
  },
  {
    name: 'sharePartie',
    path: '/share/partie/:code',
    component: SharePartie,
    props: true
  },
  {
    name: 'sharePerformance',
    path: '/share/performance/:code',
    component: SharePerformance,
    props: true
  },
  {
    name: 'shareReplay',
    path: '/share/replay/:infos',
    component: ShareReplay,
    props: true
  },
  {
    path: '/miniature',
    component: FitArenaShareMiniature
  },
  {
    path: '*',
    redirect: '/404'
  },
  // {
  //   name: 'Maintenance',
  //   path: '/*',
  //   component: Maintenance
  // }
]
