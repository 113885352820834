export default {
  props: {
    bgCardColor: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    bgCardColorStyle() {
      if (typeof this.bgCardColor === "number")
        return " bg-card" + this.bgCardColor;
    },
  },
};
