<template>
  <div class="w-full">
    <CartBookingItem :item="cartList" />
    <div class="flex flex-col lg:flex-row items-end justify-end mr-2">
      <DoiText
        :textColor="0"
        :fontSize="4"
        :fontFamily="5"
        class="pb-2 border-b-2"
        >TOTAL À PARTAGER<DoiText
          type="span"
          :textColor="1"
          :fontSize="4"
          :fontFamily="1"
          class="pb-2 ml-2"
          >{{ totalTarif }} €</DoiText
        ></DoiText
      >
    </div>
    <div class="flex flex-row justify-end px-4 pt-2">
      <DoiText
        v-if="this.$route.fullPath != '/cart/payment'"
        :fontFamily="4"
        :fontSize="1"
        :textColor="1"
        class="flex md:block text-right"
        >La somme de chaque réservation sera répartie entre les
        participants.</DoiText
      >
    </div>
  </div>
</template>

<script>
import CartBookingItem from "@/views/cart/CartBookingItem";

export default {
  name: "CartBookingList",
  props: {
    cartList: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
    shareBill: Boolean,
  },
  components: {
    CartBookingItem,
  },
  computed: {
    totalTarif() {
      return (
        (this.cartList.reservation.tarifParPersonne *
          this.cartList.reservation.nbPlaceMax) /
        100
      ).toFixed(2);
    },
  },
  methods: {
    async deleteCartElement(sIdReservation) {
      this.$emit("deleteCartElement", sIdReservation);
    },
  },
};
</script>
