<template>
  <div v-if="close_container" class="modal-container">
    <transition name="fade">
      <div v-if="open_modal" class="modal-overlay" />
    </transition>
    <transition name="slide-fade">
      <div
        v-click-outside="clickOutside"
        v-if="open_modal"
        class="modal rounded-lg h-auto"
      >
        <div>
          <div class="flex mb-4">
            <div class="w-11/12" />
            <span
              class="close cursor-pointer w-1/12 mr-2 mt-2"
              @click="vCloseModal"
              ><i class="material-icons">close</i></span
            >
          </div>
          <div class="flex items-center">
            <div class="red-line" />
            <slot name="modal_header" />
          </div>
        </div>
        <div class="modal-content padding">
          <div class="notification-icon" />
          <slot name="modal_content" />
        </div>
        <div class="modal-footer">
          <slot name="modal_footer" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "NewModal",
  data() {
    return {
      open_modal: false,
      close_container: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickOutside() {
      this.$emit("clickOutside");
    },
    vCloseModal() {
      this.open_modal = false;

      setTimeout(() => {
        this.close_container = false;
        this.$emit("close-modal");
      }, 200);

      document.body.classList.remove("no-scroll");
    },
  },
  computed: {
    getOpen() {
      return this.$props.open;
    },
  },
  watch: {
    getOpen: {
      immediate: true,
      handler(val) {
        if (val) {
          this.close_container = true;
          document.body.classList.add("no-scroll");
          setTimeout(() => {
            this.open_modal = true;
          }, 50);
        } else {
          this.open_modal = false;
          document.body.classList.remove("no-scroll");
          setTimeout(() => {
            this.close_container = false;
          }, 300);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red-line {
  border-top: 1px solid $doi--color-color0;
  width: 35px;
}
.material-icons {
  font-size: 30px;
}

.modal-container {
  position: fixed;
  z-index: 20000;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 30000;
}
.modal {
  background-color: #fff;
  position: absolute;
  z-index: 40000;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  width: 25%;
}

.modal .modal-header.black {
  background-color: rgba(0, 0, 0, 1);
}
.modal .modal-header.red {
  background-color: $doi--color-color0;
}

.modal .modal-header .modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px 30px;
  letter-spacing: 0.1em;
}
.modal .modal-content {
  overflow: auto;
  width: 100%;
}

.modal .modal-content.padding {
  padding: 10px;
}

.modal-footer {
  margin-bottom: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(200px);
  opacity: 0;
}
.notification .modal {
  max-width: 400px;
  min-height: 350px;
}

.notification.error .modal .modal-content .notification-icon:after {
  content: "\e5cd";
  font-family: "Material Icons";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  border: solid 5px #fff;
  color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
}

.notification.error .modal .modal-content .notification-icon {
  background: $doi--color-color0;
  width: 100%;
  padding: 20px;
  display: flex;
  z-index: -1;
  position: relative;
}

.notification.success .modal .modal-content .notification-icon {
  background: #21c85b;
  width: 100%;
  padding: 20px;
  display: flex;
  z-index: -1;
  position: relative;
}
.notification.error .modal .close i {
  color: #b61827;
}

.notification.error .modal-content p {
  color: #b61827;
  font-family: "Lato-Bold";
  font-size: 1.6rem;
  margin: 10px 0px;
}

.notification.success .modal-content p {
  color: #338a3e;
  font-family: "Lato-Bold";
  font-size: 1.6rem;
  margin: 10px 0px;
}

.notification.success .modal .modal-content .notification-icon:after {
  content: "\e5ca";
  font-family: "Material Icons";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  border: solid 5px #fff;
  color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
}
.notification.sucess .modal .modal-content .notification-icon {
  background: $doi--color-color0;
  width: 100%;
  padding: 20px;
  display: flex;
  z-index: -1;
  position: relative;
}

.header-icon {
  color: 000;
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

@media screen and (max-width: $doi-breakpoint-desktop) {
  .modal .modal-header .modal-title {
    padding: 8px 10px 8px 10px;
  }

  .modal-title h2:not(#replays .modal-title h2) {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 800px) {
  .modal-container div.modal {
    width: 92vw;
  }
}
</style>
