<template>
  <div class="p-8 lg:p-20">
    <div class="py-8 flex flex-col items-center">
      <div class="flex flex-col items-center justify-center">
        <DoiHeader
          :level="4"
          :fontFamily="1"
          :headerColor="0"
          class="text-center mb-4"
          >JE CHANGE MON MOT DE PASSE</DoiHeader
        >
        <DoiHeader
          :level="4"
          :fontFamily="0"
          :headerColor="2"
          class="tracking-widest text-center mt-2 mb-7"
          >BOOK, PLAY, SHARE !</DoiHeader
        >
      </div>
    </div>
    <div
      class="mx-auto flex justify-center flex-col items-center w-full lg:w-5/12 lg:max-w-2k"
    >
      <div class="w-full">
        <form @submit.prevent="submit">
          <div>
            <DoiInput
              type="email"
              v-model.trim="$v.email.$model"
              :error="$v.email.$error"
              placeholder="Votre email *"
              autocomplete="on"
            ></DoiInput>
            <DoiText
              italic
              :textColor="0"
              :fontSize="2"
              v-if="!$v.email.required && $v.email.$error"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="flex justify-center mt-10">
            <DoiButton
              :bgHover="1"
              type="submit"
              :disabled="submitStatus === 'PENDING'"
            >
              RÉINITIALISER MON MOT DE PASSE
              <DoiSpinner :color="1" :show="showSpinner" class="ml-3" />
            </DoiButton>
          </div>
        </form>

        <Modal
          title="RÉINIT. MOT DE PASSE"
          :open="open"
          size="xs"
          :keepratio="true"
          @close-modal="vCloseAndRedirect()"
        >
          <template slot="modal_content">
            <DoiText
              :textColor="1"
              :fontSize="3"
              :fontFamily="5"
              class="text-center mb-8"
              >{{ message }}</DoiText
            >
            <DoiButton @click="vCloseAndRedirect()">OK</DoiButton>
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import { required } from "vuelidate/lib/validators";
import { sendEmailReinit } from "@/api/AuthService";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      submitStatus: null,
      open: false,
      message: "",
      showSpinner: false,
    };
  },
  components: { Modal },
  validations: {
    email: {
      required,
    },
  },
  computed: {
    getToken() {
      return this.$store.getters["AuthModule/get_token"];
    },
  },
  methods: {
    submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        this.message =
          "Merci de vérifier vos informations et veuillez réessayer";
        this.showSpinner = false;
      } else {
        this.sendEmailReinit();
      }
    },
    vCloseAndRedirect() {
      this.open = false;
      this.$router.push("/signin");
    },
    async sendEmailReinit() {
      const host = `${process.env.VUE_APP_URL}reinitialisation`;
      await sendEmailReinit(this.email, host);
      this.message =
        "Si un compte existe pour cette adresse, vous recevrez un mail contenant un lien de réinitialisation.";
      this.open = true;
      this.showSpinner = false;
    },
    close() {
      this.open = false;
    },
  },
  watch: {
    getToken(token) {
      if (token !== null && token !== "") this.$router.push("/profile");
    },
  },
};
</script>
