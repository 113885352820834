<template>
  <div
    class="flex flex-col justify-between items-center md:h-128"
    :class="img_position === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'"
  >
    <div class="md:w-1/2 flex flex-col justify-center py-10">
      <slot></slot>
    </div>
    <div class="border mx-3 h-full" />
    <div class="md:w-1/2 w-full">
      <div
        class="flex -my-3"
        :class="img_position === 'right' ? 'justify-start' : 'justify-end'"
      >
        <img :src="img" :alt="img_alt" class="object-cover" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GymPresentationPanel",
  props: {
    img: String,
    img_position: {
      type: String,
      default: "right",
    },
    img_alt: {
      type: String,
      default: "",
    },
  },
};
</script>
