<template>
  <div>
    <div class="hidden xl:block">
      <img
        src="@/assets/img/background-qrcode-grand-ecran.png"
        alt="Mobile QR Code"
      />
    </div>
    <div class="desktop hidden">
      <img src="@/assets/img/background-qrcode.png" alt="Mobile QR Code" />
    </div>
    <div class="tablet hidden">
      <img
        src="@/assets/img/background-qrcode-tablet.png"
        alt="Mobile QR Code"
      />
    </div>
    <div class="smartphone hidden">
      <img
        src="@/assets/img/background-qrcode-smartphone.png"
        alt="Mobile QR Code"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileAppQRCode",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

@media (min-width: $doi-breakpoint-tablet) and (max-width: 1279px) {
  .desktop {
    display: block;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .tablet {
    display: block;
  }
}

@media (max-width: 359px) {
  .smartphone {
    display: block;
  }
}
</style>
