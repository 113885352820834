<template>
  <span
    @click="onClick"
    :class="colorClass"
    class="border rounded-full py-1 px-2 transition transition-300 text-sm flex items-center text-white mx-1 cursor-pointer"
  >
    <span class="material-icons mr-1">check</span>
    <span class="mr-1">{{ label }}</span>
  </span>
</template>

<script>
export default {
  name: "DoiFilter",
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    colorClass() {
      if (!this.active) {
        return "bg-gray-300";
      }
      switch (this.color) {
        case 0:
          return "bg-fa-red";
        case 1:
          return "bg-gray-500";
        case 2:
          return "bg-blue-500";
        default:
          return "bg-gray-300";
      }
    },
  },
  methods: {
    onClick($event) {
      this.$emit("click", $event);
    },
  },
};
</script>
