export default {
  props: {
    color: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    bigSpinnerColorStyle() {
      if (typeof this.color === "number") return " big-border" + this.color;
    },
    littleSpinnerColorStyle() {
      if (typeof this.color === "number") return " little-border" + this.color;
    },
  },
};
