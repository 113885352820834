<template>
  <div class="bg-fa-red">
    <div class="md:hidden">
      <div class="flex justify-center">
        <div>
          <DoiText
            :fontSize="7"
            :fontFamily="0"
            class="book-title relative pb-2 pt-6 tracking-widest"
            >BOOK !</DoiText
          >
        </div>
      </div>
      <div class="flex flex-col">
        <div class="w-full flex flex-col justify-center mt-3">
          <DoiText class="text-center px-5" :fontSize="4" :fontFamily="0"
            >JE CHOISIS LA FIT ARENA PROCHE DE CHEZ MOI</DoiText
          >
          <div class="flex flex-1 justify-center">
            <img
              class="max-w-xs"
              alt="présentation de l'application smartphone lors de la séléction d'un créneau"
              src="@/assets/img/presentation/BookIphone1.png"
            />
          </div>
        </div>
        <div class="w-full flex flex-col justify-center mt-3">
          <DoiText class="text-center px-5" :fontSize="4" :fontFamily="0"
            >JE CHOISIS MON SPORT ET JE RÉSERVE UN CRÉNEAU</DoiText
          >
          <div class="flex flex-1 justify-center">
            <img
              class="max-w-xs"
              alt="présentation de l'application smartphone lors de la réservation d'un créneau"
              src="@/assets/img/presentation/BookIphone2.png"
            />
          </div>
        </div>
        <div class="w-full flex flex-col justify-center mt-3">
          <DoiText class="text-center px-5" :fontSize="4" :fontFamily="0"
            >J'INVITE MES AMIS À REJOINDRE LA PARTIE</DoiText
          >
          <div class="flex flex-1 justify-center">
            <img
              class="max-w-xs"
              alt="présentation de l'application smartphone lors de l'invitation d'un ami"
              src="@/assets/img/presentation/BookIphone3.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block px-14 lg:px-32">
      <carousel
        :navigationEnabled="true"
        navigationPrevLabel="&#10140;"
        navigationNextLabel="&#10140;"
        :perPage="1"
      >
        <slide>
          <div class="book-slider">
            <div class="book-slider-img">
              <img
                class="max-w-xs"
                alt="présentation de l'application smartphone lors de la séléction d'un créneau"
                src="@/assets/img/presentation/BookIphone1.png"
              />
            </div>
            <div class="book-slider-wrapper">
              <div class="book-slider-spacer"></div>
              <div class="book-slider-text-wrapper">
                <div>
                  <DoiText
                    :fontSize="7"
                    :fontFamily="0"
                    class="book-slider-title tracking-widest 2k:text-7xl 4k:text-9xl"
                    >BOOK !</DoiText
                  >
                </div>
                <DoiText class="book-slider-text" :fontFamily="0" :fontSize="4"
                  >JE CHOISIS LA FIT ARENA PROCHE DE CHEZ MOI</DoiText
                >
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="book-slider">
            <div class="book-slider-img">
              <img
                class="max-w-xs"
                alt="présentation de l'application smartphone lors de la réservation d'un créneau"
                src="@/assets/img/presentation/BookIphone2.png"
              />
            </div>
            <div class="book-slider-wrapper">
              <div class="book-slider-spacer"></div>
              <div class="book-slider-text-wrapper">
                <div>
                  <DoiText
                    :fontSize="7"
                    :fontFamily="0"
                    class="book-slider-title tracking-widest 2k:text-7xl 4k:text-9xl"
                    >BOOK !</DoiText
                  >
                </div>
                <DoiText class="book-slider-text" :fontFamily="0" :fontSize="4"
                  >JE CHOISIS MON SPORT ET JE RÉSERVE UN CRÉNEAU</DoiText
                >
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="book-slider">
            <div class="book-slider-img">
              <img
                class="max-w-xs"
                alt="présentation de l'application smartphone lors de l'invitation d'un ami"
                src="@/assets/img/presentation/BookIphone3.png"
              />
            </div>
            <div class="book-slider-wrapper">
              <div class="book-slider-spacer"></div>
              <div class="book-slider-text-wrapper">
                <div>
                  <DoiText
                    :fontSize="7"
                    :fontFamily="0"
                    class="book-slider-title tracking-widest 2k:text-7xl 4k:text-9xl"
                    >BOOK !</DoiText
                  >
                </div>
                <DoiText class="book-slider-text" :fontFamily="0" :fontSize="4"
                  >J'INVITE MES AMIS À REJOINDRE LA PARTIE</DoiText
                >
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "BookPresentation",
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.VueCarousel-navigation-prev {
  transform: rotateY(180deg) translateY(-50%) translateX(100%) !important;
}

.VueCarousel-navigation-button {
  background-color: #4c4c4c !important;
  color: white !important;
  padding: 7px 10px !important;
}

.VueCarousel-dot {
  background: transparent !important;
  border-radius: 0px !important;
  border: 1px solid white !important;
  padding: 0px !important;
  margin: 0.5rem !important;
  margin-bottom: 1rem !important;
}

.VueCarousel-dot--active {
  background: white !important;
}

.book-title {
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid white;
}

.book-title:before {
  content: "";
  height: 1px;
  bottom: -1px;
  position: absolute;
  width: 100vw;
  transform: translateX(-5rem);
  background-color: #fff;
  margin-left: -5rem;
}

.book-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.book-slider-img {
  display: flex;
  justify-content: center;
}

.book-slider-wrapper {
  display: flex;
}

.book-slider-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.book-slider-spacer {
  width: 5rem;
}

.book-slider-title {
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid white;
}

.book-slider-title:before {
  content: "";
  height: 1px;
  bottom: -1px;
  position: absolute;
  width: 5rem;
  background-color: #fff;
  margin-left: -5rem;
}

.book-slider-text {
  font-family: $doi--text-font-family0;
  margin-top: 2.25rem;
  line-height: 2rem;
  max-width: 24rem;
}

@media (min-width: $doi-breakpoint-large-screen) {
  .book-slider-title {
    border-bottom: 3px solid white;
  }

  .book-slider-text {
    font-size: 2rem;
    line-height: 3rem;
  }
}
</style>
