<template>
  <div>
    <div
      class="border border-gray-300 cursor-pointer rounded-lg flex justify-between items-center p-3"
      @click="toggleDropdown"
    >
      <div
        class="text-container flex whitespace-nowrap overflow-hidden text-ellipsis"
      >
        <template v-if="selected != null">
          <div class="text-sm text-black">{{ selected }}</div>
        </template>
        <div v-else>{{ title }}</div>
      </div>
      <div class="flex items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          viewBox="0 0 10 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.48818 3.05745C5.16921 2.45318 4.30375 2.45318 3.98478 3.05745L1.93052 6.94911C1.63171 7.51518 2.04213 8.19591 2.68222 8.19591H6.79074C7.43083 8.19591 7.84125 7.51517 7.54244 6.94911L5.48818 3.05745ZM3.9848 17.7094C4.30378 18.3137 5.16923 18.3137 5.4882 17.7094L7.54247 13.8177C7.84127 13.2517 7.43085 12.5709 6.79077 12.5709L2.68224 12.5709C2.04215 12.5709 1.63174 13.2517 1.93054 13.8177L3.9848 17.7094Z"
            fill="#090909"
          />
        </svg>
      </div>
    </div>
    <div
      class="bottom p-3 rounded-lg top-full left-0 w-full"
      v-show="isDropdownOpen"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="flex justify-start items-center p-2 gap-2"
      >
        <span @click="select(item, index)" class="cursor-pointer">{{
          item
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isItemSelected: false,
      selected: "",
      isDropdownOpen: false,
    };
  },
  props: {
    modelValue: String,
    items: [Object, Array],
    title: String,
  },
  async mounted() {
    if (this.modelValue && this.modelValue != [] && this.modelValue != {})
      this.select(this.modelValue);
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    // Supprimez le gestionnaire d'événements lorsque le composant est détruit
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    modelValue(value) {
      this.select(value, 0);
    },
  },
  methods: {
    handleClickOutside(event) {
      const selector = this.$el; // Obtenez la référence de l'élément racine du composant
      if (selector && !selector.contains(event.target)) {
        // Si l'événement de clic ne provient pas de l'intérieur du composant
        this.closeDropdown(); // Appelez une méthode pour fermer la partie "bottom"
      }
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    select(item, index) {
      this.selected = item;
      this.closeDropdown();
      this.$emit("update:modelValue", this.selected);
      this.$emit("update:index", index);
    },
  },
};
</script>

<style scoped>
.bottom {
  border: 1px solid #e1e1e1;
  z-index: 1;
}

.text-container {
  max-width: calc(100% - 25px);
}
</style>
