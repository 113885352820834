import Network from '@/utils/Network'

const url1 = process.env.VUE_APP_API_URL

const getPerformances = (id_contact) => {
  return Network.execute({
    method: 'GET',
    url: url1 + `stats/performances?idContact=${id_contact}`,
    secure: true
  })
}

const getRanking = (contact_id, sport_id) => {
  return Network.execute({
    method: 'GET',
    url: url1 + `stats/classements?idContact=${contact_id}&idSport=${sport_id}`,
    secure: true
  })
}

export { getPerformances, getRanking }
