import Vuex from "vuex";
import Vue from "vue";

import AuthModule from "@/store/modules/auth.js";
import FitArenaModule from "@/store/modules/fitArena.js";
import UserModule from "@/store/modules/user.js";
import CartModule from "@/store/modules/cart.js";
import InvitationModule from "@/store/modules/invitation.js";
import FitArenaListModule from "@/store/modules/fitArenaList.js";
import PaymentModule from "@/store/modules/payment.js";
import PaymentTokenModule from "@/store/modules/paymentToken.js";
import TimeslotsModule from "@/store/modules/timeslots.js";
import ReservationListModule from "@/store/modules/reservationList.js";
import ReservationModule from "@/store/modules/reservation.js";
import ContactListModule from "@/store/modules/contactList.js";
import RouterHistoryModule from "@/store/modules/routerHistory.js";
import PerformanceModule from "@/store/modules/performance.js";
import TeamsModule from "@/store/modules/teams.js";
import PaymentHistoryListModule from "@/store/modules/paymentHistoryList.js";
import RankingModule from "@/store/modules/ranking.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AuthModule,
    FitArenaModule,
    UserModule,
    CartModule,
    InvitationModule,
    FitArenaListModule,
    PaymentModule,
    PaymentTokenModule,
    TimeslotsModule,
    ReservationListModule,
    ReservationModule,
    ContactListModule,
    RouterHistoryModule,
    PerformanceModule,
    TeamsModule,
    PaymentHistoryListModule,
    RankingModule,
  },
});
