<template>
  <DoiCard
    :borderBottom="1"
    :bgCardColor="1"
    class="mb-6 flex flex-col lg:flex-row relative lg:flex-1 lg:justify-between"
  >
    <template v-slot:left>
      <div class="items-center flex w-full mb-4">
        <div class="w-14 h-14 p-2 sm:w-20 sm:h-20 box-shadow rounded-xl mr-2">
          <img :src="require(`@/assets/icons/utils/sports/${sportIcon}`)" />
        </div>
        <!-- <DoiText
          :fontSize="4"
          :textColor="0"
          class="lg:hidden ml-1 p-4 uppercase w-10/12"
          >{{ item.reservation.typeCreneau }} - </DoiText
        > -->
        <DoiText
          :fontSize="4"
          :textColor="1"
          class="lg:hidden ml-1 p-4 uppercase w-10/12"
          >{{ item.activite.libelle }}</DoiText
        >
      </div>
    </template>
    <template v-slot:center>
      <div class="p-4 lg:py-0">
        <!-- <DoiText
          :fontSize="4"
          :textColor="0"
          class="lg:hidden ml-1 p-4 uppercase w-10/12"
          >{{ item.reservation.typeCreneau }} - </DoiText
        > -->
        <DoiText
          :fontSize="4"
          :fontFamily="1"
          :textColor="1"
          class="hidden lg:inline-block uppercase"
          >{{ item.activite.libelle }}</DoiText
        >
        <div
          class="flex flex-col md:flex-row justify-start items-start md:items-center"
        >
          <DoiText :fontFamily="1" :textColor="1" class="uppercase"
            >{{ libelleReservation }} -
            {{ formattedSlotDuration(item.reservation) }}</DoiText
          >
        </div>
        <div class="flex md:justify-start items-start md:items-center">
          <!-- <DoiText :fontFamily="0" :textColor="1"
            >{{ item.reservation.nbPlaceConseille }} PARTICIPANTS CONSEILLÉS</DoiText
          > -->
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="hidden lg:block">
        <div class="flex justify-center">
          <DoiText
            :textColor="1"
            :fontFamily="0"
            class="text-right uppercase flex items-center lg:text-2xl"
            >PRIX / <span class="material-symbols-outlined mr-2">person</span> :
            {{ item.reservation.tarifParPersonne / 100 }} €</DoiText
          >
        </div>
      </div>
      <div class="block p-4 lg:hidden">
        <div class="flex justify-center">
          <!-- <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="text-right uppercase lg:text-2xl"
            >{{ item.sTarif }} €</DoiText
          > -->
        </div>
      </div>
    </template>
  </DoiCard>
</template>

<script>
import sports from "@/data/sports.json";
import dayjs from "dayjs";

export default {
  name: "CartBookingItem",
  props: {
    item: Object,
  },
  computed: {
    sportIcon() {
      let icon = "";
      const sp = sports.find(
        (sport) =>
          sport.label === this.item.activite.libelle.toLowerCase().trim()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    libelleReservation() {
      return `Le ${dayjs(this.item.reservation.date).format(
        "DD MMMM YYYY"
      )} de ${dayjs(this.item.reservation.heureDebut).format(
        "HH:mm"
      )} à ${dayjs(this.item.reservation.heureFin).format("HH:mm")}`;
    },
  },
  methods: {
    formattedSlotDuration(item) {
      const duree = item.dureeCreneau;
      const hour = Math.floor(duree / 60);
      let min = duree % 60;
      if (hour > 0 && min <= 9 && min !== 0)
        min = min.toString().padStart(2, "0");
      let r;

      if (hour > 0 && min > 0) r = `${hour}h${min}min`;
      else if (hour > 0 && min === 0) r = `${hour}h`;
      else if (hour === 0 && min > 0) r = `${min}min`;
      return r;
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
