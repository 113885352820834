import { getFitArenaList2 } from "@/api/FitArenaService";

export default {
  name: "FitArenaList",
  namespaced: true,
  state: () => ({
    fitArenaList: [],
  }),
  actions: {
    async retrieveFitarenas({ state, commit }) {
      if (state.fitArenaList.length === 0) {
        const response = await getFitArenaList2();
        if (response && response.fitArenaListe)
          commit("setFitArenaList", response.fitArenaListe);
      }
    },
  },
  mutations: {
    setFitArenaList(state, payload) {
      state.fitArenaList = payload;
      state.fitArenaList.splice(state.fitArenaList.length);
    },
  },
  getters: {
    fitArenaList: (state) => {
      return state.fitArenaList;
    },
  },
};
