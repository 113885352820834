<template>
  <div v-on="$listeners" class="btn">
    <router-link
      v-if="link && !disabled"
      :to="link"
      :class="
        bgColorStyle +
        bgHoverStyle +
        textColorStyle +
        fontFamilyStyle +
        fontSizeStyle +
        fontWeightStyle
      "
      class="btn-foreground cursor-pointer py-3 px-4"
    >
      <slot></slot>
    </router-link>
    <button
      v-else
      :disabled="disabled"
      :class="
        bgColorStyle +
        bgHoverStyle +
        textColorStyle +
        fontFamilyStyle +
        fontSizeStyle +
        fontWeightStyle
      "
      class="btn-foreground cursor-pointer py-3 px-4"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
import bgColorMixin from "@/components/mixins/bgColorMixin.js";
import bgHoverMixin from "@/components/mixins/bgHoverMixin.js";
import textColorMixin from "@/components/mixins/textColorMixin.js";
import fontFamilyMixin from "@/components/mixins/fontFamilyMixin.js";
import fontSizeMixin from "@/components/mixins/fontSizeMixin.js";
import fontWeightMixin from "@/components/mixins/fontWeightMixin.js";

export default {
  name: "DoiButton",
  mixins: [
    bgColorMixin,
    textColorMixin,
    bgHoverMixin,
    fontFamilyMixin,
    fontSizeMixin,
    fontWeightMixin,
  ],
  props: {
    link: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  position: relative;
  min-width: 121px;
  min-height: 40px;
  border-radius: 4px;
}

.btn-foreground {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  transition: background-color 0.2s;
  white-space: nowrap;
  border-radius: 4px;
}

.btn-foreground:disabled {
  background-color: #a8a8a8;
  cursor: default;
}
</style>
