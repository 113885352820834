<template>
  <th
    :class="bgColorTableClass + fontFamilyStyle"
    class="border-right-cell text-white px-2 py-2"
  >
    <slot></slot>
  </th>
</template>

<script>
import bgColorTableMixin from "@/components/mixins/bgColorTableMixin.js";
import fontFamilyMixin from "@/components/mixins/fontFamilyMixin.js";

export default {
  name: "DoiTableHead",
  mixins: [bgColorTableMixin, fontFamilyMixin],
};
</script>
