import { getPaymentAlias, getPaymentAliasV2 } from "@/api/PaymentService";
import Vue from "vue";

const initState = () => {
  return {
    isFetched: false,
    data: {
      alias: null,
    },
  };
};

export default {
  name: "payment",
  namespaced: true,
  state: () => initState(),
  mutations: {
    setFetchedDirty(state) {
      state.isFetched = false;
    },
    setPaymentAlias(state, payload) {
      Vue.set(state.data, "alias", payload);
      state.isFetched = true;
    },
    setFetched(state) {
      state.isFetched = true;
    },
    resetStore(state) {
      state.isFetched = false;
      state.data.alias = "";
    },
  },
  actions: {
    async retrievePaymentAlias({ commit }) {
      commit("resetStore");
      const response = await getPaymentAlias();
      if (response.bSucces === true)
        commit("setPaymentAlias", response.oReponse);
    },
    async retrievePaymentAliasV2({ commit }) {
      commit("resetStore");
      const response = await getPaymentAliasV2();
      if (response === undefined) {
        if (window.location.href.includes("profile")) {
          const message =
            "Veuillez faire une réservation pour pouvoir modifier votre carte";
          if (screen.width > 640) {
            Vue.$toast.error(message);
          } else {
            Vue.$toast.error(message, {
              position: "top",
            });
          }
        }
        commit("setFetched");
      }
      if (response) commit("setPaymentAlias", response.oReponse);
    },
    resetStore({ commit }) {
      commit("resetStore");
    },
  },
  getters: {
    isFetched: (state) => {
      return state.isFetched;
    },
    alias: (state) => {
      return state.data.alias;
    },
  },
};
