<template>
  <div class="container mx-auto font">
    <div
      v-if="
        this.$route.params.indexArticle ===
        'six-mois-apres-ouverture-fit-arena-padel-park'
      "
      class="mx-8 text-justify"
    >
      <H1Article
        >6 mois après son ouverture : qu'en est-il de la 1ère Fit Arena
        ?</H1Article
      >
      <TextArticle
        >L’exploitation du Padel Park et investisseur du projet Thomas Brissaud
        s’est confié à notre équipe pour nous faire un retour de satisfaction 6
        mois après l’ouverture de cette salle de padel connectée.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/Photo-Thomas.jpg"
          alt="Thomas Brisaud - Fit Arena Padel Park"
        />
      </div>
      <br />
      <H2Article
        >Après 6 mois d'utilisation, êtes-vous satisfait de la structure
        connectée ?</H2Article
      ><br />
      <TextArticle
        >Nous sommes très contents de notre salle de padel. Sa structure
        esthétique et lumineuse offre des conditions de jeux optimales à nos
        sportifs qui peuvent pratiquer leur sport toute l’année sans se soucier
        du temps tout en étant connectés à l’environnement extérieur verdoyant.
        Composés de deux terrains, la salle de padel a été conçue pour limiter
        la résonnance et assurer un niveau sonore correct lorsque deux matchs
        sont en cours.</TextArticle
      ><br />
      <TextArticle
        >La structure assure un confort thermique, lumineux et acoustique aux
        joueurs pendant leur match. La couche numérique Fit Arena renforce le
        confort offert aux sportifs. L’entrée par QR code leur permet d’être
        autonome dans l’accès à la salle. Les tablettes numériques couplées à
        l’écran géant et aux caméras leur octroient une dimension digitale et
        conviviale appréciée. Les joueurs bénéficient d’un suivi en direct de
        leur match et d’un enregistrement de leurs temps forts disponibles en
        replay.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/image00008.jpeg"
          alt="Fit Arena Padel Park Dijon"
        />
      </div>
      <br />
      <H2Article
        >Pensez-vous que la couche numérique apporte-t-elle un vrai plus à votre
        salle de padel ?</H2Article
      ><br />
      <TextArticle
        >Oui, le fait d’avoir opté pour une Fit Arena nous a permis d’offrir une
        vraie valeur ajoutée à notre structure.</TextArticle
      ><br />
      <TextArticle
        >La couche numérique a vraiment su convaincre nos joueurs.
        L’enregistrement des temps forts ainsi que les outils de scoring sont
        des options qui plaisent beaucoup, au point que nos utilisateurs veulent
        que les enregistrements soient encore plus longs !</TextArticle
      ><br />
      <TextArticle
        >Pour que chacun tire le meilleur de cette expérience numérique
        innovante, nous avons assuré un rôle d’enseignement et de pédagogie
        auprès de nos joueurs. Nous avons veillé à leur présenter les
        différentes étapes du Book, Play, Share de Fit Arena. Pour ce faire,
        nous avons assuré la présence d’un responsable sur place durant les
        premiers mois d’ouverture pour guider les adhérents.</TextArticle
      ><br />
      <TextArticle
        >Fit Arena est maintenant rentrée dans les habitudes de nos joueurs.
        Nous allons donc pouvoir élargir notre plage horaire. Si une personne
        sera toujours présente pendant les heures pleines, l’accès autonome sera
        privilégié pendant les heures creuses.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/PXL_20220913_085800109.jpg"
          alt="Fit Arena Padel Park Dijon"
        />
      </div>
      <br />
      <H2Article>Les résultats de la salle sont-ils satisfaisants ?</H2Article
      ><br />
      <TextArticle
        >Les résultats de la salle sont au-dessus de nos attentes.</TextArticle
      ><br />
      <TextArticle
        >Nous jouissons d’un bon taux de fréquentation. Nous n’avons aucun jour
        blanc et comptons entre 4 à 5 réservations quotidiennes. Nous montons à
        10 réservations lors de nos meilleures journées. Les soirs et les
        week-ends sont les créneaux les plus prisés, ils sont donc généralement
        complets chaque semaine.</TextArticle
      ><br />
      <TextArticle
        >Nous animons régulièrement les terrains en proposant des tournois et
        des cours collectifs adaptés à différents niveaux.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/PXL_20220913_085207289.jpg"
          alt="Fit Arena Padel Park Dijon"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle ===
        'la-rentree-occasion-de-faire-du-sport'
      "
      class="mx-8 text-justify"
    >
      <H1Article>C'est la rentrée, l'occasion de faire du sport !</H1Article>
      <TextArticle
        >Qui dit rentrée dit bonnes résolutions : et si se (re)mettre au sport
        en était une ? Qui dit rentrée dit également découverte et apprentissage
        : une occasion de (re)découvrir un nouveau sport ? Fit Arena sera votre
        allié pour vivre une rentrée sportive !</TextArticle
      ><br />
      <H2Article
        >Sports collectifs ou sports de raquette : Fit Arena est équipée
        !</H2Article
      ><br />
      <TextArticle
        >Toute l’agilité de Fit Arena est de permettre la pratique de plusieurs
        sports en un seul lieu. Notre salle connectée est dotée d’équipements
        motorisés – filet de volleyball, paniers de basket, cages de football,
        filet de badminton... - qui s’installent automatiquement selon le sport
        réservé.</TextArticle
      ><br />
      <TextArticle
        >Vous souhaitez tester différents sports pour la rentrée sans vous
        abonner à de multiples clubs sportifs ou associatifs ? Fit Arena est la
        solution pour vous ! Il vous suffit d’un téléphone portable, de quelques
        compagnons de sport et le tour est joué. Connectez-vous sur le site ou
        l’application mobile Fit Arena, créez votre compte puis réservez votre
        créneau dans la Fit Arena proche de chez vous. Vous recevez alors un QR
        Code pour entrer dans la salle. Partagez cette invitation à vos amis ou
        votre famille, chacun reçoit ensuite son propre QR Code. Ce système de
        contrôle d’accès permet à chacun de pouvoir entrer dans la Fit Arena en
        toute autonomie.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/SMC2_PadelPark_Dijon_YoanJeudy_Andia-46.jpg"
          alt="Sports - Fit Arena"
        />
      </div>
      <br />
      <TextArticle
        >Plus besoin de se limiter à un seul sport, choisissez à chaque séance
        le sport que vous avez envie de pratiquer parmi ceux proposés par votre
        Fit Arena. C’est la salle qui s’adapte à vos envies et non plus
        l’inverse !</TextArticle
      ><br />
      <H2Article
        >Une couche numérique pour une expérience sportive optimisée</H2Article
      ><br />
      <TextArticle
        >Fit Arena est plus qu’une simple salle de sport. C’est une expérience
        digitale qui se vit en amont et en aval de la séance. La réservation de
        votre créneau se fait en ligne, l’entrée au sein de votre Fit Arena est
        permise grâce à un lecteur de QR code. Les équipements sportifs
        s’installent automatiquement à votre arrivée. Vous pouvez ensuite tenir
        à jour les scores au fur et à mesure de vos matchs grâce à une tablette
        à votre disposition. Les scores sont diffusés sur un écran géant. Des
        caméras vous permettent de filmer les temps forts de vos jeux. Ces
        derniers sont ensuite accessibles depuis votre compte Fit
        Arena.</TextArticle
      ><br />
      <TextArticle
        >Avec ce système, vous pourrez alors suivre votre évolution et vous
        comparer aux autres joueurs grâce à un système de classement entre les
        sportifs Fit Arena. Estimez votre niveau en cette rentrée et soyez
        ensuite acteur de votre progression au fil des semaines.</TextArticle
      ><br />
      <H2Article>Le padel connecté avec Fit Arena</H2Article><br />
      <TextArticle
        >Si Fit Arena est d’abord un concept de salle multisports, elle peut
        tout aussi bien être dédiée à un sport en particulier. C’est le cas de
        la Fit Arena de Dijon : une salle connectée dédiée au padel. Les
        sportifs peuvent jouir de tout l’univers numérique de Fit Arena pour une
        pratique de padel autonome et digitale. Vous êtes de la région de Dijon
        et souhaitez vous initier à ce nouveau sport de raquette en vogue ?
        Alors rendez-vous à Padel Park x Fit Arena Dijon !</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/SMC2_PadelPark_Dijon_YoanJeudy_Andia-20.jpg"
          alt="Sports - Fit Arena"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle ===
        'toutes-les-bonnes-raisons-pour-se-mettre-aux-sports-de-raquettes'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Bien pratiquer son sport de raquettes</H1Article>
      <TextArticle
        >Les sports de raquettes sont nombreux et variés : tennis, padel,
        squash, ping-pong, badminton, ... Pourquoi pratiquer un sport de
        raquettes et surtout lequel choisir ? Nous vous disons tout
        !</TextArticle
      ><br />
      <H2Article>Pourquoi pratiquer un sport de raquettes ?</H2Article><br />
      <TextArticle
        >Les sports de raquettes génèrent de multiples bienfaits, autant pour
        les adultes que pour les enfants. Ils améliorent la condition physique
        du corps et favorisent la croissance chez les plus jeunes. Les rotations
        de hanche et les frappes de balle fortifient le haut de corps, tandis
        que les accélérations et les sauts font davantage travailler les jambes.
        Le fait d’être constamment en mouvement permet également de travailler
        l’équilibre.</TextArticle
      ><br />
      <TextArticle
        >Au-delà du physique, pratiquer un sport de raquettes est également bon
        pour l’esprit. Cela permet de renforcer son mental et d’augmenter le
        niveau de concentration tout en renforçant son niveau stratégique et sa
        détermination. D’ailleurs, une étude publiée dans le British Journal of
        Sports Medicine a indiqué que les sports de raquettes sont les plus
        efficaces pour diminuer les risques de mortalité. Cette étude souligne
        notamment les effets positifs du tennis, du squash et du
        badminton.</TextArticle
      ><br />
      <H2Article>Quel sport de raquettes est fait pour vous ?</H2Article><br />
      <H3Article>La pratique du tennis</H3Article>
      <TextArticle
        >Comptant plus d’un million de licenciés en France, le tennis est le
        premier sport individuel pratiqué et le plus prestigieux des sports de
        raquettes. Sa notoriété est enrichie par les tournois professionnels et
        internationaux très populaires tels que Roland-Garros et par ses
        athlètes mondialement connus comme Rafael Nadal. Le tennis apporte de
        nombreux bienfaits sur la santé. Il permet d’optimiser son endurance, sa
        coordination et sa concentration tout en faisant travailler la majorité
        des muscles du corps. C’est un sport agréable et ludique mais également
        très intense. De nombreux terrains de tennis sont présents aux quatre
        coins de la France.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/tennis-geebd589f6_1920.jpg"
          alt="Tennis - Fit Arena"
        />
      </div>
      <br />
      <H3Article>La pratique du badminton</H3Article>
      <TextArticle
        >En parlant de sport de raquettes très intense, nous sommes obligés de
        parler du badminton ! Invitant le joueur à se déplacer vers l’avant,
        vers l’arrière et sur les côtés durant tout le temps de l’échange, il
        sollicite de nouveaux muscles. Il s’agit d’un sport de raquette aussi
        physique que stratégique et technique. C’est une discipline très
        populaire dans le domaine scolaire.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/badminton-gfd9d591ad_1920.jpg"
          alt="Badminton - Fit Arena"
        />
      </div>
      <br />
      <H3Article>La pratique du padel</H3Article>
      <TextArticle
        >Le dernier né des sports de raquettes : le padel ! Il connaît un succès
        fulgurant en France du fait de son accessibilité, son côté fun et
        physique. Il s’agit d’un mélange entre le tennis, le squash et le
        badminton. Il suffit d’une dizaine de minutes d’initiation à ce sport
        pour commencer à se faire plaisir. C’est également un sport très complet
        et ludique, qui permet de travailler sa rapidité, sa précision et ses
        réflexes.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/racket-g6c0057716_1920.jpg"
          alt="Padel - Fit Arena"
        />
      </div>
      <br />
      <TextArticle
        >Il existe évidemment d’autres sports de raquettes, tous porteurs de
        nombreux bienfaits pour le corps : tennis de table, squash
        ...</TextArticle
      ><br />
      <H2Article
        >Fit Arena, la salle connectée qui vous accompagne dans votre pratique
        sportive</H2Article
      ><br />
      <TextArticle
        >Avec ses équipements motorisés, Fit Arena vous accompagne dans la
        pratique du sport de raquettes de votre choix. Vous n’avez qu’à
        sélectionner le sport et le créneau souhaités sur notre application ou
        notre site internet, puis à inviter vos amis et le tour est joué !
        Rendez-vous le jour J à la Fit Arena proche de chez vous, les
        équipements adéquates s’installeront automatiquement à votre arrivée.
        Tout ce qui vous reste à faire maintenant, c’est de motiver vos amis,
        prendre votre smartphone et réserver votre séance de sport de raquettes
        !</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'sport-entre-amis'"
      class="mx-8 text-justify"
    >
      <H1Article>Le sport entre amis avec Fit Arena !</H1Article>
      <TextArticle
        >Il n’est pas toujours facile de se motiver à faire du sport. Mauvais
        temps, infrastructures loin de chez vous, personne pour vous
        accompagner... nombreuses peuvent être les raisons pour ne pas réussir à
        se motiver. Avec Fit Arena, finies les mauvaises excuses !</TextArticle
      ><br />
      <H2Article
        >Une location de salle de sport à l’heure en quelques clics</H2Article
      ><br />
      <TextArticle
        >Avec Fit Arena, bénéficiez d’un lieu unique vous permettant la pratique
        de sports variés selon vos envies. Depuis notre application Fit Arena ou
        notre site internet
        <router-link to="/" class="red">www.fit-arena.fr</router-link>, réservez
        en quelques minutes votre créneau de sport. Terrain de foot, terrain de
        basket, terrain de volley, terrain de badminton... Choisissez le sport
        qui vous plaît, les équipements correspondants s’installeront
        automatiquement à votre arrivée.</TextArticle
      ><br />
      <TextArticle
        >Une fois votre créneau réservé, invitez vos amis en quelques clics !
        Chacun bénéficie d’un QR code d’accès permettant une arrivée en toute
        autonomie. Vous pourrez alors faire du sport entre amis dans un bâtiment
        lumineux et moderne, conçu pour un confort optimal lors de votre
        séance.</TextArticle
      ><br />
      <TextArticle
        >La météo n’est plus un souci : la salle multisports Fit Arena vous
        protège des intempéries tout en vous permettant de bénéficier d’une
        lumière naturelle grâce à notre membrane textile translucide. Vous
        restez ainsi en contact avec l’environnement extérieur.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/Reservation-creneau-Fit-Arena.jpg"
          alt="Réservation sur une Fit Arena"
        />
      </div>
      <br />
      <H2Article>Faire du sport entre amis pour plus de motivation</H2Article
      ><br />
      <TextArticle
        >Si Fit Arena facilite la pratique de sports à plusieurs, c’est parce
        que nous savons tous les bénéficies que cela comporte ! Avoir des
        sessions d’entraînement planifiées à l’avance avec un partenaire est le
        meilleur moyen de ne pas se laisser aller et de réussir à se
        motiver.</TextArticle
      ><br />
      <TextArticle
        >En plus, s’entraîner à plusieurs est quand même plus amusant ! Rien de
        tel qu’un match de foot ou un match de badminton pour passer un bon
        moment en faisant du sport. Un match puis sa revanche : sans vous en
        rendre compte, vous avez déjà transpiré pendant une heure ! Cette
        compétition entre amis est un moyen efficace pour donner le meilleur de
        soi-même, booster sa motivation et ainsi repousser ses
        limites.</TextArticle
      ><br />
      <TextArticle
        >Le sport avec Fit Arena, c’est aussi générer du lien social. Entre la
        communauté sur les réseaux sociaux que nous créons (retrouvez nous sur
        Instagram, Facebook, Tik Tok) et les animations qui peuvent être
        organisées au sein de cette salle omnisports, réserver Fit Arena est
        l’occasion de faire de nouvelles rencontres.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/volleyball-ged298adf9_1920.jpg"
          alt="Réservation sur une Fit Arena"
        />
      </div>
      <br />
      <TextArticle
        >Alors, avez-vous toujours une bonne raison pour ne pas réserver votre
        salle innovante Fit Arena pour faire du sport entre amis ?</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle === 'pourquoi-choisir-fit-arena'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Pourquoi choisir Fit Arena ?</H1Article>
      <TextArticle
        >Qui n’a jamais été frustré de ne pas pouvoir pratiquer le sport qu’il
        aime près de chez lui, de ne pas trouver le temps d’appeler sa salle de
        sport pour réserver un créneau ou encore de vouloir tester plusieurs
        sports pour déterminer celui qui lui plaît le plus ? Fit Arena vous
        propose une solution tout en un pour une pratique sportive fluide,
        rapide et variée.</TextArticle
      ><br />
      <H2Article>Fit Arena, le digital au service du sport</H2Article><br />
      <TextArticle
        >Fit Arena est une salle multisports connectée et intelligente. Elle se
        différencie d’un complexe sportif classique par sa couche numérique et
        ses équipements motorisés qui lui permettent de s’adapter
        automatiquement au sport réservé.</TextArticle
      ><br />
      <H2Article
        >Une salle omnisports équipée pour une pratique sportive
        variée</H2Article
      ><br />
      <TextArticle
        >Avec notre salle connectée, l’espace n’est plus un souci. Grâce aux
        équipements motorisés, un même terrain vous permet de pratiquer
        différents sports collectifs ou de raquettes : football, basketball,
        badminton, volleyball, …. Ce sont donc les équipements qui s’adaptent à
        vous et non plus l’inverse. Vous bénéficiez ainsi d’une offre sportive
        plus variée, une occasion pour découvrir ou redécouvrir certains sports
        !</TextArticle
      ><br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/iStock-671176544.jpeg"
            alt="Pratique du basket avec Fit Arena"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/iStock-1359535391.jpeg"
            alt="Pratique du padel avec Fit Arena"
          />
        </div>
      </div>
      <br />
      <H2Article
        >Un parcours digitalisé pour une plus grande autonomie</H2Article
      ><br />
      <TextArticle
        >« Book, Play, Share ». A travers cette devise, Fit Arena montre qu’elle
        est bien plus qu’une simple salle multisports. C’est une expérience
        interactive et inédite qu’elle offre à ses adhérents.</TextArticle
      ><br />
      <TextArticle
        >Grâce à notre application ou notre site internet, vous avez un aperçu
        en direct des disponibilités de votre salle omnisports. Quelques clics
        suffisent ensuite pour réserver le sport et le créneau de votre choix. A
        plusieurs, le sport c’est plus motivant, non ? Avec Fit Arena, faîtes du
        sport entre amis. Invitez-les via l’application pour une séance de sport
        plus fun. Chacun bénéficie d’un QR Code d’accès lui permettant de
        pouvoir entrer en toute autonomie au sein de la salle. Il ne vous reste
        plus qu’à jouer et vous amuser ! Vos scores et vos performances
        sportives sont enregistrés grâce à une tablette tactile et aux caméras
        sur le terrain. Vous les retrouvez en replay sur votre application et
        pouvez ensuite les partager sur les réseaux sociaux. Réservez, jouez,
        partagez !</TextArticle
      ><br />
      <H2Article>Une communauté qui se fédère</H2Article><br />
      <TextArticle
        >Le partage de vos activités sportives fait partie intégrante de
        l’expérience Fit Arena. Nous sommes présents sur les réseaux sociaux
        (Instagram, Facebook et bientôt Tik Tok) pour partager avec vous vos
        meilleurs moments au sein de nos salles multisports connectées. Utilisez
        le #fitarena, identifiez-nous dans vos posts et stories, nous nous
        ferons un plaisir de les partager ! Nous pourrons donner de la
        visibilité à Fit Arena et créer une vraie communauté. Peut-être
        trouverez-vous ainsi votre prochain coéquipier de sport ?</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/Reseaux-sociaux-Fit-Arena.jpg"
          alt="Réseaux sociaux liés à Fit Arena"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="this.$route.params.indexArticle === 'genese-projet-padel-park'"
      class="mx-8 text-justify"
    >
      <H1Article>Padel Park à Dijon : réserver votre créneau</H1Article>
      <TextArticle
        >Le projet Padel Park x Fit Arena est né d’une double volonté de
        démocratisation du padel et d’optimisation du confort de jeu.
        L’association du Tennis Club Dijonnais à Fit Arena a permis de donner
        une dimension digitale au projet et de faire du Padel Park une salle
        sportive inédite, moderne et lumineuse.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/image1retouch.jpg"
          alt="Padel Park Dijon"
        />
      </div>
      <br />
      <H2Article>Les salles de padel : une disparité régionale</H2Article><br />
      <TextArticle
        >Le padel est un sport en plein développement. Néanmoins, les
        infrastructures dédiées à ce sport se déploient de façon disparate au
        sein du territoire français. Si les régions parisiennes, lyonnaises ou
        du Sud permettent aux amateurs de padel d’aisément pratiquer ce sport ;
        d’autres régions, comme la Bourgogne Franche-Comté, sont plus pauvres en
        salles de padel. La ville de Dijon fait partie des villes avec un manque
        d’infrastructures. C’est donc de ce constat que le Tennis Club Dijonnais
        et son professeur de tennis Thomas Brissaud, ex 41ème joueur français de
        padel, ont décidé de créer Padel Park.</TextArticle
      ><br />
      <H2Article>Un projet de padel qui s’est digitalisé</H2Article><br />
      <TextArticle
        >A l’origine, le projet consistait en la création d’une salle de padel
        classique, composée de deux terrains et d’un couloir de distribution.
        Sous l’impulsion de Fit Arena, la marque innovante de SMC2 leader dans
        la construction et la conception d’ouvrages pour le sport et le jeu, le
        projet s’est rapidement transformé en salle de padel
        connectée.</TextArticle
      ><br />
      <TextArticle
        >Ainsi, au-delà des deux terrains de padel, c’est tout un équipement
        digitalisé qui est à disposition des sportifs afin de leur faire vivre
        une expérience sportive inédite et connectée. La formule magique Fit
        Arena résume cette expérience : « Book, Play, Share ». Grâce à
        l’application, le sportif peut gérer sa réservation de créneau, inviter
        ses amis ou sa famille à le rejoindre puis regarder les replays des
        temps forts de ses matchs. Les tablettes mises à disposition sur les
        terrains permettent de tenir à jour les scores, diffusés sur écran
        géant, et de capturer en vidéo les temps forts des matchs grâce aux
        caméras qui filment les temps de jeu.</TextArticle
      ><br />
      <TextArticle
        >Pour autant, Padel Park a tenu à ce que le digital n’empiète pas sur le
        contact humain. Ainsi, durant les heures pleines, un animateur sera
        présent pour toute demande des joueurs ou conseil
        pédagogique.</TextArticle
      ><br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/FITA_BAPA_E.jpg"
          alt="Padel Park Dijon"
        />
      </div>
      <br />
      <H2Article>L'apport Fit Arena</H2Article><br />
      <TextArticle
        >Fit Arena a permis de donner une dimension digitale au projet et ainsi
        proposer aux habitants une expérience sportive inédite afin de répondre
        aux nouvelles attentes et générer du lien social avec le numérique.
        L’autonomie procurée grâce au digital permet de proposer une large
        amplitude horaire aux utilisateurs, avec une salle ouverte 7 jours sur
        7, de 7h15 à 23h15.</TextArticle
      ><br />
      <TextArticle
        >Le partage des performances sur les réseaux sociaux permettra de
        fédérer une vraie communauté autour de ce sport et de développer ainsi
        sa notoriété.</TextArticle
      ><br />
      <TextArticle
        >Inédit, ce projet promet un parcours digital unique aux sportifs, leur
        offrant une pratique du padel libérée et autonome grâce à une
        application dédiée. Le lundi 18 avril, réservez votre créneau et venez
        découvrir ou redécouvrir le padel au Padel Park x Fit Arena. Pour les
        curieux, l’inauguration de la salle aura lieu le week-end du 16 et 17
        avril avec, au programme, présentation de la salle, match de padel avec
        des joueurs du top 100 et initiation à ce sport de
        raquette.</TextArticle
      ><br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle ===
        'prototype-fit-arena-grandeur-nature'
      "
      class="mx-8 text-justify"
    >
      <H1Article>Un prototype Fit Arena grandeur nature !</H1Article>
      <TextArticle
        >Cinq ans après sa distinction par la Banque Publique d’Investissement
        dans le cadre du Concours de l’Innovation Numérique, le projet Fit Arena
        a pris soudain une ampleur plus importante avec la création d’un
        prototype à grande échelle.</TextArticle
      ><br />
      <TextArticle
        >Afin d’éprouver matériellement l’ensemble des équipements de
        l’écosystème numérique et de réaliser des tests in situ, quoi de mieux
        que de réaliser une « vraie » Fit Arena à vocation expérimentale
        ?</TextArticle
      ><br />
      <TextArticle
        >SMC2 a ainsi créé ce prototype dans ses locaux de la région lyonnaise,
        en début d’année 2021, avec en tête le souhait d’en faire également un
        démonstrateur pour les collectivités.</TextArticle
      >
      <TextArticle
        >Ces dernières, vivement intéressées par le concept, ne demandaient qu’à
        pouvoir visiter une telle infrastructure.</TextArticle
      >
      <TextArticle
        >L’entreprise a d’ailleurs reçu en juin 2021 la visite de l’Agence
        Nationale du Sport, séduite par le projet.</TextArticle
      ><br />
      <TextArticle
        >Comme dans un vrai bâtiment Fit Arena, le prototype est équipé de
        lecteurs de QR codes et de tourniquets pour le contrôle d’accès. Les
        tablettes sont présentes sur le terrain, pour l’édition des feuilles de
        match, ainsi que les caméras pour les replays vidéos et les enceintes
        pour l’ambiance sonore. Un grand écran permet de diffuser en direct la
        feuille de match ou des cours de sport. Une des innovations principales
        du concept Fit Arena, à savoir les équipements sportifs motorisés, sont
        également en place. Ces équipements s’installent automatiquement selon
        le sport réservé... C’est presque magique ! Lorsqu’ils sont en
        mouvement, un système de capteurs garantit leur arrêt s’ils détectent du
        mouvement sur le terrain.</TextArticle
      ><br />
      <TextArticle
        >Fit Arena permet notamment la pratique du futsal ou du foot à 5, du
        handball, du basket, du volley, du badminton, et même du padel dans
        certaines salles. Des pratiques qui ne sont en revanche pas possibles
        dans ce prototype, dont la taille du terrain n’est pas à l’échelle, pour
        des raisons de place dans les locaux SMC2. Les salariés de l’entreprise
        peuvent toutefois y pratiquer du fitness, du yoga, du basket 3x3 ou
        encore du tennis de table. Des pauses déjeuner bien sportives donc
        !</TextArticle
      ><br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/1_IMG_0023.png"
            alt="Prototype Fit Arena région lyonnaise"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/IMG_0011.png"
            alt="Prototype Fit Arena région lyonnaise"
          />
        </div>
      </div>
      <br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/IMG_0018.png"
            alt="Prototype Fit Arena région lyonnaise"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/IMG_0020.png"
            alt="Prototype Fit Arena région lyonnaise"
          />
        </div>
      </div>
      <br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/IMG_0027.png"
          alt="Prototype Fit Arena région lyonnaise"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>

    <div
      v-else-if="
        this.$route.params.indexArticle ===
        'creation-ajaccio-salle-de-sports-sable'
      "
      class="mx-8 text-justify"
    >
      <H1Article
        >Création à Ajaccio d’une salle de sports de sable avec équipements
        sportifs amovibles</H1Article
      >
      <TextArticle
        >Nous achevons à Ajaccio la réalisation d'une salle dédiée aux sports de
        sable, pour le Centre du sport et de la Jeunesse Corse. Beach volley,
        beach soccer, et même beach handball peuvent être pratiqués dans ce
        lieu.</TextArticle
      ><br />
      <TextArticle
        >Avec le sable sous nos pieds on se croirait plus à la plage que dans un
        gymnase, à ceci près qu'on peut y jouer toute l'année, bien à l'abri de
        la couverture en membrane textile translucide.</TextArticle
      ><br />
      <TextArticle
        >Cette dernière filtre les UV tout en laissant passer la lumière
        naturelle. Les façades en membrane textile microperforée protègent du
        vent et des intempéries tout en donnant l'impression de jouer en
        extérieur, avec une visibilité parfaite sur
        l'environnement.</TextArticle
      >
      <TextArticle
        >La façade persienne ajourée permet une ventilation naturelle sur les 4
        faces du bâtiment.</TextArticle
      ><br />
      <TextArticle
        >L'expérience d'une pratique multisports en un même lieu est optimisée
        par la présence d'équipements sportifs amovibles (filets de volley,
        cages de foot et de handball), qui s'adaptent au sport pratiqué. Un
        premier pas vers Fit Arena, la salle multisports autonome et
        connectée...</TextArticle
      ><br />
      <TextArticle
        >En attendant l'ouverture du bâtiment prévue pour début novembre, voici
        en exclusivité quelques photos de la fin du chantier.</TextArticle
      ><br />
      <div class="flex flex-col sm:flex-row justify-around">
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/IMG_5387.png"
            alt="Fit Arena Ajaccio"
          />
        </div>
        <div class="sm:w-5/12">
          <img
            class="article-img"
            src="../../assets/img/mock/cmsActu/IMG_5396.png"
            alt="Fit Arena Ajaccio"
          />
        </div>
      </div>
      <br />
      <div class="flex justify-center items-center">
        <img
          class="article-img"
          src="../../assets/img/mock/cmsActu/IMG_5398.png"
          alt="Fit Arena Ajaccio"
        />
      </div>
      <br />
      <DoiButton @click="back()" class="md:w-3/12 mx-auto"
        >RETOUR AUX ACTUALITÉS</DoiButton
      >
    </div>
  </div>
</template>

<script>
import articlesPadel from "@/data/mock/articles-padel-park.json";
import H1Article from "@/components/atomic/H1Article";
import H2Article from "@/components/atomic/H2Article";
import H3Article from "@/components/atomic/H3Article";
import TextArticle from "@/components/atomic/TextArticle";

export default {
  name: "FitArenaArticlePadelPark",
  data() {
    return {
      articlesPadel: articlesPadel,
    };
  },
  components: {
    H1Article,
    H2Article,
    H3Article,
    TextArticle,
  },
  mounted() {
    if (
      this.$route.params.indexArticle ===
      "six-mois-apres-ouverture-fit-arena-padel-park"
    ) {
      document.title =
        "Padel Park Fit Arena : bilan 6 mois après son ouverture";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Thomas Brissaud, exploitant de Padel Park Fit Arena, témoigne de sa satisfaction 6 mois après l’ouverture de cette salle de padel connectée."
        );
    }
    if (
      this.$route.params.indexArticle ===
      "la-rentree-occasion-de-faire-du-sport"
    ) {
      document.title =
        "Fit Arena, la salle multisports connectée pour une rentrée sportive";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Vivez une rentrée sportive avec Fit Arena, votre salle multisports dotée d’une couche numérique pour une expérience unique."
        );
    }
    if (
      this.$route.params.indexArticle ===
      "toutes-les-bonnes-raisons-pour-se-mettre-aux-sports-de-raquettes"
    ) {
      document.title =
        "Toutes les bonnes raisons pour se mettre aux sports de raquettes avec Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Avec Fit Arena, bénéficiez d’une salle multisports, idéale pour découvrir plusieurs sports de raquettes."
        );
    }
    if (this.$route.params.indexArticle === "sport-entre-amis") {
      document.title =
        "Sport entre amis - Salle multisports connectée Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Avec Fit Arena, réservez votre terrain de foot, de basket ou de badminton en quelques clics pour une séance de sport conviviale et connectée entre amis."
        );
    }
    if (this.$route.params.indexArticle === "pourquoi-choisir-fit-arena") {
      document.title = "Pourquoi choisir Fit Arena ?";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Fit Arena vous propose une solution tout en un pour une pratique sportive fluide, rapide et variée."
        );
    }
    if (this.$route.params.indexArticle === "genese-projet-padel-park") {
      document.title =
        "Padel Park à Dijon – Réservez votre salle de padel connectée avec Fit Arena";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Découvrez le Padel Park Fit Arena de Dijon, un terrain de padel connecté pour faire votre partie de padel en toute liberté. Réservez votre séance !"
        );
    }
    if (
      this.$route.params.indexArticle === "prototype-fit-arena-grandeur-nature"
    ) {
      document.title = "Un prototype Fit Arena grandeur nature !";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Cinq ans après sa distinction par la Banque Publique d’Investissement, le projet Fit Arena a pris soudain une ampleur plus importante avec la création d’un prototype à grande échelle."
        );
    }
    if (
      this.$route.params.indexArticle ===
      "creation-ajaccio-salle-de-sports-sable"
    ) {
      document.title = "Salle de sports de sable ouverte au public";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Nous achevons à Ajaccio la réalisation d'une salle dédiée aux sports de sable. Beach volley, beach soccer, et même beach handball peuvent être pratiqués dans ce lieu."
        );
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
      setTimeout(() => {
        this.$router.push("#actualites");
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.font {
  font-family: $doi--text-font-family5;
}

@media (max-width: $doi-breakpoint-mobile) {
  .article-img {
    margin-bottom: 0.75rem;
  }
}
</style>
