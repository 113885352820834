export default {
  props: {
    fontSize: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    fontSizeStyle() {
      if (typeof this.fontSize === "number")
        return " font-size" + this.fontSize;
    },
  },
};
