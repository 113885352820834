<template>
  <span :style="sizeStyle" class="doi-image">
    <img :class="imgClass" class="doi-image-img" :src="src" />
  </span>
</template>

<script>
export default {
  name: "DoiImage",
  props: {
    src: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "2rem",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    cover: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sizeStyle() {
      return "height:" + this.size + ";" + "width:" + this.size + ";";
    },
    imgClass() {
      const classList = [];
      if (this.rounded) classList.push("doi-image-img-rounded");
      if (this.cover) classList.push("doi-image-img-cover");
      return classList.join(" ");
    },
  },
};
</script>

<style scoped>
.doi-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.doi-image-img {
  width: 100%;
  height: 100%;
}

.doi-image-img-rounded {
  border-radius: 9999px;
}

.doi-image-img-cover {
  object-fit: cover;
}
</style>
