<template>
  <div>
    <section>
      <transition name="slide-fade">
        <slot></slot>
      </transition>
    </section>
    <footer>
      <ShareSection />
      <Footer />
    </footer>
  </div>
</template>

<script>
import ShareSection from "@/views/share/ShareSection";
import Footer from "@/components/organisms/Footer";

export default {
  components: {
    ShareSection,
    Footer,
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
