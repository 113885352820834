<template>
  <div class="absolute h-full w-full z-10 bg-opacity-50 bg-gray-400">
    <div class="relative flex flex-1 top-1/3 justify-center h-full w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoiForeground",
};
</script>
