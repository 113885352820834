<template>
  <div class="mx-auto">
    <div class="mt-2 mb-12 flex flex-wrap 2k:ml-28 4k:ml-72">
      <div
        class="w-1/3 flex items-end"
        v-for="(sport, index) in sportList"
        :key="index"
      >
        <div
          @click="url(sport.name)"
          class="flex flex-col md:flex-row flex-1 justify-center md:justify-start items-center cursor-pointer"
        >
          <div
            class="w-20 h-20 bg-white rounded-lg shadow-xl mx-3 mt-3 p-2 border-2 border-white hover:border-fa-red duration-200"
          >
            <img :src="require(`@/assets/icons/utils/sports/${sport.icon}`)" />
          </div>
          <div class="md:border-b-2 md:pl-6 md:bottom-5 md:-right-1 md:pr-8">
            <DoiText
              class="mt-1 text-center text-sm lg:text-xl"
              :fontFamily="1"
              :textColor="3"
              >{{ sport.name }}</DoiText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sport from "@/data/sportList.json";

export default {
  name: "SportList",
  data() {
    return {
      sportList: [],
    };
  },
  created() {
    this.sportList = sport;
  },
  methods: {
    url(sport) {
      if (sport == "FOOT5") {
        sport = "zoom-foot-5";
      }
      if (sport == "PADEL") {
        sport = "infos-padel";
      }
      if (sport == "BASKET 3X3") {
        sport = "basket-3x3";
      }
      if (sport == "BADMINTON") {
        sport = "salle-badminton-connectee";
      }
      if (sport == "TENNIS") {
        sport = "les-terrains-de-tennis-couvert-Fit-Arena";
      }
      if (sport == "PING PONG") {
        sport = "le-tennis-de-table-d-activite-de-loisirs-a-sport-olympique";
      }
      if (sport == "FUTSAL") {
        sport = "futsal-sport-derive-du-football";
      }
      if (sport == "VOLLEY" || sport == "VOLLEYBALL") {
        sport = "terrain-de-volley-a-louer";
      }
      if (sport == "BASKET 5X5") {
        sport = "salle-basket-5x5";
      }
      if (sport == "HANDBALL") {
        sport = "nouvelle-salle-handball";
      }
      if (sport == "YOGA") {
        sport = "yoga-agit-sur-le-corps-et-esprit";
      }
      if (sport == "FITNESS") {
        sport = "histoire-du-fitness";
      }
      if (
        sport == "GOLF" ||
        sport == "CYCLISME" ||
        sport == "ESCALADE" ||
        sport == "FOOTBALL"
      ) {
        return;
      }
      this.$router.push(`/fit-arena/article-sport/${sport}`);
    },
  },
};
</script>
