<template>
  <div class="background-app">
    <transition name="slide-fade">
      <slot></slot>
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.background-app {
  background-color: #fff;
  height: 100%;
}
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
