const initState = () => {
  return {
    lastRoute: null,
  };
};

export default {
  namespaced: true,
  namespace: "routeHistory",
  state: initState(),
  actions: {
    setLastRoute({ commit }, payload) {
      commit("setLastRoute", payload);
    },
  },
  getters: {
    lastRoute: (state) => {
      return state.lastRoute;
    },
  },
  mutations: {
    setLastRoute: (state, payload) => {
      state.lastRoute = payload;
    },
  },
};
