import Network from '@/utils/Network'

const url1 = process.env.VUE_APP_API_URL

const getReservationGroupe = (params) => {
  return Network.execute({
    method: 'GET',
    url: url1 + 'reservations/groupe',
    params
  })
}

const getScore = (contactId) => {
  return Network.execute({
    method: 'GET',
    url: url1 + 'equipier/partie',
    params: {
      nIdEquipier: contactId
    }
  })
}

const getScoreDetail = (sIdPartie) => {
  return Network.execute({
    method: 'GET',
    url: url1 + 'parties',
    params: {
      sIdPartie: sIdPartie
    }
  })
}

const getTempsFort = (sIdPartie) => {
  return Network.execute({
    method: 'GET',
    url: url1 + 'parties/temps-fort',
    params: {
      sIdPartie: sIdPartie
    }
  })
}

const getVideoTempsFort = (params) => {
  return Network.execute({
    method: 'GET',
    url: url1 + '/enovon/recuperer-video',
    params
  })
}

const getInfoEquipe = (idPartie) => {
  return Network.execute({
    method: 'GET',
    url: url1 + 'parties_infos/recuperer_infos_equipes?sIdPartie=' + idPartie,
    secure: true
  })
}

export { getReservationGroupe, getScore, getScoreDetail, getTempsFort, getVideoTempsFort, getInfoEquipe }
