import { valideInvitationV2 } from "@/api/BookingService";
import router from "@/router";
import Vue from "vue";

const initState = () => {
  let state = {
    isInvited: false,
    id_reservation: null,
    jeton_invitation: null,
    response_data: [],
    toSave: false,
  };
  if (localStorage.getItem("invitation")) {
    try {
      state = JSON.parse(localStorage.getItem("invitation"));
    } catch (err) {}
  }
  return state;
};

export default {
  name: "invitation",
  namespaced: true,
  state: initState(),
  mutations: {
    setInvitation(state, payload) {
      state.isInvited = true;
      state.id_reservation = payload.id_reservation;
      state.jeton_invitation = payload.jeton_invitation;
      state.response_data = payload.response_data ? payload.response_data : [];
      state.toSave =
        typeof payload.toSave === "boolean" ? payload.toSave : state.toSave;

      localStorage.setItem("invitation", JSON.stringify(state));
    },
    removeInvitation(state) {
      state.isInvited = false;
      state.id_reservation = null;
      state.jeton_invitation = null;
      state.response_data = [];
      state.toSave = false;

      if (localStorage.getItem("invitation") !== null) {
        localStorage.removeItem("invitation");
      }
    },
  },
  actions: {
    setInvitation({ commit }, payload) {
      commit("setInvitation", payload);
    },
    removeInvitation({ commit }) {
      commit("removeInvitation");
    },
    async valideInvitation(
      { dispatch },
      { idReservation, jetonInvitation, responseData }
    ) {
      const result = await valideInvitationV2({
        uuidReservation: idReservation,
        uuidInvitation: jetonInvitation,
        reponses: responseData,
      });

      if (result !== undefined && result !== null) {
        if (result.sLibelleMessage) {
          if (screen.width > 640) {
            Vue.$toast.info(result.sLibelleMessage);
          } else {
            Vue.$toast.info(result.sLibelleMessage, {
              position: "top",
            });
          }
        } else if (result.succes) {
          const message =
            "La réponse à l'invitation a bien été prise en compte";
          if (screen.width > 640) {
            Vue.$toast.success(message);
          } else {
            Vue.$toast.success(message, {
              position: "top",
            });
          }

          dispatch("setInvitation", {
            id_reservation: idReservation,
            jeton_invitation: jetonInvitation,
            response_data: responseData,
            toSave: false,
          });
          return true;
        } else {
          throw new Error("Erreur lors de la validation de l'invitation");
        }
      }
    },
    async valideInvitationPostCo({ state, rootGetters, commit }) {
      if (
        state &&
        state.id_reservation &&
        state.jeton_invitation &&
        state.response_data &&
        state.response_data.length === 1 &&
        state.toSave
      ) {
        const response = state.response_data[0];
        const contactId = rootGetters["UserModule/get_contact_id"];
        if (contactId) {
          response.nIdContact = contactId;
          const responseData = [response];
          const result = await valideInvitation({
            sIdReservation: state.id_reservation,
            sJetonInvitation: state.jeton_invitation,
            aReponse: responseData,
          });
          if (result !== undefined && result !== null) {
            if (result.bSucces) {
              const message =
                "La réponse à l'invitation a bien été prise en compte";
              if (screen.width > 640) {
                Vue.$toast.success(message);
              } else {
                Vue.$toast.success(message, {
                  position: "top",
                });
              }

              commit("setInvitation", {
                id_reservation: state.id_reservation,
                jeton_invitation: state.jeton_invitation,
                response_data: responseData,
                toSave: false,
              });

              router.push("/profile?q=reservation");
            }
          }
        }
      }
    },
  },
  getters: {
    invitation: (state) => {
      return state;
    },
  },
};
