<template>
  <a
    v-if="href"
    :class="textColorStyle + fontFamilyStyle + fontSizeStyle + fontWeightStyle"
    class="doi-link"
  >
    <slot></slot>
  </a>
  <router-link
    v-else
    :to="link"
    :class="textColorStyle + fontFamilyStyle + fontSizeStyle + fontWeightStyle"
    class="doi-link"
  >
    <slot></slot>
  </router-link>
</template>

<script>
import textColorMixin from "@/components/mixins/textColorMixin.js";
import fontFamilyMixin from "@/components/mixins/fontFamilyMixin.js";
import fontSizeMixin from "@/components/mixins/fontSizeMixin.js";
import fontWeightMixin from "@/components/mixins/fontWeightMixin.js";

export default {
  name: "DoiLink",
  mixins: [textColorMixin, fontFamilyMixin, fontSizeMixin, fontWeightMixin],
  props: {
    link: String,
    href: String,
  },
  methods: {
    onClick($event) {
      this.$emit("click", $event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.doi-link {
  font-family: $doi--text-font-family4;
  text-decoration: underline;
  color: $doi--color-color4;
  cursor: pointer;
}
.doi-link:hover {
  color: black;
}
</style>
