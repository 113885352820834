import Network from '@/utils/Network'

const url1 = process.env.VUE_APP_API_URL
const url2 = process.env.VUE_APP_API_URL_V2

const setValidationInfo = (payload) => {
  return Network.execute({
    method: 'POST',
    url: url1 + 'video/securite',
    payload: payload,
    secure: true
  })
}

const setPhoneNumber = (payload) => {
  return Network.execute({
    method: 'POST',
    url: url1 + 'portable/enregistrement_portable',
    payload: payload,
    secure: true
  })
}

const setPhoneNumberV2 = (payload) => {
  return Network.execute({
    method: 'POST',
    url: url2 + 'portable/enregistrement_portable',
    payload: payload,
    secure: true
  })
}

const setValidationPhoneNumber = (payload) => {
  return Network.execute({
    method: 'POST',
    url: url1 + 'portable/controle_portable',
    payload: payload,
    secure: true
  })
}

const setValidationPhoneNumberV2 = (payload) => {
  return Network.execute({
    method: 'POST',
    url: url2 + 'portable/controle_portable',
    payload: payload,
    secure: true
  })
}

const verifyDiscountCode = (code) => {
  return Network.execute({
    method: 'GET',
    url: url2 + `verify-discount/${code}`,
    secure: true
  })
}

export { setValidationInfo, setPhoneNumber, setValidationPhoneNumber, setPhoneNumberV2, setValidationPhoneNumberV2, verifyDiscountCode }