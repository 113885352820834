<template>
  <p
    v-if="type === 'p'"
    :class="
      fontFamilyStyle +
      fontSizeStyle +
      fontWeightStyle +
      textColorStyle +
      italicClass
    "
  >
    <slot></slot>
  </p>
  <span
    v-else-if="type === 'span'"
    :class="
      fontFamilyStyle +
      fontSizeStyle +
      fontWeightStyle +
      textColorStyle +
      italicClass
    "
  >
    <slot></slot>
  </span>
</template>

<script>
import textColorMixin from "@/components/mixins/textColorMixin.js";
import fontFamilyMixin from "@/components/mixins/fontFamilyMixin.js";
import fontSizeMixin from "@/components/mixins/fontSizeMixin.js";
import fontWeightMixin from "@/components/mixins/fontWeightMixin.js";

export default {
  name: "DoiText",
  mixins: [textColorMixin, fontFamilyMixin, fontSizeMixin, fontWeightMixin],
  props: {
    type: {
      type: String,
      default: "p",
    },
    italic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    italicClass() {
      return this.italic ? " italic" : "";
    },
  },
};
</script>
