export default {
  props: {
    bgColor: {
      type: Number,
      default: 0,
    },
    bgCardColor: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    bgColorStyle() {
      if (typeof this.bgColor === "number") return " bg" + this.bgColor;
    },
    bgCardColorStyle() {
      if (typeof this.bgCardColor === "number")
        return " bg-card" + this.bgCardColor;
    },
  },
};
