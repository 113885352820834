export default {
  props: {
    bgColorTable: Number,
  },
  computed: {
    bgColorTableClass() {
      if (typeof this.bgColorTable === "number")
        return " bg-table" + this.bgColorTable;
      return "";
    },
  },
};
