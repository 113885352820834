<template>
  <div>
    <div v-if="spinnerSize === 0" class="little-spin" v-show="show">
      <div :class="littleSpinnerColorStyle"></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div v-if="spinnerSize === 1" class="big-spin-container">
      <div :class="objectClass + bigSpinnerColorStyle"></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import spinnerColorMixin from "@/components/mixins/spinnerColorMixin.js";

export default {
  name: "DoiSpinner",
  mixins: [spinnerColorMixin],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    spinnerSize: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    objectClass() {
      return this.isAbsolute ? "big-spin" : "big-spin-block";
    },
  },
};
</script>

<style scoped>
.little-spin,
.big-spin-container {
  display: inline-block;
  position: relative;
}
.little-spin {
  width: 15px;
  top: -14px;
}
.big-spin-container {
  width: 120px;
  top: 0px;
}

.little-spin div,
.big-spin-block,
.big-spin {
  box-sizing: border-box;
  display: block;
  margin: 0px;
  border-radius: 50%;
}
.little-spin div {
  position: absolute;
  width: 19px;
  height: 19px;
  animation: little-spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.big-spin-block {
  width: 120px;
  height: 120px;
  animation: big-spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.big-spin {
  position: absolute;
  width: 120px;
  height: 120px;
  animation: big-spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.little-spin div:nth-child(1),
.big-spin:nth-child(1),
.big-spin-block:nth-child(1) {
  animation-delay: -0.45s;
}
.little-spin div:nth-child(2),
.big-spin:nth-child(2),
.big-spin-block:nth-child(2) {
  animation-delay: -0.3s;
}
.little-spin div:nth-child(3),
.big-spin:nth-child(3),
.big-spin-block:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes big-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes little-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
