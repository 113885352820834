import axios from "@/utils/NetworkConfig";

const Network = {
  execute({
    method = "GET",
    secure = true,
    url = "",
    params = {},
    payload = {},
    headers = {},
    responseType = "json",
    beforeSend = (data, headers) => {
      return data;
    },
  }) {
    params = { ...params };

    const requestHeader = { ...axios.defaults.headers, ...headers };

    const config = {
      method,
      url,
      secure,
      transformRequest: beforeSend,
      params,
      responseType,
      headers: requestHeader,
      onUploadProgress: (progressEvent) => {},
      onDownloadProgress: (progressEvent) => {},
    };

    requestHeader["Content-Type"] = "application/json";

    if (
      method === "POST" ||
      method === "DELETE" ||
      method === "PUT" ||
      method === "PATCH"
    ) {
      config.data = {};
      if (payload instanceof FormData) {
        config.data = payload;
      } else {
        config.data = JSON.stringify(payload);
      }
    }

    return axios(config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default Network;
