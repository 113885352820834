<template>
  <div
    class="flex items-center flex-col relative sm:mb-12 mb-2 rounded border border-fa-dark-gray bg-white 4k:border-4"
  >
    <div>
      <img class="img-size" :src="fitarenaImg" />
    </div>
    <div class="mt-4">
      <DoiText :textColor="1" :fontSize="4" class="text-center libelle-salle">{{
        fitArena.libelleSalle
      }}</DoiText>
    </div>
    <div class="mb-10">
      <DoiText :textColor="0">{{ fitArena.legende }}</DoiText>
    </div>
    <div class="absolute -bottom-5">
      <DoiButton :link="fitArenaRoute" @click="getFitArenaInfos()" :bgHover="5"
        >RÉSERVER</DoiButton
      >
    </div>
  </div>
</template>

<script>
import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

export default {
  name: "FitArenaCard",
  props: {
    fitArena: Object,
  },
  computed: {
    fitArenaRoute() {
      if (this.fitArena && this.fitArena.cle)
        return "/fit-arena/" + this.fitArena.cle;
      return "";
    },
    fitarenaImg() {
      if (this.fitArena && this.fitArena.urlEcusson)
        return generateImageUrlV2(this.fitArena.urlEcusson);
      else return require("@/assets/img/fitarena/fitarena-bellevue.png");
    },
  },
  methods: {
    getFitArenaInfos() {
      localStorage.setItem("idFA", this.fitArena.idFitArena);
    },
  },
};
</script>

<style scoped>
.img-size {
  width: 350px;
  height: 245px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.libelle-salle {
  max-width: 325px;
  text-align: center;
}
</style>
