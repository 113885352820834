<template>
  <table class="w-full">
    <thead class="table-fixed">
      <DoiTableRow>
        <slot name="table-head" />
      </DoiTableRow>
    </thead>
    <tbody>
      <slot name="table-body" />
    </tbody>
  </table>
</template>

<script>
export default {
  name: "DoiTableNew",
};
</script>
