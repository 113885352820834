<template>
  <td
    class="border-collapse border-gray-900 text-xs md:text-basic border-2 px-6"
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "DoiLineTable",
};
</script>
