<template>
  <span>
    <div v-if="!isFetched" class="flex justify-center">
      <DoiSpinner :spinnerSize="1" :isAbsolute="false" />
    </div>
    <template v-else>
      <div v-if="!paymentAlias" class="text-center">
        <DoiText :textColor="1" :fontFamily="4"
          >Aucun moyen de paiement enregistré</DoiText
        >
      </div>
      <div class="flex flex-col mb-12 lg:mb-16 relative">
        <PaymentCard class="mx-auto" v-if="paymentAlias" :card="paymentAlias" />
        <div class="mt-4">
          <DoiButton
            @click="addPayment"
            class="w-full sm:w-1/2 lg:w-4/12 mx-auto"
            :bgColor="2"
            :fontFamily="5"
            >{{ textAddPayment }}</DoiButton
          >
          <PaymentModal
            @close="showPaymentModal = false"
            :open="showPaymentModal"
            :v2="v2"
          />
        </div>
      </div>
    </template>
  </span>
</template>

<script>
import PaymentModal from "@/views/reglements/PaymentModal";
import PaymentCard from "@/views/reglements/PaymentCard";

export default {
  name: "PaymentMeans",
  data() {
    return {
      showPaymentModal: false,
      paymentList: [],
    };
  },
  components: {
    PaymentModal,
    PaymentCard,
  },
  props: {
    v2: String,
  },
  mounted() {
    if (this.v2 === "false")
      this.$store.dispatch("PaymentModule/retrievePaymentAlias");
    else this.$store.dispatch("PaymentModule/retrievePaymentAliasV2");
  },
  methods: {
    togglePaymentModal() {
      this.showPaymentModal = !this.showPaymentModal;
    },
    addPayment() {
      this.togglePaymentModal();
    },
  },
  computed: {
    isFetched() {
      return this.$store.getters["PaymentModule/isFetched"];
    },
    paymentAlias() {
      return this.$store.getters["PaymentModule/alias"];
    },
    textAddPayment() {
      return this.paymentAlias
        ? "MODIFIER MON MOYEN DE PAIEMENT"
        : "AJOUTER UN MOYEN DE PAIEMENT";
    },
  },
};
</script>
