<template>
  <div>
    <router-link to="/" class="flex justify-center mt-4">
      <img
        class="w-52 logo"
        src="@/assets/img/logo-fitarena.png"
        alt="Fit Arena"
      />
    </router-link>
    <div class="text-center">
      <DoiHeader class="pt-4">MON MATCH - {{ info[0] }}</DoiHeader>
      <DoiText :textColor="1" :fontSize="5" class="text-center">{{
        info[1]
      }}</DoiText>
      <DoiText :textColor="0" :fontSize="5" :fontFamily="1"
        >SCORE FINAL : {{ info[2] }}</DoiText
      >
    </div>
    <div
      v-for="url in url"
      :key="url.id"
      class="flex flex-1 items-center justify-center text-center w-full sm:w-4/12 mx-auto mt-4"
    >
      <DoiText :textColor="0" :fontSize="4">{{
        url.score.split(":")[0]
      }}</DoiText>
      <span class="flex flex-1 items-center"><span class="line-score" /></span>
      <DoiText :textColor="1" :fontSize="3" :fontFamily="5">PERIODE 1</DoiText>
      <span class="flex flex-1 items-center"><span class="line-score" /></span>
      <DoiText :textColor="0" :fontSize="4">{{
        url.score.split(":")[1]
      }}</DoiText>
    </div>
    <!-- <div v-if="url[0] == 'Futsal' || url[0] == 'Basket 5x5' || url[0] == 'Basket 3x3'" class="mb-6">
            <div class="flex flex-1 w-full sm:w-4/12 mx-auto mt-4 justify-between">
                <div>
                    <DoiText v-if="parseInt(url[3], 10) < 60" :textColor="3" :fontFamily="1" :fontSize="4">{{url[3]}}''</DoiText>
                    <DoiText v-else :textColor="3" :fontFamily="1" :fontSize="4">{{ Math.trunc(url[3] / 60) }}' {{ Math.trunc(url[3] % 60) }}''</DoiText>
                </div>
                <DoiText :textColor="3" :fontFamily="4" :fontSize="4" class="capitalize">{{url[4]}} de {{url[5]}}</DoiText>
                <DoiText :textColor="3" :fontFamily="1" :fontSize="4" class="ml-10">{{url[6]}}</DoiText>
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  name: "ShareReplay",
  data() {
    return {
      url: this.$route.params.code,
      info: this.$route.params.infos,
    };
  },
  props: ["code", "infos"],
  mounted() {
    this.decode;
  },
  computed: {
    decode() {
      this.url = window.atob(this.url);
      this.url = JSON.parse(this.url);
      this.info = window.atob(this.info);
      this.info = this.info.split("&");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.line-score {
  height: 2px;
  width: 50%;
  background-color: $doi--color-color0;
  margin-right: auto;
  margin-left: auto;
}
</style>
