<template>
  <div>
    <div class="my-10">
      <DoiText
        :fontSize="7"
        :fontFamily="0"
        class="text-center bg-gray-500 title-background-desktop py-2 4k:text-9xl"
        >PLAY !</DoiText
      >
      <div class="hidden md:block">
        <img
          src="@/assets/img/presentation/Visuel-Play.png"
          alt="image de présentation d'une salle multisports connéctée Fit Arena"
          class="img-presentation-desktop my-2.5 mx-auto"
        />
        <div class="flex justify-center">
          <router-link class="button gray" to="/mon-parcours-dans-la-salle"
            >MON PARCOURS DANS LA SALLE</router-link
          >
        </div>
      </div>
      <div class="md:hidden">
        <img
          src="@/assets/img/presentation/PlayGym.png"
          alt="image de présentation d'une salle multisports connéctée Fit Arena"
        />
        <div class="flex justify-center">
          <router-link class="button gray" to="/mon-parcours-dans-la-salle"
            >MON PARCOURS DANS LA SALLE</router-link
          >
        </div>
        <div class="flex mt-8">
          <div class="flex pl-2 w-20 h-20">
            <img
              src="@/assets/icons/utils/play/tourniquet.png"
              class="image"
              alt="tourniquet d'accès à la salle de sport Fit Arena"
            />
          </div>
          <div class="flex flex-col px-4 items-start">
            <DoiText
              class="flex justify-center items-center w-5 h-5 bg-fa-red rounded-full pt-0.5"
              :fontFamily="1"
              >1</DoiText
            >
            <DoiText
              :fontFamily="1"
              :textColor="3"
              :fontSize="3"
              class="uppercase"
              >Tourniquet d'accès</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >avec lecteur QR Code</DoiText
            >
          </div>
        </div>
        <div class="flex mt-8">
          <div class="flex pl-2 w-20 h-20">
            <img
              src="@/assets/icons/utils/play/tablette-tactile.png"
              class="image"
              alt="tablettes tactiles connectées pour la feuille de match en temps réel dans la Fit Arena"
            />
          </div>
          <div class="flex flex-col px-4 items-start">
            <DoiText
              class="flex justify-center items-center w-5 h-5 bg-fa-red rounded-full pt-0.5"
              :fontFamily="1"
              >2</DoiText
            >
            <DoiText
              :fontFamily="1"
              :textColor="3"
              :fontSize="3"
              class="uppercase"
              >Tablettes tactiles connectées</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >pour édition de la feuille de match en temps réel avec scoring et
              capture vidéo</DoiText
            >
          </div>
        </div>
        <div class="flex mt-8">
          <div class="flex pl-2 w-20 h-20">
            <img
              src="@/assets/icons/utils/play/camera.png"
              class="image"
              alt="caméras pour filmer les matchs dans la Fit Arena"
            />
          </div>
          <div class="flex flex-col px-4 items-start">
            <DoiText
              class="flex justify-center items-center w-5 h-5 bg-fa-red rounded-full pt-0.5"
              :fontFamily="1"
              >3</DoiText
            >
            <DoiText
              :fontFamily="1"
              :textColor="3"
              :fontSize="3"
              class="uppercase"
              >Caméras</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >les matchs sont filmés et disponibles en replay sur
              l’application</DoiText
            >
          </div>
        </div>
        <div class="flex mt-8">
          <div class="flex pl-2 w-20 h-20">
            <img
              src="@/assets/icons/utils/play/ecran.png"
              class="image"
              alt="écran 3m x 2m pour le suivi du match en temps réel dans la Fit Arena"
            />
          </div>
          <div class="flex flex-col px-4 items-start">
            <DoiText
              class="flex justify-center items-center w-5 h-5 bg-fa-red rounded-full pt-0.5"
              :fontFamily="1"
              >4</DoiText
            >
            <DoiText
              :fontFamily="1"
              :textColor="3"
              :fontSize="3"
              class="uppercase"
              >Écran 3m x 2m</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >- feuille de match en temps réel</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >- coaching sportif</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >- contenus pédagogiques</DoiText
            >
          </div>
        </div>
        <div class="flex mt-8">
          <div class="flex pl-2 w-20 h-20">
            <img
              src="@/assets/icons/utils/play/devices.png"
              class="image"
              alt="tablette tactile et écran pour revivre les temps forts du match"
            />
          </div>
          <div class="flex flex-col px-4 items-start">
            <DoiText
              class="flex justify-center items-center w-5 h-5 bg-fa-red rounded-full pt-0.5"
              :fontFamily="1"
              >5</DoiText
            >
            <DoiText
              :fontFamily="1"
              :textColor="3"
              :fontSize="3"
              class="uppercase"
              >Tablette tactile et écran</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >pour revivre entre amis les meilleurs moments de la
              partie</DoiText
            >
          </div>
        </div>
        <div class="flex mt-8">
          <div class="flex pl-2 w-20 h-20">
            <img
              src="@/assets/icons/utils/play/haut-parleurs.png"
              class="image"
              alt="haut-parleurs pour l'ambiance et les indications sonores pendant le match dans la Fit Arena"
            />
          </div>
          <div class="flex flex-col px-4 items-start">
            <DoiText
              class="flex justify-center items-center w-5 h-5 bg-fa-red rounded-full pt-0.5"
              :fontFamily="1"
              >6</DoiText
            >
            <DoiText
              :fontFamily="1"
              :textColor="3"
              :fontSize="3"
              class="uppercase"
              >Haut-parleurs</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="1"
              class="leading-5"
              >pour l'ambiance et les indications sonores</DoiText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayPresentation",
  data() {
    return {
      explanations: [
        {
          icon: "play/tourniquet.svg",
          title: "tourniquet d'accès",
          explanation: ["avec lecteur QR Code"],
        },
        {
          icon: "play/tabletteTactile.svg",
          title: "tablettes tactiles connectées",
          explanation: [
            "pour édition de la feuille de match en temps réel avec scoring et capture vidéo",
          ],
        },
        {
          icon: "play/camera.svg",
          title: "caméras",
          explanation: [
            "les matchs sont filmés et disponibles en replay sur l’application",
          ],
        },
        {
          icon: "play/ecran.svg",
          title: "écran 3m x 2m",
          explanation: [
            "- feuille de match en temps réel",
            "- coaching sportif",
            "- contenus pédagogiques",
          ],
        },
        {
          icon: "play/devices.svg",
          title: "tablette tactile et écran",
          explanation: [
            "pour revivre entre amis les meilleurs moments de la partie",
          ],
        },
        {
          icon: "play/hp.svg",
          title: "haut-parleurs",
          explanation: ["pour l'ambiance et les indications sonores"],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.img-presentation-desktop {
  max-width: 80%;
}

.title-background-desktop {
  background: linear-gradient(
    61deg,
    $doi--color-color4 64%,
    rgba(255, 255, 255, 1) 64%
  );
}

.image {
  min-width: 80px;
  min-height: 80px;
}

@media (min-width: 320px) and (max-width: $doi-breakpoint-tablet) {
  .title-background-desktop {
    background: linear-gradient(
      121deg,
      $doi--color-color4 85%,
      rgba(255, 255, 255, 1) 85%
    );
  }
}

@media (min-width: $doi-breakpoint-large-screen) {
  .button.gray {
    font-size: 1.25rem;
    border-radius: 4px;
  }
}
@media (min-width: $doi-breakpoint-extra-large-screen) {
  .button.gray {
    font-size: 2.25rem;
    border-radius: 4px;
  }
}
</style>
