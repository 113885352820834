<template>
  <div class="flex justify-center mt-20">
    <video :poster="setMiniature()" :key="video" controls muted>
      <source :src="setVideo()" type="video/mp4" codecs="avc1.4d002a" />
    </video>
  </div>
</template>

<script>
export default {
  name: "FitArenaShareMiniature",
  data() {
    return {
      miniature: "",
      video: "",
    };
  },
  created() {
    const fbEl = document.querySelector('head meta[property="og:image"]');
    fbEl.setAttribute("content", window.atob(this.$route.query.miniature));
  },
  methods: {
    setMiniature() {
      return (this.miniature = window.atob(this.$route.query.miniature));
    },
    setVideo() {
      return (this.video = window.atob(this.$route.query.video));
    },
  },
};
</script>
