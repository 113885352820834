<template>
  <p class="text-article">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "TextArticle",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.text-article {
  color: $doi--text-color1;
  font-size: $doi--text-font-size3;
}
</style>
