<template>
  <div class="flex flex-col lg:flex-row p-0 lg:h-80">
    <div class="bg-img bg-fa-red h-full block lg:w-1/2"></div>
    <div class="bg-fa-red p-8 lg:w-1/2 flex flex-col justify-center lg:pl-20">
      <div>
        <DoiText :fontFamily="0" :fontSize="5" class="lg:text-5xl 4k:text-7xl"
          >BOOK, PLAY ...</DoiText
        >
      </div>
      <div class="ml-14 mt-2 lg:ml-20">
        <DoiText
          :fontFamily="1"
          :fontSize="7"
          class="share-title lg:text-7xl 4k:text-8xl"
          >SHARE !</DoiText
        >
      </div>
      <div class="flex justify-around mt-8 lg:justify-start lg:ml-20">
        <div class="w-11 lg:mr-8">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.facebook.com/Fit-Arena-135920413695770/"
          >
            <img alt="icône facebook" src="@/assets/img/share/facebook.png" />
          </a>
        </div>
        <div class="w-11 lg:mr-8">
          <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/_FitArena_"
          >
            <img alt="icône twitter" src="@/assets/img/share/twitter.png" />
          </a>
        </div>
        <div class="w-11 lg:mr-8">
          <a
            target="_blank"
            rel="noopener"
            href="https://instagram.com/fitarena_officiel"
          >
            <img alt="icône instagram" src="@/assets/img/share/instagram.png" />
          </a>
        </div>
        <div class="w-11 lg:mr-5">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.youtube.com/channel/UCBnso9VI6kJkVNBpQ80TwEQ"
          >
            <img alt="icône youtube" src="@/assets/img/share/youtube.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareSection",
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-img {
  background-image: url("~@/assets/img/presentation/Footer.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@media (max-width: $doi-breakpoint-desktop) {
  .bg-img {
    height: 350px;
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .share-title {
    font-size: 3rem;
  }
}
</style>
