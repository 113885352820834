import Vue from "vue";
import { getSlots2 } from "@/api/TimeslotsService";

const initState = () => {
  return {
    isFetched: false,
    data: {
      timeslots: [],
      dateStart: null,
      dateEnd: null,
      idSport: null,
      idFitArena: null,
    },
  };
};

export default {
  namespaced: true,
  namespace: "timeslots",
  state: initState(),
  actions: {
    async retrieveTimeslots2(
      { state, commit },
      { debut, fitArena, duree, idSport }
    ) {
      try {
        if (!state.isFetched) {
          commit("setFetchedOnFetching");
          const data = await getSlots2(debut, fitArena, duree, idSport);

          commit("setTimeslots2", { data, debut, fitArena, duree, idSport });
          return data;
        }
      } catch (e) {
        commit("setFetchedOnError");
        const message =
          "Une erreur est survenue lors de l'initialisation du panier";
        if (screen.width > 640) {
          Vue.$toast.error(message);
        } else {
          Vue.$toast.error(message, {
            position: "top",
          });
        }
      }
    },
    resetInfo({ commit }) {
      commit("resetInfo");
    },
  },
  getters: {
    getTimeslots: (state) => {
      return state.data.timeslots;
    },
    getTimeslotsIsFetched: (state) => {
      return state.isFetched;
    },
  },
  mutations: {
    setFetchedOnFetching: (state) => {
      state.isFetched = true;
    },
    setTimeslots: (state, { idSport, idFitArena, data }) => {
      const dataToset = {
        idFitArena: idFitArena,
        idSport: idSport,
        timeslots: data,
      };
      state.data = Object.assign({}, dataToset);
      state.isFetched = false;
    },
    setTimeslots2: (state, { fitArena, idSport, data }) => {
      const dataToset = {
        idFitArena: fitArena,
        idSport: idSport,
        timeslots: data.creneaux,
      };
      state.data = Object.assign({}, dataToset);
      state.isFetched = false;
    },
    setFetchedOnError: (state) => {
      state.isFetched = false;
    },
    resetInfo: (state) => {
      Object.assign(state, initState());
    },
  },
};
