<template>
  <h2 class="title2">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: "H2Article",
};
</script>

<style lang="scss">
@import "~@/styles/_variables";

.title2 {
  font-size: $doi--header5;
  font-family: $doi--text-font-family2;
  color: $doi--header-color1;
}
</style>
