<template>
  <div
    v-if="creneau.statut === 'panier'"
    :class="classObject + cellHeight"
    class="flex flex-col justify-center items-center rounded-lg shadow-xl"
  >
    <DoiText
      :fontSize="4"
      :fontFamily="1"
      :textColor="2"
      class="text-sm tracking-widest text-center"
      >Créneau ajouté au panier</DoiText
    >
  </div>
  <div
    v-else
    :class="classObject + borderLeftColorStyle + cellHeight"
    class="flex flex-col rounded-lg shadow-xl"
  >
    <div v-if="creneau.typeCreneau == null" class="flex flex-col">
      <div class="flex flex-wrap p-2 pb-0 pr-0 justify-between items-center">
        <div class="flex justify-center items-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-sm mr-2 font"
            >{{ formattedDateDebut }}</DoiText
          >
          <DoiText
            :fontSize="3"
            :fontFamily="0"
            :textColor="0"
            class="md:text-sm font"
            >{{ formattedSlotDuration }}</DoiText
          >
        </div>
        <div class="flex items-center justify-center">
          <DoiText
            v-if="creneau.price > 0"
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-xs flex items-center font"
            >{{ formattedPrice }}&euro; /<span class="material-symbols-outlined"
              >person</span
            ></DoiText
          >
          <DoiText
            v-else
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-xs flex items-center font pr-2"
            >GRATUIT</DoiText
          >
        </div>
      </div>
      <div class="p-2 pb-0 pr-0">
        <DoiText
          :fontSize="3"
          :fontFamily="1"
          :textColor="1"
          class="md:text-sm mr-2 font uppercase"
          >TERRAIN</DoiText
        >
        <DoiText
          v-if="creneau.price > 0"
          :fontSize="3"
          :fontFamily="0"
          :textColor="1"
          class="md:text-sm mr-2 font"
          >Prix total :
          {{ (creneau.price / 100).toFixed(2) }}
          &euro;</DoiText
        >
        <DoiText
          v-else
          :fontSize="3"
          :fontFamily="0"
          :textColor="1"
          class="md:text-sm mr-2 font"
          >GRATUIT</DoiText
        >
      </div>
    </div>
    <div v-if="creneau.typeCreneau === 'terrain'" class="flex flex-col">
      <div class="flex p-2 pb-0 pr-0 justify-between items-center">
        <div class="flex justify-center items-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-sm mr-2 font"
            >{{ formattedDateDebut }}</DoiText
          >
          <DoiText
            :fontSize="3"
            :fontFamily="0"
            :textColor="0"
            class="md:text-sm font"
            >{{ formattedSlotDuration }}</DoiText
          >
        </div>
        <div v-if="creneau.price > 0" class="flex items-center justify-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-xs flex items-center font"
            >{{ formatPrice }} € /<span class="material-symbols-outlined"
              >person</span
            ></DoiText
          >
        </div>
        <div v-else class="flex items-center justify-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-xs flex items-center font pr-2"
            >GRATUIT</DoiText
          >
        </div>
      </div>
      <div class="p-2 pb-0 pr-0">
        <DoiText
          :fontSize="3"
          :fontFamily="1"
          :textColor="1"
          class="md:text-sm mr-2 font uppercase"
          >{{ creneau.typeCreneau }}</DoiText
        >
        <DoiText
          v-if="creneau.price > 0"
          :fontSize="3"
          :fontFamily="0"
          :textColor="1"
          class="md:text-sm mr-2 font"
          >Prix total :
          {{ formatTotalPrice }}
          &euro;</DoiText
        >
        <DoiText
          v-else
          :fontSize="3"
          :fontFamily="0"
          :textColor="1"
          class="md:text-sm mr-2 font pr-2"
          >GRATUIT</DoiText
        >
      </div>
    </div>
    <div v-if="creneau.typeCreneau === 'place'" class="flex flex-col">
      <div class="flex p-2 pb-0 pr-0 justify-between items-center">
        <div class="flex justify-center items-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-sm mr-2 font"
            >{{ formattedDateDebut }}</DoiText
          >
          <DoiText
            :fontSize="3"
            :fontFamily="0"
            :textColor="0"
            class="md:text-sm font"
            >{{ formattedSlotDuration }}</DoiText
          >
        </div>
        <div v-if="creneau.price > 0" class="flex items-center justify-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-xs flex items-center font"
            >{{ (creneau.price / 100).toFixed(2) }}&euro; /<span
              class="material-symbols-outlined"
              >person</span
            ></DoiText
          >
        </div>
        <div v-else class="flex items-center justify-center pr-2">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="0"
            class="md:text-xs flex items-center font"
            >GRATUIT</DoiText
          >
        </div>
      </div>
      <div class="p-2 pb-0 pr-0">
        <DoiText
          :fontSize="3"
          :fontFamily="1"
          :textColor="1"
          class="md:text-sm mr-2 font uppercase"
          >{{ creneau.typeCreneau }}</DoiText
        >
        <DoiText
          :fontSize="3"
          :fontFamily="0"
          :textColor="3"
          class="md:text-sm mr-2 font"
          >{{ nbPlacesDisponibles }} places restantes
        </DoiText>
      </div>
    </div>
    <div v-if="creneau.typeCreneau === 'cours'" class="flex flex-col">
      <div class="flex p-2 pb-0 pr-0 justify-between items-center">
        <div class="flex justify-center items-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="8"
            class="md:text-sm mr-2 font"
            >{{ formattedDateDebut }}</DoiText
          >
          <DoiText
            :fontSize="3"
            :fontFamily="0"
            :textColor="8"
            class="md:text-sm font"
            >{{ formattedSlotDuration }}</DoiText
          >
        </div>
        <div v-if="creneau.price > 0" class="flex items-center justify-center">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="8"
            class="md:text-xs flex items-center font"
            >{{ (creneau.price / 100).toFixed(2) }} &euro; /<span
              class="material-symbols-outlined"
              >person</span
            ></DoiText
          >
        </div>
        <div v-else class="flex items-center justify-center pr-2">
          <DoiText
            :fontSize="3"
            :fontFamily="1"
            :textColor="8"
            class="md:text-xs flex items-center font"
            >GRATUIT</DoiText
          >
        </div>
      </div>
      <div class="p-2 pb-0 pr-0">
        <DoiText
          :fontSize="3"
          :fontFamily="1"
          :textColor="1"
          class="md:text-sm mr-2 font uppercase"
          >{{ creneau.typeCreneau }}
        </DoiText>
        <DoiText
          :fontSize="3"
          :fontFamily="1"
          :textColor="1"
          class="md:text-sm mr-2 font uppercase"
          >Niveau avancé
        </DoiText>
        <DoiText
          :fontSize="3"
          :fontFamily="0"
          :textColor="3"
          class="md:text-sm mr-2 font"
          >{{ nbPlacesDisponibles }} places restantes
        </DoiText>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import borderColorMixin from "@/components/mixins/borderColorMixin.js";

export default {
  name: "PlanningLightItemDetail",
  mixins: [borderColorMixin],
  props: {
    creneau: Object,
    isSelected: Boolean,
    nbPersonneConseille: String,
  },
  computed: {
    formatPrice() {
      let data = Intl.NumberFormat("fr-FR").format(
        this.creneau.price / 100 / this.creneau.nbPersonneConseille
      );
      if (data.includes(",00")) data.replace(",00", "");
      return data;
    },
    formatTotalPrice() {
      let data = Intl.NumberFormat("fr-FR").format(this.creneau.price / 100);
      if (data.includes(",00")) data.replace(",00", "");
      return data;
    },
    nbPlacesDisponibles() {
      return this.creneau.nbParticipantMax - this.creneau.remplissage;
    },
    s() {
      return this.nbPlacesDisponibles > 1 ? "s" : "";
    },
    formattedDateDebut() {
      return dayjs(this.creneau.start).format("HH:mm");
    },
    formattedDateFin() {
      return dayjs(this.creneau.end).format("HH:mm");
    },
    formattedSlotDuration() {
      const duree = this.creneau.dureeCreneau;
      const hour = Math.floor(duree / 60);
      let min = duree % 60;
      if (hour > 0 && min <= 9 && min !== 0)
        min = min.toString().padStart(2, "0");
      let r;

      if (hour > 0 && min > 0) r = `${hour}h${min}min`;
      else if (hour > 0 && min === 0) r = `${hour}h`;
      else if (hour === 0 && min > 0) r = `${min}min`;
      return r;
    },
    formattedPrice() {
      if (this.creneau.nbPersonneConseille !== undefined) {
        if (this.creneau.tarifIndividuel) return this.creneau.price;
        else
          return Math.floor(
            this.creneau.price / this.creneau.nbPersonneConseille
          ).toFixed(2);
      } else {
        return this.creneau.price.toFixed(2);
      }
    },
    formattedPlaces() {
      if (this.creneau.remplissage === 0) return this.creneau.nbParticipantMax;
      else return this.creneau.nbParticipantMax - this.creneau.remplissage;
    },
    classObject() {
      if (this.creneau.statut === "disponible")
        return "bg-white cursor-pointer border border-grey";
      else return "bg-grey";
    },
    cellHeight() {
      if (this.creneau.typeCreneau == null) return " h-28";
      else return " h-32";
    },
    borderLeftColorStyle() {
      if (
        this.creneau.typeCreneau === "terrain" ||
        this.creneau.typeCreneau === "place" ||
        this.creneau.typeCreneau === undefined
      ) {
        return " bl0 border-l-8"; // ROUGE
      }
      // CRÉNEAU 'COURS'
      return " bl6 border-l-8"; // VERT
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" -25, "opsz" 20;
}

.bg-grey {
  background-color: $doi--color-color-lighter4;
}

@media (min-width: 375px) and (max-width: 500px) {
  .font {
    font-size: 0.8rem;
  }
}

@media (max-width: 375px) {
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 200, "GRAD" -25, "opsz" 15;
  }
}
</style>
