import Network from "@/utils/Network";

const url2 = process.env.VUE_APP_API_URL_V2;

const getSlots2 = (debut, fitArena, duree, idSport) => {
  return Network.execute({
    secure: false,
    url: url2 + `creneaux/fitarena/${fitArena}/debut/${debut}/duree/${duree}`,
    params: {
      activite: idSport,
      gp: "1",
    },
  });
};

export { getSlots2 };
