<template>
  <div>
    <section
      class="mx-auto sm:mx-8 flex justify-center text-center 4k:mt-32"
      id="fit-arena-list"
    >
      <DoiHeader class="my-4 title" :level="1" :headerColor="0"
        >Mon parcours dans la salle</DoiHeader
      >
    </section>
    <section class="flex flex-col items-center justify-center">
      <MobileAppQRCode />
    </section>
    <section class="my-8">
      <GymPresentation />
    </section>
    <footer>
      <ShareSection />
    </footer>
  </div>
</template>

<script>
import MobileAppQRCode from "./components/MobileAppQRCode";
import GymPresentation from "./components/GymPresentation";
import ShareSection from "@/views/share/ShareSection";

export default {
  name: "MonParcoursDansLaSalle",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Présentation des salles multisports connectée, intelligente et autonome. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://www.fit-arena.fr/mon-parcours-dans-la-salle",
      },
    ],
  },
  components: {
    MobileAppQRCode,
    GymPresentation,
    ShareSection,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

@media (max-width: $doi-breakpoint-mobile) {
  .title {
    font-size: $doi--header4;
  }
}
</style>
