import Network from '@/utils/Network'

const url1 = process.env.VUE_APP_API_URL
const url2 = process.env.VUE_APP_API_URL_V2

const getContact = (nIdUtilisateur, nIdContact) => {
  return Network.execute({
    method: 'GET',
    url: url1 + `Contact/?nIdContact=${nIdContact}`,
    params: {
      nIdUtilisateur: nIdUtilisateur
    }
  })
}

const getContactsV2 = () => {
  return Network.execute({
    method: 'GET',
    url: url2 + 'user/parent'
  })
}

const getContactV2 = (id) => {
  return Network.execute({
    method: 'GET',
    url: url2 + `user/parent/${id}`
  })
}

const deleteContact = (nIdContact) => {
  return Network.execute({
    method: 'DELETE',
    url: url1 + `Contact/${nIdContact}`
  })
}

const deleteContactV2 = (id) => {
  return Network.execute({
    method: 'DELETE',
    url: url2 + `user/parent/${id}`
  })
}

const setUpdateContact = (userData, nIdContact) => {
  return Network.execute({
    method: 'PUT',
    url: url1 + `Contact/${nIdContact}`,
    payload: userData
  })
}

const setUpdateContactV2 = (userData, id) => {
  return Network.execute({
    method: 'PUT',
    url: url2 + `user/parent/${id}`,
    payload: userData
  })
}

const setNewContact = (userData) => {
  return Network.execute({
    method: 'POST',
    url: url1 + 'Contact',
    payload: userData
  })
}

const setNewContactV2 = (userData) => {
  return Network.execute({
    method: 'POST',
    url: url2 + 'user/parent',
    payload: userData
  })
}

export { getContact, getContactsV2, getContactV2, deleteContact, setUpdateContact, setNewContact, setNewContactV2, setUpdateContactV2, deleteContactV2 }
